import React from "react";
import ReactPlayer from "react-player";
import { Row, Col } from "reactstrap";


const VideoDetails = (props) => {
    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={4} xl={4} className={props.classNames}>
                <div className="player-wrapper">
                    <ReactPlayer
                        className="react-player"
                        url={`${props.item.videoLink}`}
                        width="100%"
                        height="100%"
                        playing={false}
                        controls={true}
                        volume={0.5}
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 },
                            },
                            attributes: {
                                controlsList: "download",
                                poster: `${props.item.videoLink}`,
                            },
                        }}
                    />
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={7} xl={7} className={`${props.paramsClassNames}`}>
               <p className="external-section--title robotoSlab-Bold title-cornflower-blue pb-1" style={{ color: props.item.hexCode.includes('#') ? `${props.item.hexCode}` : `#${props.item.hexCode}` }}>{props.item.videoTitle ? `${props.item.videoTitle}` : ''}</p>
               <p className="p-small external-section--title pb-1 lh-30px" style={{ color: props.item.hexCode.includes('#') ? `${props.item.hexCode}` : `#${props.item.hexCode}` }}>{props.item.videoDescription}</p>
            </Col>
        </Row>
    )
}

export default VideoDetails;
