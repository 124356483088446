import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const LocationService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateLocation } = ApiConfig;

        const url = baseUrl + insertOrUpdateLocation;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            name: `${data.name}`,
        });

        const locationServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_locationService",
            true
        );
        return locationServicePromise;
    },

    getList: async () => {
        const { baseUrl, getLocationList } = ApiConfig;

        const url = baseUrl + getLocationList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const locationServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_Location",
            true
        );
        return locationServicePromise;
    },


};

export default LocationService;
