import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Button, Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import PostitonModal from "./PostitonModal";
import ColorCode from "../../../../../Utils/Json/colorCode";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        padding: 18,
        border: 0,
        height: '600px',
        '&:focus': {
            outline: 'none',
        },
    },
}));

function getModalStyle(height) {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflow: "scroll"
    };
}

const VideoGallery = (props) => {
    const classes = useStyles();
    const [videoTitle, setVideoTitle] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [videoDescription, setVideoDescription] = useState("");
    const [videoGalleryList, setVideoGalleryList] = useState([]);
    const [videoEditIndex, setVideoEditIndex] = useState(0);
    const [colorCode, setColorCode] = useState('');
    const [isColorShow, setIsColorShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [pdfFile, setPdfFile] = useState('');
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);

    const { open, toggle, handleVideoGalleryList, layoutType } = props;

    React.useEffect(() => {
        if (props.isVideoAdd) {
            setVideoGalleryList(props.videoGallery.length > 0 ? [...props.videoGallery] : [])
        } else {
            setVideoGalleryList([]);
            setVideoLink('');
            setVideoTitle('');
            setVideoDescription('');
        }
    }, [props.isVideoAdd, props.videoGallery])

    const handleVideoTitle = (event) => {
        setVideoTitle(event.target.value)
    }

    const handleVideoLink = (event) => {
        setVideoLink(event.target.value)
    }

    const handleVideoDescription = (event) => {
        setVideoDescription(event.target.value)
    }

    const handleColorCode = (color) => {
        setColorCode(color);
    }


    const handleCancel = () => {
        setVideoLink('');
        setVideoTitle('');
        setVideoDescription('');
        setPdfFile("");
        setColorCode("");
    }

    const getPdfFile = (event) => {
        setPdfFile(event.target.files[0])
    }

    const handleSave = () => {
        let data;
        if (isEdit) {
            const item = videoGalleryList[videoEditIndex];
            console.log("item :", item);
            item.videoLink = `${videoLink}`;
            item.videoTitle = `${videoTitle}`;
            item.videoDescription = `${videoDescription}`;
            item.hexCode = colorCode;
            setVideoGalleryList([...videoGalleryList]);
            item.pdfFile = pdfFile;
        } else {
            data = [
                {
                    //   id: parseInt(locationId) + parseInt(1),
                    videoLink: `${videoLink}`,
                    videoTitle: `${videoTitle}`,
                    videoDescription: `${videoDescription}`,
                    hexCode: colorCode,
                    pdfFile: pdfFile,
                }
            ];
            //   setLocationId(parseInt(locationId) + parseInt(1));
            setVideoGalleryList([...data, ...videoGalleryList]);
        }
        handleCancel();
        setIsEdit(false);
    }

    const handleEdit = index => {
        const item = videoGalleryList[index];
        setVideoLink(item.videoLink);
        setVideoTitle(item.videoTitle);
        setVideoDescription(item.videoDescription)
        setIsEdit(true);
        setVideoEditIndex(parseInt(index));
        setColorCode(item.hexCode);
        setPdfFile(item.pdfFile);
    };

    const handleDelete = index => {
        videoGalleryList.splice(index, 1);
        setVideoGalleryList([...videoGalleryList]);
    };

    const handleSubmit = () => {
        handleVideoGalleryList(videoGalleryList)
    }

    const handleLocationPosition = (index) => {
        setPositionIndex(index)
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionToggle = () => {
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionSave = (toIndex) => {
        const list = [...videoGalleryList];
        //list.splice(0, 0, list.splice(index, 1)[0]);
        var element = list[positionIndex];
        list.splice(positionIndex, 1);
        list.splice(toIndex, 0, element);
        setVideoGalleryList([...list]);
        setIsPositionModal(!isPositionModal);
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={toggle}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ overflow: 'scroll' }}
            >
                <Fade in={open}>
                    <div style={getModalStyle()} className={`${classes.paper} modal-container-position`}>
                        <FormGroup>
                            <Row className="mx-0 bg-crimson-red align-items-center py-2">
                                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                    <p className="external-section--title fw-blod">
                                        Video Gallery
                                    </p>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <p className="external-section--title fw-blod align-right cursor-pointer" onClick={toggle}>
                                        Close
                                    </p>
                                </Col>
                            </Row>
                        </FormGroup>
                        <Row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={6} lg={props.layoutType && props.layoutType.value === 5 ? 6 : 6} xl={props.layoutType && props.layoutType.value === 5 ? 6 : 6}>
                                        <FormGroup>
                                            <Label className="common-label text-left">
                                                video Title:
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder="Video Title"
                                                id="Video Title"
                                                name="Video Title"
                                                className="form-control common-form-control"
                                                value={videoTitle}
                                                onChange={event =>
                                                    handleVideoTitle(event)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={props.layoutType && props.layoutType.value === 5 ? 6 : 6} xl={props.layoutType && props.layoutType.value === 5 ? 6 : 6}>
                                        <FormGroup>
                                            <Label className="common-label text-left">
                                                video Link * :
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder="Video Link"
                                                id="Video Link"
                                                name="Video Link"
                                                className="form-control common-form-control"
                                                value={videoLink}
                                                onChange={event =>
                                                    handleVideoLink(event)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={props.layoutType && props.layoutType.value === 5 ? 12 : 12} xl={props.layoutType && props.layoutType.value === 5 ? 12 : 12}>
                                        <FormGroup
                                            row
                                            className="mx-0 align-items-center"
                                        >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                <Label className="common-label text-left d-flex align-items-center">
                                                    <div>
                                                        Color Code:
                                                    </div>
                                                    <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                                                    </div>

                                                </Label>
                                                <div className="d-flex flex-wrap">
                                                    {ColorCode.map((it, index) => (
                                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleColorCode(`${it.label}`)}>

                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    {props.layoutType && props.layoutType.value === 5 ?
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <FormGroup className="align-items-center">
                                                <Label className="common-label text-left">
                                                    Upload PDF File* :
                                                </Label>
                                                <div>
                                                </div>
                                                <div
                                                    className={`position-relative`}
                                                >
                                                    <div className="file-form-container">
                                                        <input type="file" value={""} placeholder="Upload File" multiple={false} accept="application/pdf" className="form-control common-form-control" onChange={getPdfFile} />
                                                        <p className="file-text">{pdfFile ? `${pdfFile.name} uploaded` : 'upload Files'}</p>
                                                    </div>

                                                    <div className="pdf-thumbnail-container">
                                                        <PictureAsPdfIcon
                                                            style={{
                                                                color: "#fff",
                                                                fontSize: "2rem",
                                                                borderRadius: 4,
                                                                padding: 2,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        : null}
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormGroup>
                                            <Label className="common-label text-left">
                                                video Description * :
                                            </Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Video Description"
                                                id="Video Description"
                                                name="Video Description"
                                                className="form-control common-form-control"
                                                value={videoDescription}
                                                onChange={event =>
                                                    handleVideoDescription(event)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={10} sm={10} md={10} lg={5} xl={5} className="px-0 m-auto">
                                <Row className="mx-0">
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                            onClick={handleSave}
                                            disabled={videoLink  && videoDescription ? false : true}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mx-0 align-items-center mt-3 mb-3">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                <FormGroup
                                    row
                                    className="mx-0 align-items-center"
                                >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                        <Table bordered responsive>
                                            <thead className="custom-table-thead">
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Link</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="custom-table-tbody">
                                                {videoGalleryList.map((it, index) => (
                                                    <tr key={index}>
                                                        <td>{it.videoTitle}</td>
                                                        <td>{it.videoLink}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <div
                                                                    onClick={() =>
                                                                        handleDelete(index)
                                                                    }
                                                                >
                                                                    <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                        Delete
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    className="ml-12px"
                                                                    onClick={() =>
                                                                        handleEdit(index)
                                                                    }
                                                                >
                                                                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                        Edit
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    className="ml-12px"
                                                                    onClick={() =>
                                                                        handleLocationPosition(index)
                                                                    }
                                                                >
                                                                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                        Position
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mx-0 align-items-center">
                            <Col xs={10} sm={10} md={10} lg={5} xl={5} className="px-0 ml-auto">
                                <Row className="mx-0">
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                            onClick={toggle}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Fade>
            </Modal>
            {isPositionModal ?
                <PostitonModal isModal={isPositionModal} handlePositionSave={handlePositionSave} handleToggle={handlePositionToggle} len={videoGalleryList} /> : null}

        </div>
    )
}


export default VideoGallery;