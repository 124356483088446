import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowSize } from '@react-hook/window-size';
import {
    ArrowForwardIosOutlined,
    ArrowBackIosOutlined
} from "@material-ui/icons";
import Code from "../../../assets/Homepage/project/code.png"
import VideoViewer from './VideoViewer';
import ProjectModel from './ProjectModel';
const ProjectSlider = ({ projectList }) => {

    const [width] = useWindowSize();
    const [isModal, setIsModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState();
    const[isToggle,setIsToggle]=useState(false);
    const [item ,setItem]=useState({})
   const toggle = () => {
    setIsToggle(!isToggle);
}
// console.log(data)
const handleItem = (item) => {
    
    setItem(item);
    setIsToggle(!isToggle);
}
    const handleToggle = () => {

        setIsModal(!isModal)
    }

    function getThumb(url) {
        setVideoUrl(url)
        setIsModal(!isModal)
    }
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} next-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined className="lr-arrow text-white" />
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} prev-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined className="lr-arrow text-white" />
            </div>
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        slidesToShow: width >= 1201? 4 : width >= 1024 ? 3 : width >=768 ? 2 : 1,
        slidesToScroll: width >= 1201? 4 : width >= 1024 ? 3 : width >=768 ? 2 :1,
        // slidesToShow: slide,
        // slidesToScroll: slide,
         autoplay: true,
         autoplaySpeed: 10000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 " >
                <Slider {...settings}>
                    {projectList && projectList.map((item, index) => (
                        <Row className="mx-0" key={index}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                <div className='goal-logo'>
                                    <img src={item.logo} />
                                </div>
                                <div className={item.slider}>
                                    <div className={item.tv}>
                                    
                                        <img src={item.image} alt="thumbnail" className='tv-img'  onClick={() => getThumb(item.videodata)} />
                                        
                                    
                                        {/* <iframe src="https://www.youtube.com/embed/UxCORFgh4G8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <VideoPlay videoLink="https://youtube.com/shorts/y_m33fwkTDw?feature=share" playing={true} padding={true} /> */}
                                    </div>
                                    <div className={`${item.text} mt-3`}>
                                        <p className='text-start mb-0'><span>{item.para1}</span></p>
                                        <p className='text-start mb-0'> {item.para2}</p>
                                    </div>
                                    <div className='goal-arrow mt-3'>
                                        <img src={item.arrow} onClick={()=>handleItem(item.arrowLink)} />
                                    </div>
                                </div>
                            </Col>
                        </Row>


                    ))}
                </Slider>
                {isModal ? <VideoViewer isModal={true} setIsModal={false} videoItem={videoUrl} handleToggle={handleToggle} /> : null
                }
                  {
                    isToggle ?
                        <ProjectModel toggle={toggle} open={isToggle} item={item} close={setIsToggle} />
                        : null
                }
            </Col>
        </Row>
    )
}

export default ProjectSlider