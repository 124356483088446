import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const SupporterService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrSupporterTeam } = ApiConfig;

        const url = baseUrl + insertOrSupporterTeam;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: `${data.title}`,
            highInvestorsList: data.highInvestorsList,
            lowInvestorsList: data.lowInvestorsList,
            description: `${data.description}`,
        });

        const supporterServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_supporterService",
            true
        );
        return supporterServicePromise;
    },

    getSupporterList: async () => {
        const { baseUrl, getSupporterList } = ApiConfig;

        const url = baseUrl + getSupporterList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const supporterServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_supporter",
            true
        );
        return supporterServicePromise;
    },

    enableDisableSupporter: async (data) => {
        const { baseUrl, enableOrDisableSupporter } = ApiConfig;

        const url = baseUrl + enableOrDisableSupporter;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: data.status,
        });

        const enableDisableUserServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableUser",
            true
        );
        return enableDisableUserServicePromise;
    },


  updateSequenicalOrder: async (data, userId) => {
    const { baseUrl, updateSequenicalOrderForSupporter } = ApiConfig;

    const url = baseUrl + updateSequenicalOrderForSupporter;

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const params = JSON.stringify({
      data: data,
      userId: userId,
    });

    const updateSequenicalOrderServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_updateSequenicalOrderForSupporter",
      true
    );
    return updateSequenicalOrderServicePromise;
  },

};

export default SupporterService;
