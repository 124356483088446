import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
import Img1 from '../../../assets/GoalNew/section5/sec5-logo.png';
import Logo from '../../../assets/GoalNew/section5/sec5-company.png';
const Section5 = () => {
  return (
    <>
      <Strip

        className="strip strip-no-padding"
        containerType="container-fluid px-0 goal-5"
      >
        <Strip

          className="strip strip-no-padding"
          containerType="container px-0 "
        >
          <Row >
            <Col xs={12} className="mt-3 mb-4">
              <div className='goal-img'>
                <img src={Img1} alt="image1" />
              </div>
            </Col>
            <Col xs={12} className=" mb-5 ">
              <Row className='goal-tab'>
                <Col xs={6} md={4} >
                  <div className='tab-style'>
                    <p className='mb-0'>#GoalGoa</p>
                  </div>
                </Col>
                <Col xs={6} md={4} >
                  <div className='tab-style'>
                    <p className='mb-0'>#TaketheShot</p>
                  </div>
                </Col>
                <Col xs={6} md={4}  className="mt-4 mt-md-0">
                  <div className='tab-style'>
                    <p className='mb-0'>#GirlsFootball</p>
                  </div>
                </Col>
                <Col xs={6} md={4} className="mt-4">
                  <div className='tab-style'>
                    <p className='mb-0'>#Goa</p>
                  </div>
                </Col>
                <Col xs={6} md={4} className="mt-4">
                  <div className='tab-style'>
                    <p className='mb-0'>#Mumbai</p>
                  </div>
                </Col>
                <Col xs={6} md={4} className="mt-4">
                  <div className='tab-style'>
                    <p className='mb-0'>#Bengaluru</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Strip>
      </Strip>
      <Strip

        className="strip strip-no-padding"
        containerType="container-fluid px-0 "
      >
        <Strip

          className="strip strip-no-padding"
          containerType="container px-0 "
        >

          <Row>
            <Col xs={12} className="mt-md-0 mt-4">
              <div className='goal-head'>
                <p className='mb-0'>Goal Goa is supported by</p>
              </div>
            </Col>
            <Col xs={12} className="mt-3 mb-5">
              <div className='goal-logos'>
                <img src={Logo} />
              </div>
            </Col>
          </Row>
        </Strip>
      </Strip>
    </>
  )
}

export default Section5