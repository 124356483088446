import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
import Img from "../../../assets/MapMe/section3/img.png";
import Logos from "../../../assets/MapMe/section3/Logos.png";
const Section3 = () => {
  return (
    <div>
  <Strip
        className="strip strip-no-padding me-three"
        containerType="container-fluid  px-0">
           <Strip
        className="strip strip-no-padding"
        containerType="container px-0">

          <Row>
          <Col xs={12} md={12} className=" pt-md-5 pt-3 text-center">
            <p className='text-light fs-4'>MOM TV aired on TV and online.</p>
            </Col>
            <Col xs={12} className="pb-md-5 pb-3">
              <div className='three-img'>
                <img src={Img}/>
              </div>
            </Col>
            <Col xs={12} className=" mb-md-3 ">
              <Row className='map-tab'>
                <Col xs={6} md={4} >
                  <div className='tab-style'>
                    <p className='mb-0'>#MapofMe</p>
                  </div>
                </Col>
                <Col xs={6} md={4} >
                  <div className='tab-style'>
                    <p className='mb-0'>#BT</p>
                  </div>
                </Col>
                <Col xs={6} md={4}  className="mt-2 mt-md-0">
                  <div className='tab-style'>
                    <p className='mb-0'>#Mumbai</p>
                  </div>
                </Col>
                <Col xs={6} md={4} className="mt-md-4 mt-2">
                  <div className='tab-style'>
                    <p className='mb-0'>#Bengaluru</p>
                  </div>
                </Col>
                <Col xs={6} md={4} className="mt-md-4 mt-2">
                  <div className='tab-style'>
                    <p className='mb-0'>#Delhi</p>
                  </div>
                </Col>
                <Col xs={6} md={4} className="mt-md-4 mt-2">
                  <div className='tab-style'>
                    <p className='mb-0'>#Kolkata</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} className=" text-center">
              <div className=' text-2'>
              <p className='text-light mb-0 fs-4'>Map of Me is supported by</p>
              </div>
          
            </Col>
            <Col xs={12} className="pb-md-5 pb-3 mt-3 mb-md-5 ">
              <div className='three-logo'>
                <img src={Logos}/>
              </div>
            </Col>
          </Row>
        </Strip>
         
        </Strip>

    </div>
  )
}

export default Section3