import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const FinancialService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateFinancial } = ApiConfig;

        const url = baseUrl + insertOrUpdateFinancial;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            reportType: data.reportType,
            startYear: data.startYear,
            endYear: data.endYear,
            path: `${data.path}`,
            colorCode: `${data.colorCode}`,
        });

        const financialServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_FinancialService",
            true
        );
        return financialServicePromise;
    },

    getFinancialList: async () => {
        const { baseUrl, getFinancialList } = ApiConfig;

        const url = baseUrl + getFinancialList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const financialServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_financial",
            true
        );
        return financialServicePromise;
    },

    enableDisableFinancial: async (data) => {
        const { baseUrl, enableOrDisableFinancial } = ApiConfig;

        const url = baseUrl + enableOrDisableFinancial;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: data.status,
        });

        const enableDisableFinancialServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableFinancial",
            true
        );
        return enableDisableFinancialServicePromise;
    },

};

export default FinancialService;
