import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const SectionBannerService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateSectionBanner } = ApiConfig;

        const url = baseUrl + insertOrUpdateSectionBanner;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            name: `${data.name}`,
            sectionType: `${data.sectionType}`,
            bannerDesktop: `${data.bannerDesktop}`,
            bannerMobile: `${data.bannerMobile}`,
            status: `${data.status}`,
        });

        const sectionBannerServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_sectionBannerService",
            true
        );
        return sectionBannerServicePromise;
    },

    getSectionBannerList: async () => {
        const { baseUrl, getSectionBannerList } = ApiConfig;

        const url = baseUrl + getSectionBannerList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const sectionBannerServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_sectionBanner",
            true
        );
        return sectionBannerServicePromise;
    },

    enableDisableSectionBanner: async (data) => {
        const { baseUrl, enableOrDisableSectionBanner } = ApiConfig;

        const url = baseUrl + enableOrDisableSectionBanner;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: data.status,
        });

        const enableDisableSectionBannerServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisablesectionBanner",
            true
        );
        return enableDisableSectionBannerServicePromise;
    },

};

export default SectionBannerService;
