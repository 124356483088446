import React from 'react';
import { Col, Row } from 'reactstrap';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
const CardDetails = (props) => {
    const {item, handleToggleIndex, index} = props;
    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={10} lg={10} xl={10} className="px-0 gallery-img-container">
                <div className="bg-zircon-grey gallery-card-img-container cursor-pointer">
                   <img src={`${ApiConfig.URL}${item.imgPath}`} alt="" className="img-item" onClick={() => handleToggleIndex(index)}/>
                </div>
            </Col>
        </Row>
    )
};


export default CardDetails;