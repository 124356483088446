import application_contants from "../constants/application_contant";

function activeTab(path) {
    if (path.split("/")[1] === application_contants.PEOPLES.split("/")[1]) {
        return application_contants.PEOPLES;
    } else if (path.split("/")[1] === application_contants.SUPPORTER.split("/")[1]) {
        return application_contants.SUPPORTER;
    } else if (path.split("/")[1] === application_contants.PROJECT.split("/")[1]) {
        return application_contants.PROJECT;
    } else if (path.split("/")[1] === application_contants.ABOUT.split("/")[1]) {
        return application_contants.ABOUT;
    } else if (path.split("/")[1] === application_contants.LOCATION.split("/")[1]) {
        return application_contants.LOCATION;
    }
    else if (path.split("/")[1] === application_contants.FINANACIAL.split("/")[1]) {
        return application_contants.FINANACIAL;
    }
    else {
        return path;
    }
}

export default activeTab;