import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Button, Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import PostitonModal from "./PostitonModal";
import ColorCode from "../../../../../Utils/Json/colorCode";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        padding: 18,
        border: 0,
        height: '600px',
        '&:focus': {
            outline: 'none',
        },
    },
}));

function getModalStyle(height) {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflow: "scroll"
    };
}

const ImageGallery = (props) => {
    const classes = useStyles();
    const [imgTitle, setImgTitle] = useState("");
    const [imgDescription, setImgDescription] = useState("");
    const [imgGalleryList, setImgGalleryList] = useState([]);
    const [imgEditIndex, setImgEditIndex] = useState(0);
    const [colorCode, setColorCode] = useState('fff');
    const [isEdit, setIsEdit] = useState(false);
    const [imgFile, setImgFile] = useState('');
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);

    const { open, toggle, handleImgGalleryList, layoutType } = props;

    React.useEffect(() => {
        if (props.isImgAdd) {
            setImgGalleryList(props.imgGallery.length > 0 ? [...props.imgGallery] : [])
        } else {
            setImgGalleryList([]);
            setImgTitle('');
            setImgDescription('');
        }
    }, [props.isImgAdd, props.imgGallery])

    const handleImgTitle = (event) => {
        setImgTitle(event.target.value)
    }

    const handleImgDescription = (event) => {
        setImgDescription(event.target.value)
    }

    const handleColorCode = (color) => {
        setColorCode(color);
    }


    const handleCancel = () => {
        setImgTitle('');
        setImgDescription('');
        setImgFile("");
        setColorCode("");
    }

    const getImgFile = (event) => {
        setImgFile(event.target.files[0])
    }

    const handleSave = () => {
        let data;
        if (isEdit) {
            const item = imgGalleryList[imgEditIndex];
            item.imgTitle = `${imgTitle}`;
            item.imgDescription = `${imgDescription}`;
            item.hexCode = colorCode;
            setImgGalleryList([...imgGalleryList]);
            item.imgFile = imgFile;
        } else {
            data = [
                {
                    //   id: parseInt(locationId) + parseInt(1),
                    imgTitle: `${imgTitle}`,
                    imgDescription: `${imgDescription}`,
                    hexCode: colorCode,
                    imgFile: imgFile,
                }
            ];
            //   setLocationId(parseInt(locationId) + parseInt(1));
            setImgGalleryList([...data, ...imgGalleryList]);
        }
        handleCancel();
        setIsEdit(false);
    }

    const handleEdit = index => {
        const item = imgGalleryList[index];
        setImgTitle(item.imgTitle);
        setImgDescription(item.imgDescription)
        setIsEdit(true);
        setImgEditIndex(parseInt(index));
        setColorCode(item.hexCode);
        setImgFile(item.imgFile);
    };

    const handleDelete = index => {
        imgGalleryList.splice(index, 1);
        setImgGalleryList([...imgGalleryList]);
    };

    const handleSubmit = () => {
        handleImgGalleryList(imgGalleryList)
    }

    const handleLocationPosition = (index) => {
        setPositionIndex(index)
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionToggle = () => {
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionSave = (toIndex) => {
        const list = [...imgGalleryList];
        //list.splice(0, 0, list.splice(index, 1)[0]);
        var element = list[positionIndex];
        list.splice(positionIndex, 1);
        list.splice(toIndex, 0, element);
        setImgGalleryList([...list]);
        setIsPositionModal(!isPositionModal);
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={toggle}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ overflow: 'scroll' }}
            >
                <Fade in={open}>
                    <div style={getModalStyle()} className={`${classes.paper} modal-container-position`}>
                        <FormGroup>
                            <Row className="mx-0 bg-crimson-red align-items-center py-2">
                                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                    <p className="external-section--title fw-blod">
                                        Video Gallery
                                    </p>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <p className="external-section--title fw-blod align-right cursor-pointer" onClick={toggle}>
                                        Close
                                    </p>
                                </Col>
                            </Row>
                        </FormGroup>
                        <Row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormGroup>
                                            <Label className="common-label text-left">
                                                Img Title :
                                            </Label>
                                            <Input
                                                type="text"
                                                placeholder="Video Title"
                                                id="Video Title"
                                                name="Video Title"
                                                className="form-control common-form-control"
                                                value={imgTitle}
                                                onChange={event =>
                                                    handleImgTitle(event)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <FormGroup className="align-items-center">
                                            <Label className="common-label text-left">
                                                Upload Img File* :
                                            </Label>
                                            <div>
                                            </div>
                                            <div
                                                className={`position-relative`}
                                            >
                                                <div className="file-form-container">
                                                    <input type="file" value={""} placeholder="Upload File" multiple={false} accept="image/jpeg, image/jpg, image/png" className="form-control common-form-control" onChange={getImgFile} />
                                                    <p className="file-text">{imgFile ? `${imgFile.name} uploaded` : 'upload Files'}</p>
                                                </div>

                                                <div className="pdf-thumbnail-container">
                                                    <CloudUploadIcon
                                                        style={{
                                                            color: "#fff",
                                                            fontSize: "2rem",
                                                            borderRadius: 4,
                                                            padding: 2,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormGroup
                                            row
                                            className="mx-0 align-items-center"
                                        >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                <Label className="common-label text-left d-flex align-items-center">
                                                    <div>
                                                        Color Code:
                                                    </div>
                                                    <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                                                    </div>

                                                </Label>
                                                <div className="d-flex flex-wrap">
                                                    {ColorCode.map((it, index) => (
                                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleColorCode(`${it.label}`)}>

                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormGroup>
                                            <Label className="common-label text-left">
                                                Image Description * :
                                            </Label>
                                            <Input
                                                type="textarea"
                                                placeholder="Video Description"
                                                id="Video Description"
                                                name="Video Description"
                                                className="form-control common-form-control"
                                                value={imgDescription}
                                                onChange={event =>
                                                    handleImgDescription(event)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={10} sm={10} md={10} lg={5} xl={5} className="px-0 m-auto">
                                <Row className="mx-0">
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                            onClick={handleSave}
                                            disabled={imgDescription ? false : true}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mx-0 align-items-center mt-3 mb-3">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                <FormGroup
                                    row
                                    className="mx-0 align-items-center"
                                >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                        <Table bordered responsive>
                                            <thead className="custom-table-thead">
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="custom-table-tbody">
                                                {imgGalleryList.map((it, index) => (
                                                    <tr key={index}>
                                                        <td>{it.imgDescription}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <div
                                                                    onClick={() =>
                                                                        handleDelete(index)
                                                                    }
                                                                >
                                                                    <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                        Delete
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    className="ml-12px"
                                                                    onClick={() =>
                                                                        handleEdit(index)
                                                                    }
                                                                >
                                                                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                        Edit
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    className="ml-12px"
                                                                    onClick={() =>
                                                                        handleLocationPosition(index)
                                                                    }
                                                                >
                                                                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                        Position
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mx-0 align-items-center">
                            <Col xs={10} sm={10} md={10} lg={5} xl={5} className="px-0 ml-auto">
                                <Row className="mx-0">
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                            onClick={toggle}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Fade>
            </Modal>
            {isPositionModal ?
                <PostitonModal isModal={isPositionModal} handlePositionSave={handlePositionSave} handleToggle={handlePositionToggle} len={imgGalleryList} /> : null}

        </div>
    )
}


export default ImageGallery;