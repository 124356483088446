import React, { useState } from 'react'
import { Row, Col } from 'reactstrap';
import { Button, Modal, Box } from "@material-ui/core";
import Strip from "../Strip/Strip";
import ExternalLayout from '../ExternalLayout/ExternalLayout';
import Map from '../../assets/Homepage/HomeMap.jpg';
import Slider1 from '../../assets/Homepage/homeSlider/1.jpg';
import Slider2 from '../../assets/Homepage/homeSlider/2.jpg';
import Slider3 from '../../assets/Homepage/homeSlider/3.jpg';
import Slider4 from '../../assets/Homepage/homeSlider/4.jpg';
import Slider5 from '../../assets/Homepage/homeSlider/5.jpg';
import Slider6 from '../../assets/Homepage/homeSlider/6.jpg';
// import Play from '../../assets/Homepage/play-city.png';
import Going from '../../assets/Homepage/going.png';
import Project from '../../assets/Homepage/project.png';
import Bamboo from '../../assets/Homepage/bamboo-New.png';
import School from '../../assets/Homepage/school.png'
import Homeslider from './component/homeslider';
import Know from '../../assets/Homepage/know-more.png';
import Gts from '../../assets/Homepage/gts.png';
import Play from '../../assets/Homepage/project/play.png';
import Out from '../../assets/Homepage/project/out-mi.png';
import GetPlan from '../../assets/Homepage/project/get.png';
import Arrow1 from '../../assets/Homepage/yellow.png';
import Arrow2 from '../../assets/Homepage/blue.png';
import Arrow3 from '../../assets/Homepage/green.png';
import ProjectSlider from './component/ProjectSlider';
import playlogo from '../../assets/Homepage/play-logo.png'
import outlogo from '../../assets/Homepage/out-logo.png'
import planlogo from '../../assets/Homepage/get-logo.png'
import Goallogo from '../../assets/Homepage/project/goallogo.png';
import Momlogo from '../../assets/Homepage/project/momlogo.png';
import Yeslogo from '../../assets/Homepage/project/yeslogo.png';
import Schoollogo from '../../assets/Homepage/project/schoollogo.png';
import Scrappylogo from '../../assets/Homepage/project/scrappylogo.png';
import Girllogo from '../../assets/Homepage/project/girllogo.png';
import Lunalogo from '../../assets/Homepage/project/lunalogo.png';
import Belogo from '../../assets/Homepage/project/belogo.png';
import Codelogo from '../../assets/Homepage/project/codelogo.png';
import Pitchlogo from '../../assets/Homepage/project/pitchlogo.png';
import Goinglogo from '../../assets/Homepage/project/goinglogo.png';
import Goal from '../../assets/Homepage/project/goal.png';
import Mom from '../../assets/Homepage/project/map.png';
import Yes from '../../assets/Homepage/project/yess.png';
import Schools from '../../assets/Homepage/project/school.png';
import Scrappy from '../../assets/Homepage/project/scrappy.png';
import Girl from '../../assets/Homepage/project/girl.png';
import Luna from '../../assets/Homepage/project/luna.png';
import Be from '../../assets/Homepage/project/be.png';
import Code from '../../assets/Homepage/project/code.png';
import Pitch from '../../assets/Homepage/project/pitch.png';
import Goings from '../../assets/Homepage/project/going.png';
import Arrows1 from '../../assets/Homepage/project/arr1.png';
import Arrows2 from '../../assets/Homepage/project/arr2.png';
import Arrows3 from '../../assets/Homepage/project/arr3.png';
import Arrows4 from '../../assets/Homepage/project/arr4.png';
import Arrows5 from '../../assets/Homepage/project/arr5.png';
import Arrows6 from '../../assets/Homepage/project/arr6.png';
import Arrows7 from '../../assets/Homepage/project/arr7.png';
import Arrows8 from '../../assets/Homepage/project/arr8.png';
import Arrows9 from '../../assets/Homepage/project/arr9.png';
import Arrows10 from '../../assets/Homepage/project/arr10.png';
import Arrows11 from '../../assets/Homepage/project/arr11.png';
import VideoPlay from '../VideoPlay/VideoPlay';
import ProjectModel from './component/ProjectModel';
import data from "./component/data";
import { useWindowSize } from '@react-hook/window-size';
import OutDoorSchool from '../../customComponent/HomeOutDoorSchool/OutDoorSchool';
import HomePlan from '../../customComponent/HomePlan/HomePlan';
import HomeVideo from '../../customComponent/HomeVideo/HomeVideo';
import HomeBannerSection from '../../customComponent/HomeBannerSection/HomeBannerSection';
import HomeSlider from '../../customComponent/HomeSlider/HomeSlider';
import ContactUsInfo from '../../customComponent/ContactUsInfo/ContactUsInfo';
const HomePage = () => {
    const [showModal, setShowModal] = useState(false)
    const [isToggle, setIsToggle] = useState(false);
    const [play, setPlay] = useState(false);
    const [out, setOut] = useState(false);
    const [get, setGet] = useState(false);
    const [item, setItem] = useState({})
    const [width] = useWindowSize();
    const toggle = () => {
        setIsToggle(false);
    }
    const HomePopUp = () => {
        setShowModal(true)
    }
    React.useEffect(() => {

        setTimeout(HomePopUp, 500)
    }, []);
    const handleItem = (item) => {

        setItem(item);
        setIsToggle(!isToggle);
    }
    // const imgHandle=(e)=>{
    //     event.stopPropagation();
    // }
    const sliderList = [Slider1, Slider2, Slider3, Slider4, Slider5, Slider6]
    const projectList = [
        {
            slider: "goal-slider",
            tv: "goal-tv",
            text: "goal-text",
            logo: Goallogo,
            arrow: Arrows1,
            image: Goal,
            arrowLink: data.goalData,
            videodata: "https://youtu.be/3bq8l8EGZI0",
            para1: "Goal Goa",
            para2: " Goal Goa is a feature film made across Goa during the monsoon, in search of football players old and young, young women and men, girls and boys, Goal..."
            //  Goa discovered how the great game came to be in 'every Goans blood'..."
        },
        {
            slider: "mom-slider",
            tv: "mom-tv",
            text: "mom-text",
            logo: Momlogo,
            arrow: Arrows2,
            arrowLink: data.mapData,
            image: Mom,
            videodata: "https://youtu.be/uKj9K6iSCgk",
            para1: "Map of Me ",
            para2: "Map of Me is a mobile, digital, skills channel that runs online and on TV. City-focused sustainable skills equip young women and girls with the skills..."
            //  they need to complete their education and transition from..."
        },
        {
            slider: "yes-slider",
            tv: "yes-tv",
            text: "yes-text",
            logo: Yeslogo,
            image: Yes,
            videodata: "https://youtu.be/66_ON13MjRc",
            arrow: Arrows3,
            arrowLink: data.yesData,
            para1: "Young Entrepreneurs Skills School ",
            para2: "The Young Entrepreneurs Skills School in Marathi introduces young people to the joys of learning skills... "
            // to become an entrepreneur, in classrooms in government schools and on..."
        },
        {
            slider: "school-slider",
            tv: "school-tv",
            text: "school-text",
            logo: Schoollogo,
            arrow: Arrows4,
            arrowLink: data.schoolData,
            image: Schools,
            videodata: "https://youtu.be/QPUNJH_h-ZA",
            para1: "School Tv",
            para2: "A DIY series on Doordarshan Bihar & Doordarshan Rajasthan that explores skills for employability and entrepreneurship for young people led... "
            //by experts and professionals, from beekeeping..."
        },
        {
            slider: "scrappy-slider",
            tv: "scrappy-tv",
            text: "scrappy-text",
            logo: Scrappylogo,
            image: Scrappy,
            videodata: "https://youtu.be/zQdAIpQVPUs",
            arrow: Arrows5,
            arrowLink: data.scrappyData,
            para1: "Scrappy news",
            para2: "Scrappy to make something out of nothing to change everything. A news-talk-game-show mass -media program, made in India for a world... "
            // of children that runs on national television, online and most importantly, on the..."
        },
        {
            slider: "girl-slider",
            tv: "girl-tv",
            text: "girl-text",
            logo: Girllogo,
            arrow: Arrows6,
            arrowLink: data.girlData,
            image: Girl,
            videodata: "https://youtu.be/oRUG42S5blA",
            para1: "A Girl's Guide to the 21st Century",
            para2: "An offline-to-online toolkit for girls to learn the skills they need to complete their education and make new life plans..."
            //For boys it was A Boys Guide to 21st Century India...
        },
        {
            slider: "luna-slider",
            tv: "luna-tv",
            text: "luna-text",
            logo: Lunalogo,
            arrow: Arrows7,
            arrowLink: data.lunaData,
            image: Luna,
            videodata: "https://youtu.be/-35mbdqGpZ8?list=PL1ue9VzrTsigsmKJOkM7Y_ET9Ymkmafm0",
            para1: "Luna",
            para2: "20 stories run in Kasturba Gandhi Balika Vidyalayas or KGBVs in Bihar, with an animated series. The Luna series is a girl's quest to find everything that..."
            //  is wrong in the world and finally set it right..."
        },
        {
            slider: "be-slider",
            tv: "be-tv",
            text: "be-text",
            logo: Belogo,
            arrow: Arrows8,
            arrowLink: data.beData,
            image: Be,
            videodata: "https://youtu.be/C58Yk5QMnrs",
            para1: "Be! an Entrepreneur",
            para2: "A series of illustrated books to teach children in Grade 9 entrepreneurial skills with weekly action projects, teacher training..."
            //  and skills project assessment..."
        },
        {
            slider: "code-slider",
            tv: "code-tv",
            text: "code-text",
            logo: Codelogo,
            arrow: Arrows9,
            arrowLink: data.codeData,
            image: Code,
            videodata: "https://youtu.be/XULc8nv67F0",
            para1: "Code for Bihar",
            para2: " Code for Bihar equipped girls studying in government schools with problem-solving and design-thinking abilities through coding."
        },
        {
            slider: "pitch-slider",
            tv: "pitch-tv",
            text: "pitch-text",
            logo: Pitchlogo,
            image: Pitch,
            videodata: "https://youtu.be/55h_c8cq7fI",
            arrow: Arrows10,
            arrowLink: data.pitchData,
            para1: "Pitch It! ",
            para2: "A school-change-enterprise challenge that saw children design-think brilliant, practical ideas for change around them from sports pitches..."
            // for girls to birdhouses in schools with solar panels to keep them..."
        },
        {
            slider: "going-slider",
            tv: "going-tv",
            text: "going-text",
            logo: Goinglogo,
            image: Goings,
            videodata: "https://youtu.be/cAcT_-cETuU",
            arrow: Arrows11,
            arrowLink: data.goingData,
            para1: "Going to School in India",
            para2: " A 144-page award-winning children's book and TV series that formed the genesis of a 20+ year-long journey that saw children across India answer..."
            // one question - How do you go to school?"
        },

    ]
    return (
        <ExternalLayout>
            <div className='home-para'>


                <Strip
                    className="strip strip-no-padding home-one"
                    containerType="container-fluid  px-0 mt-5">
                    <Row>
                        <Col xs={12} className=' mt-2 mb-md-2 mb-4' >

                            <div className='home1'>
                                <img src={Bamboo} className='bamboo' />
                                <div className='sli-img'>
                                    <div className='sli'>
                                        <Homeslider images={sliderList} slide={1} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} className="mt-md-5">
                            <div className='school-img '>
                                <img src={School} />
                            </div>
                        </Col>
                        <Col xs={12} className="mt-md-5 mt-3">
                            <div className='school-para'>
                                <p >
                                    Going to School makes design-driven stories, graphic novel adventures and television shows to equip young people with the sustainable enterprise skills they need to take on the biggest challenge of our time, Climate Change.
                                </p>
                                <p className='pt-2 '>
                                    We have a special focus on girls' retention in school and their transition from school to equitable work, sustainable enterprise of their choice after Grade 12.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} className="mt-md-3 mb-4">
                            <div className='know-btn'>
                                <a href="/about"> <img src={Know} /></a>
                            </div>
                        </Col>

                    </Row>

                </Strip>
                <Strip
                    className="strip strip-no-padding home2"
                    containerType="container-fluid  px-0">
                    <Strip
                        className="strip strip-no-padding"
                        containerType="container px-0 ">
                        <Row>
                            <Col xs={12} className="gts-logo">
                                <div className='gts'>
                                    <img src={Gts} />
                                </div>
                            </Col>
                            <Col xs={12} className="mt-5">
                                <div className='img1'>
                                    <img src={Going} />
                                </div>
                            </Col>
                            <Col xs={12} className=" pt-3">
                                <div className='img2'>
                                    <img src={Project} />
                                </div>
                            </Col>
                            <Col xs={12} md={4} className="mt-5 " onClick={() => handleItem(data.playData)} >
                                <div className='play-lo'>
                                    <img src={playlogo} />
                                </div>
                                <div className='play' >
                                    <div className='tv1'>
                                        {/* <iframe src="https://www.youtube.com/embed/oslthjxI4bQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                        {play ? <iframe src="https://www.youtube.com/embed/oslthjxI4bQ?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : <img src={Play} onClick={(e) => {
                                            e.stopPropagation(); setPlay(!play);
                                        }} className="playDataImg" />}

                                    </div>

                                    <div className='play-text mt-3'>
                                        <p className='text-start mb-0'><span>Play City</span></p>
                                        <p className='text-start mb-0'> The Children's Scrappy News Service - Making India's cities giant sustainable places to play for millions of children to play together and find solutions to Climate Change...</p>
                                    </div>
                                    <div className='play-arrow mt-3'>
                                        <img src={Arrow1} onClick={() => handleItem(data.playData)} />
                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={4} className="mt-5" onClick={() => handleItem(data.outData)}>
                                <div className='out-lo'>
                                    <img src={outlogo} />
                                </div>
                                <div className='outdoor'>
                                    <div className='tv2'>
                                        {out ?

                                            <iframe src="https://www.youtube.com/embed/QmCLRqynzr0?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                            : <img src={Out} onClick={(e) => { e.stopPropagation(); setOut(!out) }} />}
                                    </div>
                                    <div className='out-text  mt-3'>
                                        <p className='text-start mb-0'><span>The Outdoor School for Girls</span></p>
                                        {width <= 834 ? <p className='text-start mb-0'>brings football to school for girls for the first time in government schools in India. Kicking off at scale, the Outdoor School has an audacious goal of equipping...</p> : <p className='text-start mb-0'>brings football to school for girls for the first time in government schools in India. Kicking off at scale, the Outdoor School has an audacious goal of equipping 2.7 million girls with the football, life, digital...</p>}
                                    </div>
                                    <div className='out-arrow mt-3'>
                                        <img src={Arrow2} onClick={() => handleItem(data.outData)} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={4} className="mt-5" onClick={() => { handleItem(data.getData) }} >
                                <div className='plan-lo'>
                                    <img src={planlogo} />
                                </div>
                                <div className='plan'>
                                    <div className='tv3'>
                                        {/* <iframe  src="https://www.youtube.com/embed/UxCORFgh4G8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                        {get ? <VideoPlay videoLink="https://youtube.com/shorts/y_m33fwkTDw?feature=share" playing={true} padding={true} /> : <img src={GetPlan} onClick={(e) => { e.stopPropagation(); setGet(!get) }} />}
                                    </div>
                                    <div className='plan-text  mt-3'>
                                        <p className='text-start mb-0'><span>Get a Plan</span></p>
                                        {width <= 834 ? <p className='text-start mb-0'> Graphic novel adventures equip young people with the sustainable, entrepreneurial, green skills to transition from school to sustainable enterprise of her choice. Green...</p> : <p className='text-start mb-0'> Graphic novel adventures equip young people with the sustainable, entrepreneurial, green skills to transition from school to sustainable enterprise of her choice. Green Enterprise Internships...</p>}
                                    </div>
                                    <div className='plan-arrow mt-3'>
                                        <img src={Arrow3} onClick={() => handleItem(data.getData)} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} className="mt-5 mb-5">
                                <ProjectSlider projectList={projectList} />
                            </Col>
                        </Row>
                    </Strip>
                </Strip>

                <Strip
                    className="strip strip-no-padding home3"
                    containerType="container-fluid  px-0"
                >
                    <Row >
                        <Col xs={12} md={12} lg={12} >
                            <div className='map-home'>
                                <img src={Map} />
                            </div>

                        </Col>
                    </Row>
                </Strip>
                {
                    isToggle ?
                        <div >
                            <ProjectModel toggle={toggle} open={isToggle} item={item} close={setIsToggle} />
                        </div>
                        : null
                }
              
            </div>
        </ExternalLayout>
    )
}

export default HomePage