import React, { useContext, useState } from "react";
import { Route } from "react-router-dom";
import application_contants from "../../constants/application_contant";
import { AuthContext } from "../../context/AuthProvider";
import About from "../../customPages/About/About";
import PeopleList from "../../customPages/Admin/PeopleList/PeopleList";
import ProjectList from "../../customPages/Admin/Project/ProjectList";
import BannerList from "../../customPages/Admin/Banner/BannerList";
import SupporterList from "../../customPages/Admin/Supporter/SupporterList";
import ContactUs from "../../customPages/ContactUs/ContactUs";
import Login from "../../customPages/Login/Login";
// import People from "../../customPages/People/People";
import Policies from "../../customPages/Policies/Policies";
import ProjectDetails from "../../customPages/ProjectDetails/ProjectDetails";
import Support from "../../customPages/Support/Support";
import Authenticated from "../Authenticated/Authenticated";
import PublicRoute from "../PublicRoute/PublicRoute";
// import Home from "../../customPages/Home/Home";
import PrivacyPolicy from "../../customPages/Policies/PrivacyPolicy";
import ChildProtectionPolicy from "../../customPages/Policies/ChildProtectionPolicy";
import WhistleBlowerPolicy from "../../customPages/Policies/WhistleBlowerPolicy";
import AboutList from "../../customPages/Admin/About/AboutList";
import LocationList from "../../customPages/Admin/Location/LocationList";
import Financials from "../../customPages/Financials/Financials";
import FinancialList from "../../customPages/Admin/Financial/FinancialList";
import Usa from "../../customPages/USA/Usa";
// import GoalGoaNew from '../../customComponent/GoalGoaNew/GoalGoa'
import HomePage from "../HomePage/HomePage";
import UnderDevelopment from "../UnderDevelopment/UnderDevelopment";
// import MapMe from "../MapMe/MapOfMe";

const Navigation = (props) => {
    const appContext = useContext(AuthContext);
    const [authenticated, setAuthenticated] = useState(AuthContext.isLogin);

    React.useEffect(() => {
        const handleAuthenticated = async () => {
            await setAuthenticated(appContext ? appContext[0].isLoggedIn : false)
        }
        handleAuthenticated();
    }, [appContext]);

    return (
        <div>
            {/* <Route exact path={'*'} component={UnderDevelopment} {...props} /> */}
            <Route exact path={'/'} component={HomePage} {...props} />
            <Route exact path="/about" component={About} {...props} />
            {/* <Route exact path="/people" component={People} {...props} /> */}
            <Route exact path="/contact-us" component={ContactUs} {...props} />
            <Route exact path="/Supporters" component={Support} {...props} />
            <Route exact path="/policies" component={Policies} {...props} />
            <Route exact path="/financials" component={Financials} {...props} />
            <Route exact path="/policies/privacy-policy" component={PrivacyPolicy} {...props} />
            <Route exact path="/policies/child-protection-policy" component={ChildProtectionPolicy} {...props} />
            <Route exact path="/policies/whistle-blower-policy" component={WhistleBlowerPolicy} {...props} />
            <Route exact path="/project/:name" component={ProjectDetails} {...props} />
            <Route exact path="/usa" component={Usa} {...props} />
           {/* <Route exact path="/goal-goa" component={GoalGoaNew} {...props} />
           <Route exact path="/map-of-me" component={MapMe} {...props} /> */}
           {/*   <Route exact path="/play-city" component={PlayCity} {...props} /> */}
            {/* <Route exact path="/home-page" component={HomePage} {...props} /> */}
           
            <PublicRoute
                exact   
                path={'/login'}
                component={Login}
                {...props}
                authenticated={authenticated}
            />
            <Authenticated
                exact
                path={'/people-list'}
                component={PeopleList}
                authenticated={authenticated}
                {...props}
            />
                <Authenticated
                exact
                path={'/supporter-list'}
                component={SupporterList}
                authenticated={authenticated}
                {...props}
            />
               <Authenticated
                exact
                path={'/project-list'}
                component={ProjectList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/banner-list'}
                component={BannerList}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={'/about-list'}
                component={AboutList}
                authenticated={authenticated}
                {...props}
            />
               <Authenticated
                exact
                path={'/location-list'}
                component={LocationList}
                authenticated={authenticated}
                {...props}
            />
              <Authenticated
                exact
                path={'/financial-list'}
                component={FinancialList}
                authenticated={authenticated}
                {...props}
            />
        </div>
    );
};

export default Navigation;