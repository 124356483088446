import React from 'react';
import { Table, Button } from 'reactstrap';


const LogoListDetails = (props) => {
    const { data, handleLogoDelete, handleLogoEdit, handleLogoPosition } = props;
    return (
        <Table bordered responsive>
            <thead className="custom-table-thead">
                <tr>
                    <th>Id</th>
                    <th>File Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className="custom-table-tbody">
                {data.map((it, index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{it.fileName.split('/')[2] ? it.fileName.split('/')[2] : it.fileName}</td>
                        <td>
                            <div className="d-flex align-items-center justify-content-center">
                                <div
                                    onClick={() =>
                                        handleLogoDelete(index)
                                    }
                                >
                                    <Button type="button" className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                        Delete
                                    </Button>
                                </div>
                                <div
                                    className="ml-12px"
                                    onClick={() =>
                                        handleLogoEdit(index)
                                    }
                                >
                                    <Button type="button" className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                        Edit
                                    </Button>
                                </div>
                                <div
                                    className="ml-12px"
                                    onClick={() =>
                                        handleLogoPosition(index)
                                    }
                                >
                                    <Button type="button" className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                        Position
                                    </Button>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default LogoListDetails;