import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
import MapSlider from '../component/MapSlider';
import Train from '../../../assets/MapMe/section1/train1.png'
import Slide1 from '../../../assets/MapMe/section1/slider/1.jpg'
import Slide2 from '../../../assets/MapMe/section1/slider/2.jpg'
import Slide3 from '../../../assets/MapMe/section1/slider/3.jpg'
import Slide4 from '../../../assets/MapMe/section1/slider/4.jpg'
import Slide5 from '../../../assets/MapMe/section1/slider/5.jpg'
import Slide6 from '../../../assets/MapMe/section1/slider/6.jpg'
import Slide7 from '../../../assets/MapMe/section1/slider/7.jpg'
import Slide8 from '../../../assets/MapMe/section1/slider/8.jpg'
import Slide9 from '../../../assets/MapMe/section1/slider/9.jpg'
import Slide10 from '../../../assets/MapMe/section1/slider/10.jpg'
import Slide11 from '../../../assets/MapMe/section1/slider/11.jpg'
import Slide12 from '../../../assets/MapMe/section1/slider/12.jpg'
import Logo from "../../../assets/MapMe/section1/Maplogo.png"

const Section1 = () => {
    const  sliderList=[
        Slide1, Slide2, Slide3, Slide4, Slide5,Slide6,Slide7,Slide8,Slide9,Slide10,Slide11,Slide12
    ] 
    return (
        <Strip
            className="strip strip-no-padding me-one"
            containerType="container-fluid  px-0">
            <Strip
                className="strip strip-no-padding"
                containerType="container ">
                <Row className='pb-5 '>
                    
                    <Col md={2} xs={1} className="mt-4 pb-lg-5" >

                    </Col>
                    <Col xs={10} md={8} className="mt-4 pb-lg-5">

                        <div className='map-train  '>
                            <img src={Train} className="train" />
                            <div className='sli-img'>
                                <div className='sli'>
                                    <MapSlider images={sliderList} slide={1} />
                                </div>
                            </div>
                            <img src={Logo} className='beach' />
                        </div>

                    </Col>
                    <Col md={2} xs={1} className="mt-4  pb-lg-5">

                    </Col>
                </Row>
            </Strip>
        </Strip>
    )
}

export default Section1