import React from "react";
import ReactPlayer from "react-player";
import { Row, Col } from "reactstrap";


const VideoPlay = (props) => {
    
    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <div className={props.padding ? null :'pt-2'}>
                    <div className="player-wrapper player-wrapper-small">
                        <ReactPlayer
                            className="react-player"
                            url={`${props.videoLink}`}
                            width="100%"
                            height="100%"
                            playing={props.playing}
                            controls={true}
                             volume={props.volume ? 0:0.5}
                             loop={props.loop}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 },
                                    onUnstarted : true
                                },
                                attributes: {
                                    controlsList: "download",
                                    poster: `${props.videoLink}`,
                                },
                            }}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default VideoPlay;
