import React, { useState } from 'react';
import Select from "react-select";
import Axios from "axios";
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import FileUploader from '../../../customComponent/FileUploader/FileUploader';
import Loader from '../../../customComponent/Loader/Loader';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/ConfirmationMessage/ConfirmationMessage';
import Strip from '../../../customComponent/Strip/Strip';
import PeopleService from '../../../../api/services/PeopleService';
import TeamType from '../../../Utils/Json/teamType';
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';

const AddPeople = (props) => {
    const [photo, setPhoto] = useState('');
    const [photoFileName, setPhotoFileName] = useState('');
    const [photoFile, setPhotoFile] = useState('');
    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('');
    const [designationData, setDesignationData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [typeId, setTypeId] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locationName, setLocationName] = useState('');
    const { toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setName('');
            setDescription('');
            setPhotoFileName('');
            setDesignation('');
            setId('');
            setPhoto('');
            setLocationName('');
        } else {
            setName(props.item.name)
            setDescription(props.item.description);
            let it = props.list.find((item) => item.value === props.item.designationId);
            setDesignation(it);
            let type = TeamType.find((it) => it.value === props.item.typeId);
            setTypeId(type)
            setPhotoFileName(props.item.imgPath);
            setId(props.item.id)
            setPhoto(props.item.imgPath);
            let locationItem  = props.locationList.find((item) => item.value === parseInt(props.item.locationName));
            setLocationName(locationItem);
            
        }
        setIsFileChanged(false);
        setDesignationData(props.list);
        setLocationData(props.locationList);
    }, [isAdd, props]);

    const handleName = (event) => {
        setName(event.target.value)
    };

    const handleDesignation = (item) => {
        setDesignation(item);
    }

    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const getThumbnail = (item) => {
        setPhoto(item[0].base64);
        setPhotoFile(item[0].file);
        setPhotoFileName(item[0].file.name);
        setIsFileChanged(true);
    };

    const handleTypeId = (item) => {
        setTypeId(item)
    }

    const handleLocationName =(item) => {
        setLocationName(item)
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            imgPath: isFileChanged ? `/team/${photoFileName}` : photoFileName,
            id: id,
            name: name,
            description: description,
            designationId: designation ? designation.value : '',
            typeId: typeId ? typeId.value : '',
            userId: userId,
            locationName: locationName ? locationName.value: '',
        };

        if (isFileChanged) {
            UploadImage(photoFile, data)
        } else {
            insertOrUpdate(data);
        }
    }

    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/team/");
        await data.append("image", file);
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
       // console.log('photos : ', data);
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };


    const insertOrUpdate = async (data) => {
        await PeopleService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <Strip
            id="tst-add-people"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="modal-header-container bg-crimson-red">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="external-section--title fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="external-section--title fw-blod align-right">close</p>
                                </Col>
                            </Row>
                        </div>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} xl={5} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                                className="d-flex position-relative justify-content-center"
                                                            >
                                                                {!photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={false}
                                                                        labelName="Upload Image"
                                                                    //isDefault={isDefaultThumbnail}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={`${isFileChanged ? `${photo}` : `${ApiConfig.URL}${photo}`}`}
                                                                        alt={""}
                                                                    />
                                                                )}
                                                                {photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={true}
                                                                        labelName="Upload Image"
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Name*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Name"
                                                                    id="name"
                                                                    name="name"
                                                                    className="form-control common-form-control"
                                                                    value={name}
                                                                    onChange={(event) => handleName(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Location Name:
                                                                </Label>
                                                                <Select
                                                                    value={locationName}
                                                                    onChange={handleLocationName}
                                                                    options={locationData}
                                                                    placeholder={"Select Designation"}
                                                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                                                    isSearchable={true}
                                                                    isClearable
                                                                    maxMenuHeight={200}
                                                                />
                                                                {/* <Input
                                                                    type="text"
                                                                    placeholder="Location Name"
                                                                    id="Location Name"
                                                                    name="Location Name"
                                                                    className="form-control common-form-control"
                                                                    value={locationName}
                                                                    onChange={(event) => handleLocationName(event)}
                                                                /> */}
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Designation*:
                                                              </Label>
                                                                <Select
                                                                    value={designation}
                                                                    onChange={handleDesignation}
                                                                    options={designationData}
                                                                    placeholder={"Select Designation"}
                                                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                                                    isSearchable={true}
                                                                    isClearable
                                                                    maxMenuHeight={200}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    TypeId*:
                                                              </Label>
                                                                <Select
                                                                    value={typeId}
                                                                    onChange={handleTypeId}
                                                                    options={TeamType}
                                                                    placeholder={"Select Type"}
                                                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                                                    isSearchable={true}
                                                                    isClearable
                                                                    maxMenuHeight={200}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Description:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="description"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={description}
                                                                    onChange={(event) => handleDescription(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 0, size: 6 }}
                                                    lg={{ offset: 0, size: 6 }}
                                                    xl={{ offset: 0, size: 6 }}
                                                    className="m-auto mt-3"
                                                >
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                          </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button

                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                                disabled={
                                                                    name &&
                                                                    designation &&
                                                                    photo && typeId ? false : true
                                                                }
                                                            >
                                                                Submit
                                                         </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                </Col>
            </Row>

        </Strip>
    )
}

export default AddPeople;