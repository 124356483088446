import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { SearchOutlined } from '@material-ui/icons';
import DataTable from "react-data-table-component";
import { Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import DashboardLayout from '../../../customComponent/InternalLayout/DashboardLayout';
import RenderPage from '../../../customComponent/RenderPage/RenderPage';
import Strip from '../../../customComponent/Strip/Strip';
import TablesColumns from './TablesColumns';
import Loader from '../../../customComponent/Loader/Loader';
import application_contants from '../../../constants/application_contant';
import { dataTablesMobileStyledTheme, dataTablesStyledTheme } from '../../../Utils/dataTablesStyledTheme';
import MessageConfirmation from '../../../customComponent/MessageConfirmation/MessageConfirmation';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/ConfirmationMessage/ConfirmationMessage';

import AddProject from './AddProject';
import LocationService from '../../../../api/services/LocationService';
import ProjectService from '../../../../api/services/ProjectService';

const ProjectList = () => {
    const [data, setData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterText, setFilterText] = useState();
    const [isAdd, setIsAdd] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [item, setItem] = useState('');
    const [width] = useWindowSize();

    React.useEffect(() => {
        getList();
        getLocationList();
    }, [])

    const getLocationList = async () => {
        await LocationService.getList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    let itemList = [];
                                    let obj = {};
                                    result.data.list && result.data.list.map((item) => {
                                        obj = {
                                            label: item.name,
                                            value: item.id
                                        }
                                        itemList.push(obj)
                                    });

                                    setLocationData([...itemList])
                                    setIsLoading(false);
                                } else {
                                    setLocationData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setLocationData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const getList = async () => {
        await ProjectService.getProjectList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setData(result.data ? [...result.data.list] : []);
                                // alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);

                            } else {
                                setData([]);
                                setIsLoading(false);
                                // alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleToggle = () => {
        setIsModal(!isModal);
        setIsAdd(true)
    };

    const handleClear = () => {
        setFilterText("");
    };

    const handleOnChange = (event) => {
        setFilterText(event.target.value)
    }

    const handleEdit = (item) => {
        setIsModal(!isModal);
        setIsAdd(false);
        setItem(item);
    }

    const handleDelete = async (item) => {
        setIsConfirmModal(true)
        setItem(item);
    }

    const handleRefresh = async () => {
        setIsModal(!isModal);
        await getList();
        setIsAdd(true)
    }

    const handleCancelBtn = async () => {
        setIsConfirmModal(false)
        setItem('');
    }


    const handleConfirmBtn = async () => {
        setIsConfirmModal(false)
        const id = await localStorage.getItem('userId')
        let data = {
            id: item.id,
            status: item.status === 1 ? 0 : 1,
            userId: id,
        };
        setIsLoading(false);
        await ProjectService.enableDisableProject(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                await getList();
                                alertSuccessMessage(`${result.message}`);
                            } else {
                                alertDangerConfirmMessage(`${result.message}`);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <DashboardLayout>
            <RenderPage
                id="about-page"
                className="render-page mt-5"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-about"
                    className="strip strip-no-padding"
                    containerType="container box-shadow"
                >
                    {isModal ?
                        <div>
                            <AddProject
                                isModal={isModal}
                                toggle={handleToggle}
                                isAdd={isAdd} item={item}
                                handleRefresh={handleRefresh}
                                locationData={locationData} />
                        </div> :
                        <div>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Row className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-3 px-0">
                                            <Row className="align-items-center mx-0">
                                                <Col xs={7} sm={7} md={4} lg={3} xl={3}>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <Button
                                                                className="btn custom-theme-btn"
                                                                onClick={() => handleToggle()}
                                                            >
                                                                Add Project
                                                         </Button>
                                                        </div>

                                                    </div>
                                                </Col>
                                                {/* <Col xs={5} sm={5} md={8} lg={9} xl={9}>
                                                    <Row className="justify-content-end">
                                                        <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                                                            <InputGroup className="search__icon-container">
                                                                <InputGroupAddon
                                                                    addonType="append"
                                                                    className="search__icon_append"
                                                                >
                                                                    <InputGroupText className="cursor-pointer search__icon-position">
                                                                        <SearchOutlined
                                                                            style={{
                                                                                color: `${filterText ? "#DC0D45" : "#dfdfdf"}`,
                                                                                fontSize: "1.5rem",
                                                                            }}
                                                                        />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>

                                                                <Input
                                                                    placeholder="Search"
                                                                    name="filter"
                                                                    className="form-control search__form-container"
                                                                    value={filterText}
                                                                    onChange={(event) => handleOnChange(event)}
                                                                />
                                                                {filterText ? (
                                                                    <p
                                                                        className="common-label common-label-small mb-0 reset_label"
                                                                        onClick={() => handleClear()}
                                                                    >
                                                                        Clear
                                                                    </p>
                                                                ) : null}
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                            </Row>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="pb-3 pt-3">
                                        {!isLoading ? (
                                            <DataTable
                                                title=""
                                                columns={TablesColumns(handleEdit, handleDelete)}
                                                data={data}
                                                pagination
                                                // expandableRows
                                                className="data-table"
                                                persistTableHead={false}
                                                highlightOnHover
                                                // expandableRowsComponent={<ExpandedComponent />}
                                                customStyles={
                                                    width > application_contants.MOBILE_BREAKPOINT
                                                        ? dataTablesStyledTheme
                                                        : dataTablesMobileStyledTheme
                                                }
                                            // onRowClicked={(data) => this.getHospitalItem(data)}
                                            />
                                        ) : (
                                            <div className="pt-32px pb-64px d-flex justify-content-center">
                                                <Loader containerClassName="" />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>}
                    {isConfirmModal ? (
                        <MessageConfirmation
                            isModal={isConfirmModal}
                            handleCancelBtn={handleCancelBtn}
                            handleConfirmBtn={handleConfirmBtn}
                            message={`Are you sure you want to ${item.status === 1 ? 'disable' : 'enable'} this project?`}
                        />
                    ) : null}
                </Strip>
            </RenderPage>
        </DashboardLayout>
    )
};

export default ProjectList;