import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Loader from '../../../customComponent/Loader/Loader';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/ConfirmationMessage/ConfirmationMessage';
import Strip from '../../../customComponent/Strip/Strip';
import LocationService from '../../../../api/services/LocationService';

const AddLocation = (props) => {
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setName('');
            setId('');
        } else {
            setName(props.item.name)
            setId(props.item.id)
        }
    }, [isAdd, props]);

    const handleName = (event) => {
        setName(event.target.value)
    };



    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            id: id,
            name: name,
            userId: userId,

        };

        await insertOrUpdate(data)
    }


    const insertOrUpdate = async (data) => {
        await LocationService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <Strip
            id="tst-add-people"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="modal-header-container bg-crimson-red">
                        <Row className="no-margin align-items-center py-2">
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <p className="external-section--title fw-blod">
                                    {isAdd ? "ADD" : "UPDATE "}
                                </p>
                            </Col>
                            <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                className="cursor-pointer"
                                onClick={toggle}
                            >
                                <p className="external-section--title fw-blod align-right">close</p>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={5} className="m-auto">
                            <Row className="m-auto">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="pt-3 pb-5"
                                >
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Location Name*:
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Location Name"
                                                            id="Location Name"
                                                            name="Location Name"
                                                            className="form-control common-form-control"
                                                            value={name}
                                                            onChange={(event) => handleName(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="no-margin">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={{ offset: 0, size: 6 }}
                                            lg={{ offset: 0, size: 6 }}
                                            xl={{ offset: 0, size: 6 }}
                                            className="m-auto mt-3"
                                        >
                                            <Row>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Button
                                                        className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                        onClick={toggle}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Button

                                                        className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                        onClick={() => handleSubmit()}
                                                        disabled={
                                                            name ? false : true
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <div className="loader-position">
                            <Loader containerClassName="" />
                        </div>
                    ) : null}
                </Col>
            </Row>

        </Strip>
    )
}

export default AddLocation;