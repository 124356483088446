
const MenuData = [
    {
        id: 1,
        name: 'about',
        selected: false,
        link: '/about',
        activeClassName: 'title-neon-carrot',
    },
    {
        id: 2,
        name: 'Supporters',
        selected: false,
        link: '/Supporters',
        activeClassName: 'title-kelly-green-light'
    },
    // {
    //     id: 3,
    //     name: 'People',
    //     selected: false,
    //     link: '/people',
    //     activeClassName: 'title-crimson-red'
    // },
    {
        id: 4,
        name: 'Policies',
        selected: false,
        link: '/policies',
        activeClassName: 'title-denim-blue-light'
    },
    {
        id: 5,
        name: 'Projects',
        selected: false,
        link: '',
        activeClassName: 'title-denim-blue-light',
        subMenu:[
            {
            link:"play-city-the-childrens-scrappy-news-service",
            name:"Play City"
        },{
            link:"outdoor-school",
            name:"Outdoor School"
        },{
            link:"get-a-plan",
            name:"Get A Plan"
        },
        {
            link:"goal-goa",
            name:"Goal Goa"
        },
        {
            link:"map-of-me",
            name:"Map Of Me"
        },
        {
            link:"young-entrepreneurs-skills-school-marathi",
            name:"Young Entrepreneurs Skills School"
        },{
            link:"school-tv",
            name:"School TV"
        },{
            link:"the-childrens-scrappy-news-service",
            name:"Scrappy News Service"
        },{
            link:"a-girls-guide-to-21st-century-india",
            name:"Girls Guide"
        },{
            link:"luna",
            name:"Luna"
        },{
            link:"be-an-entrepreneur",
            name:"Be! An Entrepreneur"
        },{
            link:"code-of-bihar",
            name:"Code For Bihar"
        },{
            link:"pitch-it",
            name:"Pitch it!"
        },{
            link:"going-to-school-in-india",
            name:"Going to School in India"
        },
    ]
    },

    {
        id: 6,
        name: 'Financials',
        selected: false,
        link: '/financials',
        activeClassName: 'title-blue-medium'
    },
    {
        id: 7,
        name: 'contact Us',
        selected: false,
        link: '/contact-us',
        activeClassName: 'title-summer-sky'
    },
]

export default MenuData;