import React from "react";
import memoizeOne from "memoize-one";
import application_contants from "../../../constants/application_contant";
import { Button } from "reactstrap";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const TablesColumns = memoizeOne((handleEdit, handleDelete, handleSequentialOrder) => [
    {
        name: "Id",
        selector: "id",
        sortable: false,
        grow: 0.75,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => <div className="py-1 full-width" onClick={() => handleSequentialOrder(row)}><p className="p-small external-section--title title-black-pearl">{row._id}</p></div>,
    },
    {
        name: "Img",
        selector: "imgPath",
        sortable: false,
        grow: 0.2,
        cell: (row) => <div className="py-1"><img src={`${ApiConfig.URL}${row.imgPath}`} alt="" className="width-32px" /></div>,
    },
    {
        name: "Name",
        selector: "name",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{row.name}</p></div>,
    },
    {
        name: "Designation",
        selector: "designationName",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{row.designationName}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{`${row.status === 1 ? 'Active' : 'InActive'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleDelete(row)}>
                    <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">Delete</Button>
                </div>
                <div onClick={() => handleEdit(row)} className="ml-12px">
                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">Edit</Button>
                </div>
            </div>
        ),
    },

]);




export default TablesColumns;
