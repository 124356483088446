import React,{useState} from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../Strip/Strip";
import PlayBg1 from '../../assets/playtocity/play-bg1.jpg';

import ExternalLayout from '../ExternalLayout/ExternalLayout';
import PlaySlider from './component/PlaySlider';
import Slider1 from '../../assets/playtocity/slider/slider1.png';
import Slider2 from '../../assets/playtocity/slider/slider2.png';
import Slider3 from '../../assets/playtocity/slider/slider3.png';
import Slider4 from '../../assets/playtocity/slider/slider4.png';
import Slider5 from '../../assets/playtocity/slider/slider5.png';
import Slider6 from '../../assets/playtocity/slider/slider6.png';
import Slider7 from '../../assets/playtocity/slider/slider7.png';
import Slider8 from '../../assets/playtocity/slider/slider8.png';
import Slider9 from '../../assets/playtocity/slider/slider9.png';
import Slider10 from '../../assets/playtocity/slider/slider10.png';
import Slider11 from '../../assets/playtocity/slider/slider11.png';
import Slider12 from '../../assets/playtocity/slider/slider12.png';
import Scrappy from '../../assets/playtocity/scrappy1.png';
import Air from '../../assets/playtocity/air.png';
import AirTv from '../../assets/playtocity/air-tv.png';
import Section5 from '../../assets/playtocity/sections5.png';
import Panel1 from "../../assets/playtocity/panel1.png";
import Panel2 from "../../assets/playtocity/panel2.png";
import Panel3 from "../../assets/playtocity/panel3.png";
import Card from "../../assets/playtocity/card.png";
import Ground from "../../assets/playtocity/ground.png";
import Child from '../../assets/playtocity/child.png';
import ChildData from "../../assets/playtocity/childData.png";
import Play8 from "../../assets/playtocity/play8.jpg";
import FootballCourt from "../../assets/playtocity/footballs.png";
import Gardern from "../../assets/playtocity/garden.png";
import Wooden from "../../assets/playtocity/wooden.png";
import Dog from "../../assets/playtocity/dog.png";
import Online from "../../assets/playtocity/online.png";
import Tv1 from "../../assets/playtocity/tv.png";
import Tv2 from "../../assets/playtocity/max-tv.png";
import Text1 from "../../assets/playtocity/text.png";
import Text2 from "../../assets/playtocity/text-2.png";
import Airplane from "../../assets/playtocity/airplane1.png";
import Three from "../../assets/playtocity/three.png";
import Small from "../../assets/playtocity/small.png";
import Big from "../../assets/playtocity/big.png";
import VideoPlay from '../VideoPlay/VideoPlay';
import ScrappyPic from '../../assets/playtocity/scrappypic.png';
import IsadoraPic from '../../assets/playtocity/isadorapic.png';
import Climate from '../../assets/playtocity/climate.png';
const PlayCityServices = () => {
    const sliderList = [Slider1, Slider2, Slider3, Slider4, Slider5, Slider6, Slider7, Slider8, Slider9, Slider10, Slider11, Slider12]
    const [show,setShow]=useState(false)
    const [showw ,setShoww]=useState(false)
    return (
        // <ExternalLayout>
        <>
            <Strip
                className="strip strip-no-padding "
                containerType="container-fluid  px-0"
            >
                <Row>
                    <Col xs={12} md={12} lg={12} >
                        <img src={PlayBg1} />
                    </Col>
                </Row>
            </Strip>
            <Strip
                className=" strip-no-padding section2"
                containerType="container-fluid  px-0"
            >
                <Row className=" pt-md-2 pt-4 pb-4">
                    <Col xs={12}>
                        <div className='climate'>
                            <img src={Climate}/>
                        </div>
                    </Col>
                    <Col xs={12} md={1} lg={2} className="mt-md-5">
                    </Col>
                    <Col xs={12} md={10} lg={8} className="mt-md-5">

                        <div className='play-slide'>
                            <PlaySlider images={sliderList} slide={1} />
                        </div>
                    </Col>
                    <Col xs={12} md={1} lg={2} className="mt-md-5">
                    </Col>
                    <Col xs={12} md={12} className="text-center mt-md-5 mb-mt-5">
                        <div className='second-para'>
                            <p>
                                <span> Play City. The Children's Scrappy News Service Season  II ~</span>  Making India's cities giant sustainable places to play for millions of children to play together and find solutions to Climate Change.

                            </p>
                        </div>
                    </Col>
                </Row>
            </Strip>

            <Strip
                className="strip strip-no-padding section3"
                containerType="container-fluid  px-0"
            >
                <Row >
                    <Col xs={12} md={12} lg={12} >
                        <div>
                            <div className='scraapy-img'>
                                <img src={Scrappy} />
                                <div className='scrappy-video'>
                                    <iframe src="https://www.youtube.com/embed/9M4F7Yrw2TM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Strip>
            <Strip
                className="strip strip-no-padding section4"
                containerType="container-fluid  px-0"
            >
                <Col xs={12}>
                    <div className='air-img'>
                        <img src={Air} />
                    </div>

                </Col>
                <Col xs={12}>
                    <div className='air-tv'>
                        <img src={AirTv} />
                        <div className='air-video'>
                            <iframe src="https://www.youtube.com/embed/UMdYL7s580A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </Col>
                <Col xs={12} className="text-center mt-md-4  mt-2 pb-3 air-para">
                    <p>26 episodes of sticky TV propel the scrappy movement forward.</p>
                </Col>
            </Strip>
            <Strip
                className="strip strip-no-padding section5"
                containerType="container-fluid  px-0"
            >
                <Col xs={12} md={12}>
                    <div>
                        <img src={Section5} />
                    </div>

                </Col>
            </Strip>
            <Strip
                className="strip strip-no-padding section6"
                containerType="container-fluid  px-0"
            >
                <Strip
                    className="strip strip-no-padding "
                    containerType="container  px-0"
                >
                    <Row>

                        <Col xs={12} md={12}>
                            <div className='ground-img'>
                                <img src={Ground} />
                            </div>

                        </Col>
                        <Col xs={12} md={12}>
                            <div className='child-img'>
                                <img src={ChildData} />
                            </div>

                        </Col>
                        {/* <Col xs={12} md={6} className="d-flex justify-content-md-end">
                            <div className='child-img'>
                                <img src={Child} />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex ps-md-5">
                            <div className='card-img'>
                                <img src={Card} />
                            </div>
                        </Col> */}
                        <Col xs={12} className="text-center pt-md-4 pt-3 pb-md-3 ">
                            <div>
                                <p className='child-para'>
                                   In 3,000 Government Schools, children take on and solve 26 challenges in a scrapbook made of up-cycled and once-loved-things. For each challenge children get to the bottom of it and play, creating a game that a lot of children can play together across the city to solve the big problem of Climate Change.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={12} className="d-flex justify-content-center mt-3 mt-md-0 mb-md-4 pb-md-5">
                            <div className='panel-img'>
                                <img src={Three} />
                            </div>
                        </Col>
                        {/* <Col xs={12} md={4} className="d-flex justify-content-md-center mt-3 mt-md-0  mb-md-4 pb-md-5">
                            <div className='panel-img'>
                                <img src={Panel2} />
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="d-flex justify-content-md-start  mt-3 mt-md-0  mb-md-4 pb-md-5">
                            <div className='panel-img'>
                                <img src={Panel3} />
                            </div>
                        </Col> */}
                    </Row>
                </Strip>
            </Strip>
            {/* <Strip
                className="strip strip-no-padding section7"
                containerType="container-fluid  px-0"
            >
                <Col xs={12} md={12}>
                    <div>
                        <img src={Play8} />
                    </div>

                </Col>
            </Strip>   */}
            <Strip
                className="strip strip-no-padding section7"
                containerType="container-fluid  px-0"
            >
                <Col xs={12} md={12}>
                    <div className='img-foot'>
                        <img src={FootballCourt} />
                    </div>

                </Col>
                <Col xs={12} md={12} className="pt-3 pb-3 text-center">
                    <p className=' foot-para'>
                        The first challenge is for children to design a sustainable sports pitch for their school or nearby, for girls and boys to play football together. With red-earth for the pitch, vertical gardens and urban farms to reduce the temperature of the pitch
                        and solar to keep the lights on at night, children’s sustainable sports pitch plans are then made real actoss the city. </p>
                </Col>
                <Col xs={12} md={12}>
                    <div>
                        <img src={Gardern} />
                    </div>

                </Col>
            </Strip>
            <Strip
                className="strip strip-no-padding section8"
                containerType="container-fluid  px-0"
            >
                <Row>
                <Col xs={12} md={12}>
                    <div className='online-img'>
                        <img src={Online} />
                        
                    </div>

                </Col>
                <Col xs={12} md={12}>
                    <div className='img-wood'>
                        <img src={Wooden} />
                    </div>

                </Col>
                <Col xs={1} md={2} className="pb-4">
                   

                </Col>
                <Col xs={4} md={3} className="pb-md-4  pt-md-0 pb-2 pt-2 px-0">
                    <div className='small'>
                        <img src={Small} />
                        {/* {
                                    showw ?  */}
                                    <div className='video-1'>  <VideoPlay videoLink="https://youtu.be/kP2XdOap9iI" playing={true} loop={true} volume={true} /> </div>
                                  {/* : 
                                    <div className='scr-pic' onClick={()=>setShoww(true)}>
                                    <img src={ScrappyPic}/>
                                </div>

                                } */}
                        
                    </div>

                </Col>
                <Col xs={6} md={5} className="pb-md-4  pt-md-0 pb-2 pt-2 px-0">
                    <div className='big'>
                        <img src={Big} />
                      

                        {/* {
                                    show ? */}
                                    <div className='video-2'>
                                        <VideoPlay videoLink="https://youtu.be/oRlBCmxZ7V4" playing={true} loop={true} volume={true}  />
                                        </div>
                                         
                                    {/* <div className='iso-pic' onClick={()=>setShow(true)}>
                                    <img src={IsadoraPic}/>
                                </div>

                                } */}
                            </div>

                </Col>
                <Col xs={1} md={2} className="pb-4">
                   

                   </Col>
                </Row>
            </Strip>
            {/* <Strip
                className="strip strip-no-padding section9"
                containerType="container-fluid  px-0"
            >
                <Col xs={12} md={12}>
                    <div >
                        <div  className='videosec'>
                         
                            <div className='video1'>
                            <iframe src="https://www.youtube.com/embed/OZ-H2b96_vQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className='video2'>
                            <iframe src="https://www.youtube.com/embed/OM4kmbggTCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </Col>

            </Strip> */}
            <Strip
                className="strip strip-no-padding section9"
                containerType="container-fluid  px-0"
            >


                {/* <div className='text-one'>
                    <img src={Text1} />

                </div> */}



                <div className='tv-one'>
                    <iframe src="https://www.youtube.com/embed/OZ-H2b96_vQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <div>

                </div>
                {/* <div className='lines'>
                    <img src={Airplane} />

                </div> */}

                {/* <div className='text-two'>
                    <img src={Text2} />

                </div> */}

              
                <div className='tv-two'>
                <iframe src="https://www.youtube.com/embed/OM4kmbggTCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>


            </Strip>
               <Strip
                className="strip strip-no-padding"
                containerType="container-fluid  px-0"
            >
                <Col xs={12} md={12}>
                  <div>
                    <img src={Play8}/>
                  </div>
                </Col>

            </Strip>
            </>
        // </ExternalLayout>
    )
}

export default PlayCityServices