import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { useWindowSize } from "@react-hook/window-size";
import application_contants from "../../constants/application_contant";
import MenuData from "../../Utils/Json/MenuData";
import { NavItem } from "reactstrap";


const ExternalHeader = (props) => {
    const [width, height] = useWindowSize();
    const [isToggle, setIsToggle] = useState(false);
    const [visible, setVisible] = useState(false)
    const handleToggle = () => {
        // let body = document.getElementsByTagName('body')[0];
        setIsToggle(!isToggle);
        // if (!isToggle) {
        //     body.className += 'menu-isOpen';
        // } else {
        //     body.className = body.className.replace('menu-isOpen', '');
        // }
    };

    const handleLink = async (link, name) => {
        props.history.push(`${link}`);
        setIsToggle(!isToggle);
        // let body = document.getElementsByTagName('body')[0];
        // if (!isToggle) {
        //     body.className += 'menu-isOpen';
        // } else {
        //     body.className = body.className.replace('menu-isOpen', '');
        // }
    }

    return (
        <div className="header-container">
            {width >= application_contants.MOBILE_BREAKPOINT ?
                <div className="d-flex justify-content-between align-items-center menu-container">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <Link to="/">
                                    <img src={application_contants.logoImg} alt="" className="logo-container" />
                                </Link>
                            </div>
                            <div className="px-2">
                                <Link to="/" className="link--underline--none">
                                    <h2 className="h2-small external-title title-crimson-red">Going to School</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-end">
                        {MenuData.map(({ id, selected, link, name, activeClassName, subMenu }) => (
                            <>
                                <div className="custom-link--position" key={`menu-${id}`}>
                                    {name === "Projects" ?
                                        <div key={id} onClick={() => { handleLink(link, name); setVisible(!visible) }} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                            className={`custom-link dropdown dropdown-6 ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `custom-link-active dropdown dropdown-6 ${activeClassName}` : ''}`}>
                                            {name}
                                            {/* {visible ? (
                                                <ul className="menu">
                                                    {subMenu && subMenu.map((item, index) => (
                                                        <li className="menu-item" key={index}>
                                                            <a href={`/project/${item.link}`}>
                                                                <button>{item.name}</button>
                                                            </a>

                                                        </li>
                                                    ))

                                                    }


                                                </ul>
                                            ) : null} */}
                                            <ul className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                                            {subMenu && subMenu.map((item, index) => (
                                                        <li className="dropdown_item-1" key={index}>
                                                            <a href={`/project/${item.link}`}>
                                                                {item.name}
                                                            </a>

                                                        </li>
                                                    ))

                                                    }
                                               
                                            </ul>
                                        </div>
                                        :
                                        <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                            className={`custom-link ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `custom-link-active ${activeClassName}` : ''}`}>
                                            {name}
                                        </div>}




                                </div>




                            </>
                        ))}
                    </div>
                </div>
                :
                <div className="d-flex justify-content-between full-width align-items-center">
                    <div className="d-flex align-items-center justify-content-between full-width">
                        <div onClick={() => handleToggle()}>
                            <img src={application_contants.menuImg} alt="" className="menu-icon" />
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="px-2">
                                <Link to="/" className="link--underline--none">
                                    <h4 className="h4-small external-title title-crimson-red">Going to School</h4>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Link to="/">
                                    <img src={application_contants.logoImg} alt="" className="logo-container" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    {isToggle ?
                        <div
                            className={`mobile-header animate__animated ${isToggle ? "animate__fadeInLeft" : "animate__fadeOutLeft"
                                }`} style={{ minHeight: `${height}px` }}>
                            <div>
                                <div onClick={handleToggle} className="close-icon">
                                    <CloseIcon style={{ fontSize: '2.15rem', color: '#DC0D45' }} />
                                </div>
                                <div className="side-menu__logo-contianer pl-2">
                                    <Link to="/">
                                        <img src={application_contants.logoImg} alt="" />
                                    </Link>
                                </div>
                                <div className="px-2">
                                    <Link to="/" className="link--underline--none">
                                        <h2 className="h2-small external-title title-crimson-red">Going to School</h2>
                                    </Link>
                                </div>
                            </div>
                            <div className="pt-4">
                                {MenuData.map(({ id, selected, link, name, activeClassName, subMenu }) => (
                                    <div className="custom-link--mobile-position" key={`menu-${id}`}>
                                        {name === "Projects" ?
                                            <div key={id} onClick={() => { setVisible(!visible) }} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                className={`custom-link dropdown dropdown-6 ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `custom-link-active dropdown dropdown-6 ${activeClassName}` : ''}`}>
                                                {name}

                                                {/* {visible ? (
                                                    <ul className="menu mt-5">
                                                        {subMenu && subMenu.map((item, index) => (
                                                            <li className="menu-item" key={index}>
                                                                <a href={`/project/${item.link}`}>
                                                                    <button>{item.name}</button>
                                                                </a>

                                                            </li>
                                                        ))

                                                        }


                                                    </ul>
                                                ) : null} */}
                                                 {visible && <ul className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                                            {subMenu && subMenu.map((item, index) => (
                                                        <li className="dropdown_item-1" key={index}>
                                                            <a href={`/project/${item.link}`}>
                                                                {item.name}
                                                            </a>

                                                        </li>
                                                    ))

                                                    }
                                               
                                            </ul>}
                                            </div> :
                                            <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                className={`custom-link ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `custom-link-active ${activeClassName}` : ''}`}>
                                                {name}
                                            </div>}

                                    </div>
                                ))}
                            </div>
                        </div>
                        : null}
                </div>}
        </div>
    )
}

export default withRouter(ExternalHeader);