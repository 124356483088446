import React, { useState } from "react";
import { Row, Col } from "reactstrap";

const TitleandParagraph = (props) => {
    return (
        <Row className="mx-0 pt-5" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.sectionTitle ?
                            <div>
                                <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</h2>
                                <p className="p-small external-section--title pb-1 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description ? `${props.data.description}` : ''}</p>
                            </div>
                            :
                            <div>
                                {props.isMapofme ?
                                    <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>
                                            <a href={`https://${props.websiteUrl ? props.websiteUrl : ''}`} className="text-decoration--none" rel="noreferrer" target="_blank" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `MOM ${props.data.locationName}` : ''}</a>
                                        </h2>
                                        <p className="p-small external-section--title pb-1 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                    : <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `${props.data.locationName}` : ''}</h2>
                                        <p className="p-small external-section--title pb-1 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>

                                    </div>}
                            </div>}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TitleandParagraph;
