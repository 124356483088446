import React, { useState } from 'react'
import { Row, Col, FormGroup, Label, Button, Input, Table } from 'reactstrap';
import PostitonModal from "../LocationDetails/PostitonModal";
import ColorCode from '../../../../../Utils/Json/colorCode';


const CountDetails = (props) => {
    const { countList } = props;
    const [count, setCount] = useState("");
    const [countDescription, setCountDescription] = useState("");
    const [countDetails, setCountDetails] = useState([]);
    const [countId, setCountId] = useState(0);
    const [isCountEdit, setIsCountEdit] = useState(false);
    const [countEditIndex, setCountEditIndex] = useState(0);
    const [colorCode, setColorCode] = useState('');
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);

    React.useEffect(() => {
        setCountDetails([...countList])
    }, [countList]);

    const handleCount = event => {
        setCount(event.target.value);
    };

    const handleCountDescription = event => {
        setCountDescription(event.target.value);
    };

    const handleCountCancel = () => {
        setCount("");
        setCountDescription("");
        setColorCode('fff');
    };

    const handleCountSave = () => {
        let data;
        if (isCountEdit) {
            const item = countDetails[countEditIndex];
            item.count = count;
            item.description = countDescription;
            item.colorHex = colorCode;
            setCountDetails([...countDetails]);
            props.handleCountList(countDetails);
        } else {
            data = [
                {
                    count: count,
                    description: countDescription,
                    colorHex: colorCode,
                }
            ];
            setCountDetails([...data, ...countDetails]);
            let array = [...data, ...countDetails]
            props.handleCountList(array);
        }
        handleCountCancel();
        setIsCountEdit(false);
    };


    const handleCountDelete = index => {
        countDetails.splice(index, 1);
        setCountDetails([...countDetails]);
        props.handleCountList(countDetails);
    };

    const handleCountEdit = index => {
        const item = countDetails[index];
        setCountDescription(item.description);
        setCount(item.count);
        setIsCountEdit(true);
        setCountEditIndex(parseInt(index));
        setColorCode(item.colorHex);
    };

    const handleSelectedColor = (label) => {
        setColorCode(label)
    }

    const handlePosition = (index) => {
        setPositionIndex(index)
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionToggle = () => {
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionSave = (toIndex) => {
        const list = [...countDetails];
        //list.splice(0, 0, list.splice(index, 1)[0]);
        var element = list[positionIndex];
        list.splice(positionIndex, 1);
        list.splice(toIndex, 0, element);
        setCountDetails([...list]);
        setIsPositionModal(!isPositionModal);
        props.handleCountList(list);
    }

    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h6 className="h6-small external-title title-bright-grey bg-zircon-grey p-2">
                    Project Count Details :
                </h6>
            </Col>
            <Col
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="m-auto mt-4"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Count :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Count"
                                    id="Count"
                                    name="Count"
                                    className="form-control common-form-control"
                                    value={count}
                                    onChange={event => handleCount(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColor(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Description :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Description"
                                    id="Description"
                                    name="Description"
                                    className="form-control common-form-control"
                                    value={countDescription}
                                    onChange={event =>
                                        handleCountDescription(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col
                        xs={10}
                        sm={10}
                        md={8}
                        lg={6}
                        xl={6}
                        className="ml-auto mb-3"
                    >
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                    onClick={handleCountCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                    onClick={handleCountSave}
                                    disabled={count && countDescription ? false : true}
                                >
                                    Save
                                </Button>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Table bordered responsive>
                                    <thead className="custom-table-thead">
                                        <tr>
                                            <th>Count</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="custom-table-tbody">
                                        {countList.map((it, index) => (
                                            <tr key={index}>
                                                <td>{it.count}</td>
                                                <td>{`${it.description.length > 50 ? `${it.description.substr(0, 50)}...` : it.description} `}</td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div
                                                            onClick={() =>
                                                                handleCountDelete(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                Delete
                                                            </Button>
                                                        </div>
                                                        <div
                                                            className="ml-12px"
                                                            onClick={() =>
                                                                handleCountEdit(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Edit
                                                            </Button>
                                                        </div>
                                                        <div
                                                            className="ml-12px"
                                                            onClick={() =>
                                                                handlePosition(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Position
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            {isPositionModal ?
                <PostitonModal isModal={isPositionModal} handlePositionSave={handlePositionSave} handleToggle={handlePositionToggle} len={countDetails} /> : null}
        </Row>
    )
}


export default CountDetails;