import application_contants from "../../constants/application_contant";
const getPlanJson = [
    {
        description: "Get a Plan is a series of 30 graphic novel adventures led by young women entrepreneur heroes who take on and solve problems of Climate Change in their villages, towns and cities by starting sustainable green enterprises.        ",
        logoPath: application_contants.GetPlan,
        bannerPath: application_contants.ChildernsWithHoney,
        mapPath: application_contants.MapBihar,
        backGrandPath: application_contants.bannerBackGrand,
        mobileBannerImage : application_contants.BannerForMobile,
        desktopBannerImage : application_contants.BannerForDesktop
    },
    {
        title: 'Graphic Novels',
        videoDescription: `Graphic novels are 70-100 pages long, bi-lingual, Hindi/English, gorgeously
        designed. The content explores how to set up and run a sustainable
        enterprise with 100+ hard and soft skills through the series that young
        people need to be an entrepreneur pioneering a new venture in rural India
        with a focus on tackling Climate Change.`,
        videoURL: 'https://www.youtube.com/watch?v=J1pssTrYUSo',
        bgImg: application_contants.BGYellow,
        sectionImg: application_contants.Bee,
        sectionDescription: '',
        sectionTitle: '#YouthEnterprise #BeeKeeping',
        firstImg : application_contants.LandOfLove,
        imageArray: [ application_contants.LandOfLove,application_contants.LandofloveHoney2, application_contants.LandofloveHoney3
        ],
        videoListSection: [],
        videoListTitle: '',
        isVideoList : false,
        playListId : false

    },
    {
        title: "",
        videoDescription: `Graphic novel adventures feature young women starting sustainable
        enterprises to solve problems of Climate Change, powered by clean
        energy, creating jobs for young people where they live.`,
        videoURL: 'https://www.youtube.com/watch?v=7UeZntbH40E',
        bgImg: '',
        sectionDescription: '',
        sectionImg: application_contants.SportBall,
        sectionTitle: '#IHaveAnAmbition #FromSchooltoWork',
        firstImg : application_contants.GirlsPlaying2,
        secondImgs : [application_contants.sportBall2,application_contants.sportBall1],
        imageArray: [application_contants.GirlsPlaying2,
        application_contants.GirlsPlaying3, application_contants.GirlsPlaying4],
        videoListSection: [],
        videoListTitle: '',
        isVideoList : false,
        playListId : false

    },
    {
        title: "",
        videoDescription: `Through an MOU with the Government of Bihar Get a Plan is run in 1,000
        Government Secondary Schools reaching 500,000 young people in
        Grade 9-12. The key KPI for Get a Plan is the number of young people who
        transitioned from school to equitable work or enterprise of their choice
        after Grade 12, with a focus on girls and young women.`,
        videoURL: 'https://www.youtube.com/watch?v=ifcTf1EkESo',
        bgImg: application_contants.BGYellow,
        sectionImg: application_contants.Self_Defence1,
        sectionDescription: '',
        sectionTitle: '#SelfDefence #SkillstoSucceed',
        firstImg:application_contants.Self_Defence2,
        imageArray: [application_contants.Self_Defence2,
        application_contants.Self_Defence3, application_contants.Self_Defence4],
        isVideoList : true,
        playListId : application_contants.PLAYLISTID_Graphic_Novel_videos,
        videoListTitle: 'Click to watch more Get a Plan Graphic Novel videos',
        videoListSection: ['https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF', 'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF',
            'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF', 'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF', 'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF']
    },
     {
        title: "Green Enterprise Internships",
        videoDescription: ``,
        videoImgList: [application_contants.GreenEnterpriseInternships1,
        application_contants.GreenEnterpriseInternships2],
        videoURL: 'https://www.youtube.com/watch?v=FE2RcIDIkAQ',
        bgImg: application_contants.BGGreen,
        imageArray: [],
        sectionImg: application_contants.GreenEnterpriseInternships3,
        isVideoList : true,
        playListId : application_contants.PLAYLISTID_Green_Enterprise_Adventures,
        sectionTitle: '#GreenEnterpriseInternships #21stCenturySkills',
        sectionDescription: `Green Enterprise Internships are 21-day, hands-on, skills experiences with sustainable entrepreneurs - beekeeping, solar, biomass, biogas, garbage recycling enterprises. Our teams design the 21-day skills-action-challenge for young people in Grade 9-12 in Government Schools with the sustainable entrepreneurs making sure they take place in a Covid-19 safe environment, outside. Our teams are present daily with mobile phones to track young people’s learning outcomes. Students choose printed sustainable graphic novel adventures and submit completed action-projects at the end of the internship.\nThe YESS APP hosts the content with baseline and endline assessment quizzes to measure skills outcomes.\nYoung people make a TV series about their experience that airs on Doordarshan in Bihar.`,
        videoListTitle: 'Click to watch the Rough Cut: Green Enterprise Adventures in Bihar TV series',
        videoListSection: ['https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF', 'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF',
            'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF', 'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF', 'https://youtu.be/J1pssTrYUSo?list=PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF']
    },
    {
        title: "Young Entrepreneurs Skills School APP",
        playStoreImg: application_contants.GooglePlay,
        appLog: application_contants.YesLogo,
        appImg: application_contants.Mobile_all,
        description: "Get a Plan has an APP for Android and IOS called the Young Entrepreneurs Skills School (YESS APP) that has the Green Enterprise Internships, TV series, graphic novel video, baseline/endline, complete graphic novel e-book and the action project for upload and assessment that children can access.",
        imageArray: [application_contants.kidswithphone2,application_contants.kidswithphone3,application_contants.YoungEntrepreneurs],
        mobileImageArray : [application_contants.YoungEntrepreneurs2,application_contants.YoungEntrepreneurs1,application_contants.YoungEntrepreneurs3]
    },
];

export default getPlanJson;