import React, { useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
import Slider from "react-slick";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import VideoCardDetails from "./VideoCardDetails";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import VideoDetailsModal from "./VideoDetailsModal";

function SampleNextArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowForwardIosOutlined style={{ color: `#${arrowColor}`, fontSize: '2rem' }}/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick , arrowColor} = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowBackIosOutlined style={{ color: `#${arrowColor}`, fontSize: '2rem' }} />
        </div>
    );
}



const VideoGrid = (props) => {
    const [width] = useWindowSize();
    const [isPreview, setIsPreview] = useState(false);
    const [item, setItem] = useState('');

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: width >= application_contants.MOBILE_BREAKPOINT ? 3 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow arrowColor={props?.data.arrowColor}/>,
        prevArrow: <SamplePrevArrow arrowColor={props?.data.arrowColor}/>
    };

    const handleRead = (path) => {
        window.open(
            `${ApiConfig.URL}${path}`,
            "_blank"
        );
    }

    const handlePreview = (item) => {
        setItem(item)
        setIsPreview(!isPreview);
    }

    const toggle = () => {
        setIsPreview(!isPreview);
    }

    return (
        <Row className="mx-0 pt-5 pb-5" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.sectionTitle ?
                            <div>
                                <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</h2>
                                <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description ? `${props.data.description}` : ''}</p>
                            </div>
                            :
                            <div>
                                {props.isMapofme ?
                                    <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>
                                         <a href={`https://${props.websiteUrl ? props.websiteUrl : ''}`} className="text-decoration--none" rel="noreferrer" target="_blank" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `MOM ${props.data.locationName}` : ''}</a>
                                        </h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                    : <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `${props.data.locationName}` : ''}</h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>

                                    </div>}
                            </div>}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Slider {...settings}>
                            {props.data.videoGalleryList.length > 0 && props.data.videoGalleryList.map((item, index) => (
                                <Row key={index} className="mx-0">
                                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className="pt-3 px-0 video-grid-mobile--position">
                                        <VideoCardDetails item={item} descLen={width >= 1025
                                            ? 200 : width >= 1024 && width <= 1024 ? 100
                                                : width >= 768 && width <= 1023
                                                    ? 200
                                                    : 250}
                                            isBtn={props.isBtn}
                                            handleRead={handleRead}
                                            paramsClassName="card-description--postion"
                                            handlePreview={handlePreview}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Slider>
                    </Col>
                    {isPreview ?
                        <VideoDetailsModal item={item} open={isPreview} toggle={toggle} /> : null}
                </Row>
            </Col>
        </Row>
    )
}

export default VideoGrid;
