import React, { useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { Button, Modal, Box } from "@material-ui/core";
import Slider from "react-slick";
import { useWindowSize } from '@react-hook/window-size';
import {
    ArrowForwardIosOutlined,
    ArrowBackIosOutlined
} from "@material-ui/icons";
import ProjectService from '../../../api/services/ProjectService';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import VideoViewer from './component/VideoViewer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UsaLogo from '../../assets/usa_logo.png'
import SectionBannerService from '../../../api/services/SectionBannerService';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import WorldFire from '../../assets/World_fire.png'
import PlayCity from '../../assets/play_city.png'
 import VideoPlay from '../../customComponent/VideoPlay/VideoPlay';
import application_contants from "../../constants/application_contant";
import Tv1 from '../../assets/tv1.png';
import Tv2 from '../../assets/tv2.png';
import Tv3 from '../../assets/tv3.png';
import Tv4 from '../../assets/tv4.png';
import Tv5 from '../../assets/tv5.png';
import Tv6 from '../../assets/tv6.png';
import Tv7 from '../../assets/tv7.png';
import Episode1 from '../../assets/usa_slider/episode1.png';
import Episode2 from '../../assets/usa_slider/episode2.png';
import Episode3 from '../../assets/usa_slider/episod3.png';
import Episode4 from '../../assets/usa_slider/episod4.png';
import Episode5 from '../../assets/usa_slider/episod5.png';
import Episode6 from '../../assets/usa_slider/episod6.png';
import Episode7 from '../../assets/usa_slider/episod7.png';
import Episode8 from '../../assets/usa_slider/episod8.png';
import Episode9 from '../../assets/usa_slider/episod9.png';
import Episode10 from '../../assets/usa_slider/episod10.png';
import Episode11 from '../../assets/usa_slider/episod11.png';
import Episode12 from '../../assets/usa_slider/episod12.png';
import Episode13 from '../../assets/usa_slider/episod13.png';
import Climate from '../../assets/Climate.png'
import Scrappy1 from '../../assets/scrappy1.jpg'
import Scrappy2 from '../../assets/scrappy2.jpg'
import Scrappy3 from '../../assets/scrappy3.png'
const Usa = () => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [sectionData, setSectionData] = useState([]);
    const [bannerList, setBannerList] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [isModel ,setIsModel]=useState(false);
    const [isModels ,setIsModels]=useState(false);
    const [videoUrl, setVideoUrl] = useState();
     const [videoUrls, setVideoUrls] = useState();
    const [sliderScrappy, setSliderScrappy] = useState([{
        name: Scrappy1,
        //  link:"https://youtu.be/UMdYL7s580A",
    },
    {
        name: Scrappy2,
        // link:"https://youtu.be/zQdAIpQVPUs"
    },
    {
        name: Scrappy3,
        // link:"https://youtu.be/xIrhw7AbQvg"
    },
   
    ])
    const [sliderList, setSliderList] = useState([
    {
        name: Episode1,
        // link:"https://youtu.be/y-oAlI_46hQ"
    },
    {
        name: Episode2,
        // link:"https://youtu.be/I-q1NSMBl6o"
    },
    {
        name: Episode3,
        // link:"https://youtu.be/yKemrpjJUr0"
    },
    {
        name: Episode4,
        // link:"https://youtu.be/dDiV1Le-Pp8"
    },
    {
        name: Episode5,
        // link:"https://youtu.be/dRh9imrDhTc"
    },
    {
        name: Episode6,
        // link:"https://youtu.be/UxCORFgh4G8"
    },
    {
        name: Episode7,
        // link:"https://youtu.be/w2PsAQZ4UY8"
    },
    {
        name: Episode8,
        // link:"https://youtu.be/ZJP-J8BLtA0"
    },
    {
        name: Episode9,
        // link:"https://youtu.be/hSre0oCeSSg"
    },
    {
        name: Episode10,
        // link:"https://youtu.be/RizBzah-tjM"
    },
    {
        name: Episode11,
        // link:"https://youtu.be/OymjtD6ug2E"
    },
    {
        name: Episode12,
        // link:"https://youtu.be/Wgf5z9H4l2E"
    },
    {
        name: Episode13,
        // link:"https://youtu.be/E8Y9LYkIAoc"
    },])
    const handleToggle1 = () => {

        setIsModel(!isModel)
    }
    const handleToggle2 = () => {

        setIsModels(!isModels)
    }
    function getThumb(url) {
        setVideoUrl(url)
        setIsModel(!isModel)
    }
    function getThumbs(url) {
        setVideoUrls(url)
        setIsModels(!isModels)
    }
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} next-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined className="lr-arrow" />
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} prev-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined className="lr-arrow" />
            </div>
        );
    }


    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: width >= 1024 ? 3 : 1,
        slidesToScroll: width >= 1024 ? 3 : 1,
        autoplay: true,
        autoplaySpeed: 8000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };


    const setting = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: width >= 1024 ? 2 : 1,
        slidesToScroll: width >= 1024 ? 2 : 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);



    }, []);





    const handleReadMore = (len) => {
        setIsShow(!isShow);
    }



    return (
        <ExternalLayout>

            <SEOComponent title="Design" subtitle="Thinking, Mass-Media Educational Content Not-for-profit, TV, online, at school" description="Going to School is a design company, a notfor profit eductainment organization for education that works to improve the quality of  education in India through powerful stories that transform the way young people learn at school, online, outside and on national television."
            />

            <RenderPage
                id="about-page"
                className="render-page"
                containerType="container-fluid px-0 usa-main"
            >
                <Strip
                    id="tst-about"
                    className="strip usa-section"
                    containerType="container ps-md-5 pe-md-5"
                >
                    <Row className="mx-0 pt-3  px-0 ">
                        <Col xs={6} lg={6} md={6} className="px-0 mt-md-5 text-start">
                            <h2 >Going to School USA</h2>
                        </Col>
                        <Col lg={6} md={6} xs={6} className="sub_logo text-md-end text-center"><img src={UsaLogo} alt="side_image" /></Col>
                        <Col lg={11} xs={12} className="px-0 text-start mt-3">
                            <p className='lh-30px'>Going to School USA was founded with the audacious goal of equipping millions of children and young people, from low-income groups, anywhere in the world, with the sustainable entrepreneurial skills they need to complete their education and transition from school to equitable work of their choice, with a big hope that many will elect to become the heroes we need for the time we’re in, that is, becoming sustainable entrepreneurs who take on Climate Change for everyone.</p>

                        </Col>
                        <Col lg={8} md={8} xs={12} className="px-0 text-start ">
                            <p className='lh-30px'>Going to School has always had a fierce commitment to girls. </p>
                            <p className='lh-30px'>And so, most of our graphic novel adventure hero stories feature girls and young women taking action to change the world where they live.</p>
                            <p className='lh-30px'>From moving entire communities off grid to be powered by solar light, to taking on the garbage challenge where they live by starting a funky garbage company, composting and sustainable packaging design firm, the girls and women in our projects break new ground, always. </p>
                        </Col>
                        <Col lg={4} md={4} xs={12} className="px-0 text-md-end">
                            <div className='globe_img'>
                                <img src={WorldFire} alt="globe" />
                            </div>
                        </Col>
                        <Col lg={11} xs={12} className="px-0 text-start mt-3">
                            <p className='lh-30px'>While we’re hard at work in India, directly working in 3,000 Government Schools and on national television, around the world we would love to scale Play City, The Children’s Scrappy News Service and connect children and young people around the world in the shared vision of the planet they’d like to live on.</p>

                        </Col>
                    </Row>
                    <Row className="mx-0 pt-3 px-0 ">
                        <Col xs={12} lg={8} md={8} className="px-0 text-start mt-3">
                            <div className='play_img '>
                                <img src={PlayCity} alt="play" />
                            </div>

                        </Col>
                        <Col xs={12} lg={12} md={12} className="px-0 text-start mt-3">
                            <p className='lh-30px'>
                                <strong className='pink_bold1'>Play City, The Children’s Scrappy News Service</strong> is a news-talk-game show edutainment format that runs on TV, online and on the ground in schools. Play City wants children to make cities giant sustainable places to play so one million children can take on Climate Change, and when they are successful, challenge other mega cities to be scrappy and do the same.
                            </p>
                            <p className='lh-30px'>
                                If you’d like to be scrappy or be part of the Play City movement, please do write to us. We’ll get back to you as soon as we can.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mx-0 pt-3 px-0 ">
                        <Col xs={12} lg={12} className="px-0 text-start mt-3">
                            <h2 className='pink_bold '>The Children’s Scrappy News Service Season One</h2>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0  tv_section pb-3">
                            <div className='videocontainer'>
                                <img className='tv1_section' src={Tv1} />
                                <iframe className="tv_video" src="https://www.youtube.com/embed/OZ-H2b96_vQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>

                        </Col>
                    </Row >
                    <Row className="mx-0 pt-3 px-0 ">
                        <Col xs={12} lg={12} className="px-0 text-start mt-3">
                            <h5 className='pink_bold '>The Children's Scrappy News Service - Trailer</h5>
                            <p className='lh-30px text-start'>The Children's Scrappy Service is a makeshift news service that is run by the kids for the kids, who are taking on the biggest problems they face and solving them with scrappy skills and design-thinking.</p>

                        </Col>
                    </Row>
                    <Row className="mx-0 pt-3 px-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pb-4">
                            <Slider {...settings}>
                                {sliderList.map((item, index) => (
                                    <Row key={index} className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                          
                                            <div className='slider_bg' onClick={() => setIsModel(!isModel)}>
                                               
                                                <img src={item.name} alt="slider image" />
                                              
                                            </div>
                                           
                                        </Col>
                                    </Row>
                                ))}
                            </Slider>
                            {
                                                isModel ?<><VideoViewer isModal={true} setIsModal={false} handleToggle={handleToggle1} /></>:null
                                            }
                        </Col>
                    </Row>
                    <Row className="mx-0 pt-3 px-0">
                        <Col xs={12} lg={12} className="px-0 pb-3">
                            <h2 className='pink_bold text-start'>The Children’s Scrappy News Service Season Two</h2>
                        </Col>
                        <Col xs={12} lg={6} md={6} className="px-0 pb-3">
                            <h4 className='pink_bold text-center pb-2'>Scrappy Mumbai</h4>
                            <div className='videocontainer1'>
                                <img className='tv1_section' src={Tv2} />
                                {/* <iframe className="tv_video" src="https://www.youtube.com/embed/UMdYL7s580A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                <iframe className="tv_video" src="https://www.youtube.com/embed/OM4kmbggTCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} md={6} className="px-0 pb-3">
                            <h4 className='pink_bold text-center pb-2'>Scrappy Bengaluru</h4>
                            <div className='videocontainer1'>
                                <img className='tv1_section' src={Tv3} />
                                <iframe className="tv_video" src="https://www.youtube.com/embed/zQdAIpQVPUs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </Col>
                        <Col xs={12} lg={12} md={12} className="px-0 pt-3 pb-3">
                            <p className='lh-30px text-start '>The Children’s Scrappy News Service is a news-talk-game show TV series, made by children in Mumbai and Bengaluru, broadcasting from newsrooms made of once-loved and sustainable things</p>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pb-4">
                            <Slider {...setting}>
                                {sliderScrappy.map((item, index) => (
                                    <Row key={index} className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">

                                            <div className='scrap_banner' onClick={() => setIsModels(!isModels)}>
                                                {/* <a href="https://www.youtube.com/playlist?list=PL1ue9VzrTsiikALENPjq9vr3Ct9d9eMuQ" target="_blank"> */}
                                                <img src={item.name} alt="slider image" />
                                                {/* </a> */}
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Slider>
                            {
                            isModels ?<><VideoViewer isModal={true} setIsModal={false}  handleToggle={handleToggle2} playList={true} /></>:null
                          }
                        </Col>
                    </Row>  
                    <Row className="mx-0 pt-3 px-0">
                        <Col xs={12} lg={12} md={12} className="px-0 pt-3 pb-3">
                            <h2 className='pink_bold text-start'>Scrappy Mexico </h2>
                        </Col>
                        <Col xs={12} md={6} className="px-0 pt-3 pb-3 d-flex justify-content-center">
                           <div>
                           <div className='videocontainer2'>
                                <img className='tv1_section' src={Tv4} />
                                <iframe className="tv_video" src="https://www.youtube.com/embed/OM4kmbggTCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className='data-box'>
                            <h5 className='pink_bold  text-start pt-3 pb-2'>The Children's Scrappy News Service | Mexico</h5>
                            <p className='lh-30px text-start'>The Children's Scrappy News Mexico congratulates Scrappy News India for launching The Children's Scrappy News Service.</p>
                            </div>
                           </div>
                          
                            
                             
                           

                        </Col>
                        <Col xs={12} md={6} className="px-0 pt-3 pb-3 d-flex justify-content-center">
                           <div>
                           <div className='videocontainer2'>
                                <img className='tv1_section' src={Tv5} />
                                <iframe className="tv_video" src="https://www.youtube.com/embed/UxCORFgh4G8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className='data-box'>
                            <h5 className='pink_bold text-start pt-3  pb-2'>No Place to Play | Scrappy News | Mexico</h5>
                            <p className='lh-30px text-start'>Hola Amigo. Welcome to the Children's Scrappy News Service! The world is a mess and on top of it there is no place to play especially for girls. Watch our Scrappy Reporters in Mexico find a solution for girls to play..</p>
                            </div>
                           </div>
                          

                        </Col>
                        <Col xs={12} md={6} className="px-0 pt-3 pb-3 d-flex justify-content-center">
                            <div>
                            <div className='videocontainer2'>
                                <img className='tv1_section' src={Tv6} />
                                <iframe className="tv_video" src="https://www.youtube.com/embed/ke3v_DxFTtY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className='data-box'>
                                <h5 className='pink_bold text-start pt-3 pb-2'>Good Food | Scrappy News | Mexico</h5>
                                <p className='lh-30px text-start'>Hola! Welcome to the Children's Scrappy News Service.You are what you eat. Do you know what is Organic food? Find out what our Scrappy Reporters in Mexico are eating.</p>
                            </div>
                            </div>
                           
                        </Col>
                        <Col xs={12} md={6} className="px-0 pt-3 pb-3 d-flex justify-content-center">
                          <div>
                          <div className='videocontainer2'>
                                <img className='tv1_section' src={Tv7} />
                                <iframe className="tv_video" src="https://www.youtube.com/embed/1UHpi54SlV4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className='data-box'>
                                <h5 className='pink_bold text-start pt-3 pb-2'>Cycling Cities | Scrappy News | Mexico</h5>
                                <p className='lh-30px text-start'>Hola! Riding a bicycle is very good. It's not just fun, it's also good for your health and cheaper than having a car. Above all it doesn't pollute. Our Scrappy Reporters in Mexico City are asking the beautiful people of Mexico about the importance of Cycling</p>
                            </div>
                          </div>
                          
                        </Col>
                    </Row>
                    <Row className="mx-0 pt-3 px-0 d-flex align-items-center">
                        <Col xs={12} md={6} className="px-0 pb-3">


                            <iframe className='usa_video' src="https://www.youtube.com/embed/5Qzb9gskbHE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Col>
                        <Col xs={12} md={6} className="px-0 pb-3">
                            <div className='usa_climate_img'>
                                <img src={Climate} alt="climate change" />
                            </div>
                        </Col>
                        <Col xs={12} md={12} className="px-0 pb-3 text-start" >
                            <p>Going to School USA is a 501C# tax-exempt organization, in New York, New York, USA.</p>
                        </Col>
                    </Row>
                </Strip>



            </RenderPage>
        </ExternalLayout>
    )
}

export default Usa