
const SectionTypeData = [
    {
        label: 'section 1',
        value: 1
    },
    {
        label: 'section 2',
        value: 2
    },
]
export default SectionTypeData;