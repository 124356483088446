import React from 'react'
import ExternalLayout from '../ExternalLayout/ExternalLayout'
import Section1 from './section/Section1'
import Section2 from './section/Section2'
import Section3 from './section/Section3'

const MapOfMe = () => {
  return (
    <div>
        {/* <ExternalLayout> */}
        <Section1/>
        <Section2/>
        <Section3/>
        {/* </ExternalLayout> */}
      
    </div>
  )
}

export default MapOfMe