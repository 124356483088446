import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowSize } from '@react-hook/window-size';
import {
    ArrowForwardIosOutlined,
    ArrowBackIosOutlined
} from "@material-ui/icons";
import VideoPlay from '../../VideoPlay/VideoPlay';

const PlaySlider = ({ images, slide ,styles }) => {
    // console.log(styles)
    const [width] = useWindowSize();
    // const [imageList, setImageList] = useState(images)
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} next-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined className="lr-arrow blue-arrow" />
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} prev-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined className="lr-arrow blue-arrow" />
            </div>
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        slidesToShow: width >= 1024 ? slide : 1,
        slidesToScroll: width >= 1024 ? slide : 1,
        // slidesToShow: slide,
        // slidesToScroll: slide,
         autoplay: true,
        autoplaySpeed: 10000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
  return (
    <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <Slider {...settings}>
                    {images && images.map((item, index) => (

                        <Row key={index} className="mx-0">
                            {/* {console.log(item)} */}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                               <div className={styles ? styles : null}>
                                        <img src={item} alt="slider image " />
                                    </div>
                                
                            </Col>
                        </Row>
                    ))}
                </Slider>
            </Col>
        </Row>
  )
}

export default PlaySlider;