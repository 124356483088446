import React, { useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
import Slider from "react-slick";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import CardCaursolInfo from "../CardCaursolInfo/CardCaursolInfo";
import ImageViewer from "../ImageViewer/ImageViewer";

function SampleNextArrow(props) {
    const { className, style, onClick,arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowForwardIosOutlined style={{ color: `#${arrowColor}`, fontSize: '2rem' }} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowBackIosOutlined style={{ color: `#${arrowColor}`, fontSize: '2rem' }} />
        </div>
    );
}

const ImageCaursolGrid = (props) => {
  //  console.log("imageGrid", props)
    const [width] = useWindowSize();
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [imageData, setImageData] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        loop: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow arrowColor={props?.data.arrowColor}/>,
        prevArrow: <SamplePrevArrow arrowColor={props?.data.arrowColor}/>
    };

    React.useEffect(() => {
        if (props.data) {
            setImageData([...props.data.imgGallery]);
        }
    }, [props.data])

    const handleToggleIndex = (index) => {
        let list = imageData;
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }

    const handleToggle = () => {
        setIsModal(!isModal)
    }

    return (
        <Row className="mx-0 pt-5 pb-5" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.sectionTitle ?
                            <div>
                                <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</h2>
                                <p className="p-small external-section--title robotoSlab-Regular pb-4 lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                            </div> :
                            <div>
                                {props.isMapofme ?
                                    <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>
                                            <a href={`https://${props.websiteUrl ? props.websiteUrl : ''}`}  className="text-decoration--none" rel="noreferrer" target="_blank" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `MOM ${props.data.locationName}` : ''}</a>
                                        </h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                    : <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `${props.data.locationName}` : ''}</h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                }
                            </div>}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.imgGallery.length > 0 ?
                            <Slider {...settings}>
                                {props.data.imgGallery.length > 0 && props.data.imgGallery.map((item, index) => (
                                    <Row key={index} className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3 px-0">
                                            <CardCaursolInfo item={item} handleToggleIndex={handleToggleIndex} index={index} title={props.title}/>
                                        </Col>
                                    </Row>
                                ))}
                            </Slider> : null}
                    </Col>
                    {isModal ?
                        <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                        : null}
                </Row>
            </Col>
        </Row>
    )
}

export default ImageCaursolGrid;
