import React from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import ProgressiveImage from 'react-progressive-bg-image';
import application_contants from '../../constants/application_contant';
import Strip from '../Strip/Strip';
import SEOComponent from '../SEO/SEOComponent';


const ContactUsInfo = (props) => {
    const [width] = useWindowSize();
    return (
        <Strip
            id="tst-contact-us"
            className="strip strip-no-padding"
            containerType="container px-0"
        >
            {props.isInfo ?
              <SEOComponent title="Contact Us" subtitle={`Going to School`}  description="You'd like to collaborate? We'd love to hear from you. Please do call +91 98110 15255"
              />: null}
            <Row className="mx-0 pt-5 pb-5">
                {props.isInfo ?
                <Col xs={11} sm={11} md={10} lg={10} xl={10} className="m-auto">
                    <h2 className="h2-small external-title pb-2">Contact Us</h2>
                    <p className="external-section--title lato-Regular title-bright-grey">
                        For queries, collaboration and more information<span><a href={`mailto: ${`lisa@goingtoschool.com`}`} className="link--underline pl-4px pr-4px">email</a></span>
                        us or call us on <span><a href={`tel: ${`+91 98110 15255`}`} className="link--underline pl-4px pr-4px">+91 98110 15255</a></span>
                    </p>
                </Col>: null}
                {width >= 1024 ?
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pl-5 pr-5 pt-5 pb-5">
                        <ProgressiveImage
                            src={application_contants.contactMapImg}
                            placeholder={application_contants.contactMapImg}
                            blur={2}
                            opacity={0.9}
                            transition="all 1s linear"
                            // style={{ height: width >= 1900 ? 1100 : width === 1024 ? 700 : 950, backgroundSize: width >= 1023 ? "contain" : 'contain', backgroundPosition: "right right", backgroundColor: '#fff' }} 
                             style={{ height: width >= 1900 ? 1100 : width === 1024 ? 700 : 900, backgroundSize: width >= 1023 ? "contain" : 'contain', backgroundPosition: "center", backgroundColor: '#fff' }}
                        />
                        {/* <div className="contact-info-address--contianer">
                            {ContactInfo.map((item, index) => (
                                <div key={index} className="common-width">
                                    <div>
                                        <img src={item.icon} alt="" className="address-icon" />
                                    </div>
                                    <div>
                                        <p className="external-section--title robotoSlab-Medium" style={{ color: item.color }}>{item.title}</p>
                                        {item.location.map((it, index) => (
                                            <div key={index} className="pt-1">
                                                <p className="p-small external-section--title lato-Regular title-bright-grey">{it.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div> */}
                        {/* <div className="contact-logo--contianer">
                            <img src={application_contants.logoImg} alt="" className="logo" />
                        </div>
                        <div className="platinum-logo">
                            <a href={'https://guidestarindia.org.in/Summary.aspx?CCReg=360'} target="_blank" rel="noreferrer">
                                <img src={application_contants.platinumLogoImg} alt="" />
                            </a>
                        </div> */}
                        {/* <div className="platinum-logo">
                            <a href={'https://guidestarindia.org.in/Summary.aspx?CCReg=360'} className="link-platinum" target="_blank" rel="noreferrer">
                            <img src={application_contants.platinumLogoImg} alt="" />
                            </a>
                        </div> */}
                    </Col> :
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 align-center m-auto pt-3 pb-3">
                        {/* <Row className="mx-0 pt-5 pb-5">
                            <Col xs={11} sm={11} md={11} lg={12} xl={12} className="px-0 align-center m-auto">
                                <Row className="mx-0">
                                    {ContactInfo.map((item, index) => (
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} key={index} className="align-left">
                                            <div>
                                                <img src={item.icon} alt="" className="address-icon" />
                                            </div>
                                            <div>
                                                <p className="external-section--title robotoSlab-Medium" style={{ color: item.color }}>{item.title}</p>
                                                {item.location.map((it, index) => (
                                                    <div key={index} className="pt-1">
                                                        <p className="p-small external-section--title lato-Regular title-bright-grey">{it.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row> */}
                        <Row className="mx-0 pt-3">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                {/* <img src={application_contants.mobileMap} alt="" /> */}
                                <img src={application_contants.contactMapImg} alt="" />
                            </Col>
                        </Row>
                        {/* <Row className="mx-0 pt-5 pb-5">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                <Row className="mx-0">
                                    <Col xs={8} sm={8} md={6} lg={12} xl={12} className="px-0 m-auto">
                                        <a href={'https://guidestarindia.org.in/Summary.aspx?CCReg=360'} target="_blank" rel="noreferrer">
                                            <img src={application_contants.platinumLogoImg} alt="" />
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                    </Col>
                }
            </Row>
        </Strip>
    )
}

export default ContactUsInfo;