import React, { useState } from 'react';
import {
    ScrollingProvider,
    useScrollSections,
    Section,
} from 'react-scroll-section';
import { useWindowSize } from '@react-hook/window-size';
import { Row, Col } from 'reactstrap';
import ProjectService from '../../../api/services/ProjectService';
import application_contants from '../../constants/application_contant';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import ImageViewer from '../../customComponent/ImageViewer/ImageViewer';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import Template from '../../customComponent/PreviewModal/Template';
import VideoPlay from '../../customComponent/VideoPlay/VideoPlay';
import GetAPlan from '../../customComponent/GetAPlan/GetAPlan';
// import GoalGoa from '../../customComponent/GoalGoa/GoalGoa';
import GoalGoa from "../../customComponent/GoalGoaNew/GoalGoa"
import SEOComponent from '../../customComponent/SEO/SEOComponent';
// import PlayCity from '../../customComponent/PlayCity/PlayCity';
import PlayCity from '../../customComponent/PlayCityServices/PlayCityServices';
// import MapOfMe from '../../customComponent/MapOfMe/MapOfMe';
import MapOfMe from "../../customComponent/MapMe/MapOfMe";
import GetPlan from '../../customComponent/GetPlan/GetPlan';
import OutDoor from '../../customComponent/OutDoor/OutDoor';
export const DynamicMenu = () => {
    const sections = useScrollSections();
    return (
        <Row>
            {sections.map(({ id, onClick, selected }) => (
                <Col key={id} onClick={onClick} selected={selected} className="d-flex align-items-center cursor-pointer">

                    <p className="external-section--title title-cornflower-blue pb-1">{id}</p>
                </Col>

            ))}
        </Row>
    );
};


export const DynamicsMenu = () => {
    const sections = useScrollSections();
    return (
        <div>
            {sections.map(({ id, onClick, selected }) => (
                <div key={id} selected={selected} className="d-flex align-items-center cursor-pointer">

                    <p className="p-small external-section--title title-black-rock-light pb-1">{id}</p>
                </div>

            ))}
        </div>
    );
};

const ProjectDetails = (props) => {
    const [width] = useWindowSize();
    const [item, setItem] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [locationDetails, setLocationDetails] = useState([]);
    const [location, setLocation] = useState([]);
    const [countViewerList, setCountViewerList] = useState([]);
    const sections = useScrollSections();
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [supporterImgList, setSupporterImgList] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [params, setParams] = useState('');


    React.useEffect(() => {
        window.scrollTo(0, 0);
        let name = props.match.params.name;
        
        setParams(name);
        if (name === application_contants.GETAPLAN || name === 'goal-goa' || name === 'play-city-the-childrens-scrappy-news-service' || name === 'map-of-me'|| name === 'get-a-plan' || name ==='outdoor-school' ) {
            // if (name === application_contants.GETAPLAN || name === 'goal-goa' || name ==='get-a-plan' ) {
            setIsShow(!isShow)
        } else {
            getList(name);
        }
    }, [props.match.params.name])
    // console.log(params)
    const getList = async (id) => {
        await ProjectService.getProjectList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    const details = result.data.list.find((it) => it.projectUrl == id);
                                    if (details.projectLocationDetails) {
                                        const it = JSON.parse(details.projectLocationDetails);
                                        setLocationDetails([...it])
                                    }
                                    if (details.projectCountViewer) {
                                        const it = JSON.parse(details.projectCountViewer);
                                        setCountViewerList([...it])
                                    }
                                    if (details.location) {
                                        const locationInfo = details.location.split(',');
                                        setLocation([...locationInfo])
                                    }
                                    if (details.supportedLogpath) {
                                        const it = JSON.parse(details.supportedLogpath);
                                        setSupporterImgList([...it])
                                    }
                                    setItem(details)
                                }
                                //setData(result.data ?  [...result.data.list] : []);
                                // alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);

                            } else {

                                setIsLoading(false);
                                // alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        // console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                // console.log(err);
                setIsLoading(false);
            });
    }

    const handleToggle = () => {
        setIsModal(!isModal)
    }

    const handleToggleIndex = (index) => {
        let list = ImageData;
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }

    const renderComponent = () => {

        // if (params === application_contants.GETAPLAN) {
        //     return <GetAPlan />
        // }
        if (params === 'play-city-the-childrens-scrappy-news-service') {

            return <PlayCity />
        }
        if (params === 'map-of-me') {

            return <MapOfMe />
        }
        if (params === 'goal-goa') {
            return <GoalGoa />
        } 
        if (params === 'get-a-plan') {
            return <GetPlan/>
        } 
         if (params === 'outdoor-school') {
            return <OutDoor/>
        }
        

        return null
    }
    //  console.log(item)
    return (
        <ExternalLayout>
            <SEOComponent title="Going to School" subtitle={`${props.match.params.name}`} description=""
            />
            <RenderPage
                id="policies-page"
                className="render-page"
                containerType="container-fluid px-0"
            >
                {isShow ?
                    <div>
                        {renderComponent()}
                    </div>
                    :
                    <Strip
                        id="tst-policies"
                        className="strip strip-no-padding"
                        containerType="container-fluid px-0"
                    >
                        <ScrollingProvider>
                            <Row className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                    <Row className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto px-0 project-details-container position-relative">
                                            <div className="full-width">
                                                <img src={`${ApiConfig.URL}${item.bannerPath}`} alt="" className="details-img" />
                                            </div>
                                            {item.title === application_contants.GOALGOA ?
                                                <div className="project-details-img-container p-0">
                                                    <img src={application_contants.GoalGoaCircleImg} alt="" />
                                                </div>
                                                :
                                                <div className="project-details-img-container">
                                                    <img src={`${ApiConfig.URL}${item.logoPath}`} alt="" className="card-logo-img" />
                                                </div>

                                            }
                                        </Col>
                                        <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto pb-5 px-0">
                                            <Row className="align-items-center">
                                                <Col xs={12} sm={12} md={item.websiteUrl === 'www.mapofme.in' ? 6 : 12} lg={item.websiteUrl === 'www.mapofme.in' ? 6 : 12} xl={item.websiteUrl === 'www.mapofme.in' ? 6 : 12}>
                                                    <Row>
                                                        <Col xs={4} sm={4} md={item.websiteUrl === 'www.mapofme.in' ? 4 : 2} lg={item.websiteUrl === 'www.mapofme.in' ? 4 : 2} xl={item.websiteUrl === 'www.mapofme.in' ? 4 : 2} className="px-0">
                                                            {/* <div className="project-details-img-container">
                                                            <img src={`${ApiConfig.URL}${item.logoPath}`} alt="" className="card-logo-img" />
                                                        </div> */}
                                                        </Col>
                                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="px-0 pt-2">
                                                            <h1 className="external-section--title title-crimson-red robotoSlab-Bold pb-1">{item ? item.title : ''}</h1>
                                                            {item.title === application_contants.GOALGOA ? <p className="p-small external-section--title title-denim-blue-light pb-1 lh-30px">#playbackbetter</p> : null}
                                                            {item.websiteUrl ?
                                                                <a href={`https://${item.websiteUrl ? item.websiteUrl : ''}`} rel="noreferrer" target="_blank" className="p-small external-section--title title-cornflower-blue">{item ? item.websiteUrl : ''}</a>
                                                                : null}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {item.websiteUrl === 'www.mapofme.in' ?
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className="pd-location-mobile--position">
                                                        <DynamicMenu />
                                                    </Col> : null}
                                            </Row>
                                        </Col>
                                        <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto pb-5 mobile-col-no--padding">
                                            <Row className="mx-0">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div>
                                                        {item.description && item.description.split(/[\n\r]/g).map((it, index) => (
                                                            <p className="p-small external-section--title title-bright-grey pb-1 lh-30px" key={index}>{it}</p>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {item.videoLink ?
                                            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto pb-5 mobile-col-no--padding">
                                                <VideoPlay videoLink={item.videoLink} />
                                            </Col> :
                                            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto pb-5 mobile-col-no--padding">
                                                {item.title === application_contants.GOALGOA ? <img src={`${application_contants.GoalGoaImg}`} alt="" /> :
                                                    <img src={`${ApiConfig.URL}${item.logoPath}`} alt="" />}
                                            </Col>
                                        }
                                        <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto pt-3 px-0">
                                            <Row className="">
                                                {/* bg-oyster-grey */}
                                                {countViewerList.map((it, index) => (
                                                    <Col xs={6} sm={6} md={6} lg={4} xl={4} key={index} className="pt-2 pb-1 count-bg-container">
                                                        <Row className="pb-2">
                                                            <Col xs={11} sm={11} md={10} lg={11} xl={11} className="m-auto count-bg px-0">
                                                                <div className="count-bg-container" style={{ backgroundColor: `#${it.colorHex}` }}>
                                                                    <div>
                                                                        <h1 className="h1-extra-large counter-tittle external-section--title title-white align-center robotoSlab-Bold pb-2">{it.count}</h1>
                                                                        <p className="p-small external-section--title title-white align-center pb-1 count-desc">{it.description}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                        {item.locationMapPath ?
                                            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="mt-5 pt-5 m-auto pb-5 mobile-col-no--padding m-auto">
                                                <Row className="mx-0 mobile-column-reverse justify-content-center">
                                                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6} className="px-0">
                                                    <Row>
                                                        <Col xs={8} sm={8} md={10} lg={10} xl={8} className="m-auto">
                                                            <div className="pt-3">
                                                                <img src={application_contants.mapofmeIcon} alt="" className="address-icon" />
                                                                <p className="external-section--title robotoSlab-Medium title-red pb-2">{item.title}</p>
                                                                {location.map((it, index) => (
                                                                    <div key={index}>
                                                                        <p className="external-section--title title-dune-grey pb-2 robotoSlab-Medium">{it}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <img src={`${ApiConfig.URL}${item.locationMapPath}`} alt="" />
                                                    </Col>
                                                </Row>
                                            </Col> : null}
                                        <Col xs={10} sm={10} md={10} lg={10} xl={9} className={`m-auto mobile-col-no--padding ${item.points ? 'py-5' : ''}`}>
                                            <div>
                                                {item.points && item.points.split(/[\n\r]/g).map((it, index) => (
                                                    <p className="p-small external-section--title title-bright-grey pb-1 lh-30px bullet-points pb-4" key={index}>{it}</p>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto px-0 pt-5 pb-5 mobile-col-no--padding">
                                            <Row className="mx-0">
                                                {locationDetails.map((it, index) => (
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} className="px-0">
                                                        {item.websiteUrl === 'www.mapofme.in' ?
                                                            <Section id={`${it.locationName ? it.locationName : ''}`}>
                                                                <Row className="mx-0">
                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                                        <Row className="mx-0">
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                                                <Template data={it} isMapofme={true} websiteUrl={item.websiteUrl} title={item.title} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Section> :
                                                            <Row className="mx-0">
                                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                                    <Row className="mx-0">
                                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                                            <Template data={it} isMapofme={false} websiteUrl={''} title={item.title} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                        <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0 pb-5 mobile-col-no--padding">
                                            <Row className="align-items-center mx-0">
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    {supporterImgList.length > 0 ? null :
                                                        <Row className="align-items-center mx-0">
                                                            {item.supportedByName ?
                                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                                                    <p className="external-section--title title-black-pearl">{` Supported by ${item.supportedByName}`}</p>
                                                                </Col> : null}
                                                        </Row>}
                                                    <Row className="align-items-center mx-0 pt-3">
                                                        {supporterImgList.length > 0 ? supporterImgList.map((it, index) => (
                                                            <Col xs={it.imgPath.split('/')[2] === 'IKEA-Foundation.png' || it.imgPath.split('/')[2] === 'BT_Sport_logo.png' ? 6 : 5}
                                                                sm={it.imgPath.split('/')[2] === 'IKEA-Foundation.png' || it.imgPath.split('/')[2] === 'BT_Sport_logo.png' ? 6 : 5}
                                                                md={it.imgPath.split('/')[2] === 'IKEA-Foundation.png' || it.imgPath.split('/')[2] === 'BT_Sport_logo.png' ? 5 : 4}
                                                                lg={it.imgPath.split('/')[2] === 'IKEA-Foundation.png' || it.imgPath.split('/')[2] === 'BT_Sport_logo.png' ? 4 : 3}
                                                                xl={it.imgPath.split('/')[2] === 'IKEA-Foundation.png' || it.imgPath.split('/')[2] === 'BT_Sport_logo.png' ? 4 : 3} key={index}>
                                                                <img src={`${ApiConfig.URL}${it.imgPath}`} alt="" />
                                                            </Col>
                                                        ))
                                                            : null
                                                        }

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ScrollingProvider>
                    </Strip>
                }
                {isModal ?
                    <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                    : null}
            </RenderPage>
        </ExternalLayout>
    )
};


export default ProjectDetails;