import React, { useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import SupporterService from '../../../api/services/SupporterService';
import application_contants from '../../constants/application_contant';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import Strip from '../../customComponent/Strip/Strip';
import SupportBanner from '../../assets/support_banner.png'
import Plan from '../../assets/plan1.png'
import datas from './data';
import { useWindowSize } from '@react-hook/window-size';
const Support = () => {
    const [data, setData] = useState(datas);
    const [width] = useWindowSize();
    React.useEffect(() => {
        window.scrollTo(0, 0);

    }, [])



    return (
        <ExternalLayout>
            <SEOComponent title="Supporters, Foundations" subtitle="Going to School" description="Going to School supporters, partners, foundations believe in the potential of young people to transform their lives and the world around them."
            />
            <RenderPage
                id="support-page"
                className="render-page "
                containerType="container-fluid"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                        <img src={SupportBanner} alt="support_banner" />
                    </Col>
                </Row>
                <Strip
                    id="tst-support"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-col-no--padding">
                            <Row>

                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto pt-5">
                                    <h1 className="external-main--heading support_heading pb-2"> Our Supporters</h1>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto pb-5 card-data">
                                    <Row className="pb-5">
                                        {data.length > 0 && data.map((item, index) => (
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} className="mb-5 ">

                                                <Card className="card-container p-0">
                                                    <CardBody className="card-body--container ">
                                                        {item.company_logo.length > 0 ?
                                                            <Row className="mx-0  align-items-center " >
                                                                {item.company_logo.map((it, index) => (
                                                                    <Col xs={6} md={4} key={index} className="ps-0 pb-2">
                                                                        <img src={it} alt="asfsdfgsdf" className='pe-3 ps-0' />
                                                                    </Col>
                                                                ))}
                                                            </Row> : null}

                                                        <Row className='d-flex align-items-end'>
                                                            <Col lg={9} xs={12} md={10}><h4 className=" external-main--heading head-font mt-3 mb-md-0 mb-3 ">{item.heading1}</h4></Col>
                                                          {console.log(item.side_logo)}
                                                            {item.side_logo ? item.side_logo === "/static/media/Plan12.0487a3a4.png" ? <Col lg={2} xs={12} md={2} className={width<= 480 ?"sub_logo text-md-end text-start pb-1":"support_plan text-md-end text-start pb-1" }><img src={item.side_logo} alt="side_image" /></Col> : item.side_logo==="/static/media/Plans.f8464adc.png" ? <Col lg={3} xs={12} md={3} className="sub_logo1 text-md-end text-start pb-1 "><img src={item.side_logo} alt="side_image" /></Col> : <Col lg={3} xs={12} md={3} className="sub_logo text-md-end text-start pb-1 "><img src={item.side_logo} alt="side_image" /></Col>:null}
                                                        </Row>

                                                        {item.para.map((its, index) => (<p className="external-section--title text-dark lh-30px pb-2 pt-2" key={index} >{its}</p>))}
                                                        {item.heading2 !== "" && item.heading2 !== undefined ?
                                                            <>
                                                                <Row className='d-flex align-items-end'>
                                                                    <Col lg={9} md={9} xs={12} className=" mt-0 "><h4 className=" external-main--heading  head-font mt-md-0 mt-3 mb-md-0 mb-3 ">{item.heading2}</h4></Col>

                                                                    <Col lg={3} md={3} xs={12} className="sub_logo text-md-end text-start p-md-0 pt-2 pb-2"><img src={item.side_logo1} alt="side_image" /></Col>
                                                                </Row>

                                                                {item.para1.map((its, index) => (<p className="external-section--title 
                                                                text-dark lh-30px pb-2" key={index} >{its}</p>))}</> : null
                                                        }

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};


export default Support;