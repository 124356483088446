import React, { useState } from "react";
import Select from "react-select";
import Axios from "axios";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button
} from "reactstrap";
import FileUploader from "../../../customComponent/FileUploader/FileUploader";
import Loader from "../../../customComponent/Loader/Loader";
import {
  alertDangerConfirmMessage,
  alertSuccessMessage
} from "../../../customComponent/ConfirmationMessage/ConfirmationMessage";
import Strip from "../../../customComponent/Strip/Strip";
import SectionBannerService from "../../../../api/services/SectionBannerService";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const AddBanner = props => {
  console.log("AddBanner props :", props);

  const [photoDesktop, setPhotoDesktop] = useState("");
  const [photoDesktopFileName, setPhotoDesktopFileName] = useState("");
  const [photoDesktopFile, setPhotoDesktopFile] = useState("");

  const [photoMobile, setPhotoMobile] = useState("");
  const [photoMobileFileName, setPhotoMobileFileName] = useState("");
  const [photoMobileFile, setPhotoMobileFile] = useState("");
  const [section, setSection] = useState("");
  const [id, setId] = useState("");
  const [sectionData, setSectionData] = useState([]);
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [isFileDesktopChanged, setIsFileDesktopChanged] = useState(false);
  const [isFileMobileChanged, setIsFileMobileChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const { isModal, toggle, isAdd } = props;

  React.useEffect(() => {
    if (isAdd) {
      setPhotoDesktopFileName("");
      setPhotoMobileFileName("");
      setId("");
      setPhotoDesktop("");
      setPhotoMobile("");
      setSection('');
      setStatus('1');
    } else {
      setId(props.item.id);
      setPhotoDesktopFileName(props.item.bannerDesktop);
      setPhotoMobileFileName(props.item.bannerMobile);
      setPhotoDesktop(props.item.bannerDesktop);
      setPhotoMobile(props.item.bannerMobile);
      let it = props.list.find((item) => item.value === props.item.sectionType);
      setSection(it);
      setStatus(props.item.status);

    }
    setIsFileChanged(false);
    setIsFileDesktopChanged(false);
    setIsFileMobileChanged(false);
    setSectionData(props.list);
  }, [isAdd, props]);


  const handleSection = item => {
    setSection(item);
  };

  const getThumbnailDesktop = item => {
    setPhotoDesktop(item[0].base64);
    setPhotoDesktopFile(item[0].file);
    setPhotoDesktopFileName(item[0].file.name);
    setIsFileDesktopChanged(true);
  };
  const getThumbnailMobile = item => {
    setPhotoMobile(item[0].base64);
    setPhotoMobileFile(item[0].file);
    setPhotoMobileFileName(item[0].file.name);
    setIsFileMobileChanged(true);
  };


  const handleSubmit = async () => {
   // console.log('section :', section)
    const userId = await localStorage.getItem("userId");
    let data = {
      userId: userId,
      id: id,
      sectionType: section ? section.value : '',
      bannerDesktop: isFileDesktopChanged ? `/banner/${photoDesktopFileName}` : photoDesktopFileName,
      bannerMobile: isFileMobileChanged ? `/banner/${photoMobileFileName}` : photoMobileFileName,
      status: status
    };

    // insertOrUpdate(data);
    if (isFileDesktopChanged) {
        UploadImage(photoDesktopFile, data)
    } else {
        insertOrUpdate(data);
    }
  };

  const UploadImage = async (file, insertOrUpdateData) => {
    console.log("file", file);
    const data = await new FormData();
    await data.append("floderPath", "uploads/banner/");
    await data.append("image", file);
    setIsLoading(true);

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    console.log("photos : ", data);
    Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
      .then(async res => {
        if (res.status === 200) {
          try {
            if (res.data.code === 0) {
              await insertOrUpdate(insertOrUpdateData);
            } else {
              setIsLoading(false);
              alertDangerConfirmMessage(`${res.data.message}`);
            }
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch(err => {
        console.log("We will be back soon.");
        return err;
      });
  };

  const insertOrUpdate = async data => {
    await SectionBannerService.insertOrUpdate(data)
      .then(async res => {
        if (res.status === 200) {
          try {
            res.text().then(async res => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                alertSuccessMessage(`${result.message}`);
                props.handleRefresh();
                setIsLoading(false);
              } else {
                setIsLoading(false);
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Strip
      id="tst-add-project"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row className="mx-0 box-shadow">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
          <div className="bg-crimson-red">
            <Row className="no-margin align-items-center py-2">
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <p className="external-section--title fw-blod py-1">
                  {isAdd ? "ADD" : "UPDATE "}
                </p>
              </Col>
              <Col
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                className="cursor-pointer"
                onClick={toggle}
              >
                <p className="external-section--title fw-blod align-right cursor-pointer">
                  Back
                </p>
              </Col>
            </Row>
          </div>
          <Row className="mx-0">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="m-auto px-0"
            >
              <Row className="m-auto">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-3 pb-5"
                >
                  <Row className="mt-2">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form>
                        <FormGroup row className="mx-0 align-items-center mt-3">
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={4}
                            className="m-auto mt-4"
                          >
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="d-flex position-relative m-auto">
                                {!photoDesktop ? (
                                  <FileUploader
                                    isImg={true}
                                    getThumbnail={item => getThumbnailDesktop(item)}
                                    isEditable={false}
                                    labelName="Upload Banner"
                                  //isDefault={isDefaultThumbnail}
                                  />
                                ) : (
                                  <img
                                    src={`${isFileDesktopChanged ? `${photoDesktop}` : `${ApiConfig.URL}${photoDesktop}`
                                      }`}
                                    alt={""}
                                  />
                                )}
                                {photoDesktop ? (
                                  <FileUploader
                                    isImg={true}
                                    getThumbnail={item => getThumbnailDesktop(item)}
                                    isEditable={true}
                                    labelName="Upload Banner"
                                  />
                                ) : null}

                              </Col>
                              {/* <Col xs={6} sm={6} md={6} lg={6} xl={6} className="d-flex position-relative">
                                {!photoMobile ? (
                                  <FileUploader
                                    isImg={true}
                                    getThumbnail={item => getThumbnailMobile(item)}
                                    isEditable={false}
                                    labelName="Upload Mobile Banner"
                                  />
                                ) : (
                                  <img
                                    src={`${isFileChanged ? `${photoMobile}` : `${photoMobile}`
                                      }`}
                                    alt={""}
                                  />
                                )}
                                {photoMobile ? (
                                  <FileUploader
                                    isImg={true}
                                    getThumbnail={item => getThumbnailMobile(item)}
                                    isEditable={true}
                                    labelName="Upload Mobile Banner"
                                  />
                                ) : null}

                              </Col> */}
                            </Row>
                          </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center mt-3">
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={4}
                            className="m-auto mt-4"
                          >
                            <Label className="common-label text-left">
                              Select section*:
                            </Label>

                            <Select
                              value={section}
                              onChange={handleSection}
                              options={sectionData}
                              placeholder={"Select Location"}
                              classNamePrefix="common-select-drop-down common-select-drop-down-large"
                              isSearchable={true}
                              isClearable
                              maxMenuHeight={200}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                  <Row className="no-margin py-2 mt-4 mx-0">
                    <Col
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      xl={3}
                      className="m-auto"
                    >
                      <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Button
                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                            onClick={toggle}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Button
                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                            onClick={handleSubmit}
                            disabled={section && photoDesktopFileName ? false : true}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {isLoading ? (
            <div className="loader-position">
              <Loader containerClassName="" />
            </div>
          ) : null}
        </Col>
      </Row>
    </Strip>
  );
};

export default AddBanner;
