import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { KeyboardArrowDown , ImportContactsOutlined, PlayArrowOutlined} from '@material-ui/icons';
import { Row, Col, Card, CardBody, Button } from "reactstrap";


const VideoCardDetails = (props) => {
    return (
        <Card className={`project-container cursor-pointer`} onClick={() => props.handlePreview(props.item)}>
            <CardBody className="project-body--container card-body--small">
                <Row className="mx-0 project-cards-list" style={{ border: `3px dotted ${props.item.hexCode.includes('#') ? props.item.hexCode : `#${props.item.hexCode}` }` }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={'pt-2'}>
                            <div className="player-wrapper">
                                <ReactPlayer
                                    className="react-player"
                                    url={`${props.item.videoLink}`}
                                    width="100%"
                                    height="100%"
                                    playing={false}
                                    controls={true}
                                    volume={0.5}
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 },
                                        },
                                        attributes: {
                                            controlsList: "download",
                                            poster: `${props.item.videoLink}`,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="card-info-container">
                        <h6 className="h6-small external-title pb-2 pt-2" style={{ color: props.item.hexCode.includes('#') ? `${props.item.hexCode}` : `#${props.item.hexCode}` }}>{`${props.item.videoTitle && props.item.videoTitle.length > 20 ? `${props.item.videoTitle.substr(0, 20)}...` : props.item.videoTitle}`}</h6>
                        <p className={`p-small external-section--title pb-1 lh-25px ${props.paramsClassName}`} style={{ color: props.item.hexCode.includes('#') ? `${props.item.hexCode}` : `#${props.item.hexCode}` }}>{`${props.item.videoDescription && props.item.videoDescription.length > props.descLen ? `${props.item.videoDescription.substr(0, props.descLen)}...` : `${props.item.videoDescription}`}`}</p>
                    </Col>
                    {props.isBtn ? <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3 pb-3 px-0">
                        <Row className="mx-0 align-items-center">
                            <Col>
                                <Button
                                    className="btn btn-block custom-cornflower-blue-btn custom-btn--very-small br-4px d-flex align-items-center justify-content-center"
                                  onClick={() => props.handleRead(props.item.pdfPath)}
                                >
                                    <ImportContactsOutlined style={{
                                        fontSize: '1.1rem',
                                        marginRight: '8px',
                                    }}/>
                                    Read
                               </Button>
                            </Col>
                            <Col>
                            <Button
                                    className="btn btn-block custom-cornflower-blue-btn custom-btn--very-small br-4px d-flex align-items-center justify-content-center"
                                 //  onClick={handleSubmit}
                                >
                                    <PlayArrowOutlined style={{
                                        fontSize: '1.4rem',
                                        marginRight: '8px',
                                    }}/>
                                    Watch
                               </Button>
                            </Col>
                        </Row>
                    </Col> :
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ color: props.item.hexCode.includes('#') ? `${props.item.hexCode}` : `#${props.item.hexCode}` }} className="align-center cursor-pointer">
                            <KeyboardArrowDown className="down-arrow" />
                        </Col>}
                </Row>
            </CardBody>
        </Card>
    )
}
VideoCardDetails.propTypes = {
    paramsClassName: PropTypes.string,
  };
  
  VideoCardDetails.defaultProps = {
    paramsClassName: "container card-description-small--postion",
  };

export default VideoCardDetails;
