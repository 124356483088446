import React, { useState } from 'react';
import Axios from "axios";
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Loader from '../../../customComponent/Loader/Loader';
import Select from "react-select";
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/ConfirmationMessage/ConfirmationMessage';
import Strip from '../../../customComponent/Strip/Strip';
import FinancialService from '../../../../api/services/FinancialService';
import FileUploader from '../../../customComponent/FileUploader/FileUploader';
import FinancialType from '../../../Utils/Json/financialType';
import ColorCode from '../../../Utils/Json/colorCode';
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';

const AddFinancial = (props) => {
    const [id, setId] = useState('');
    const [reportType, setReportType] = useState('');
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [pdfFileName, setPdfFileName] = useState('');
    const [pdfFile, setPdfFile] = useState('');
    const [colorCode, setColorCode] = useState("fff");
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setReportType('');
            setId('');
            setStartYear('');
            setEndYear('');
            setColorCode('fff');
        } else {
            setStartYear(props.item.startYear);
            setEndYear(props.item.endYear);
            setId(props.item.id);
            const type = FinancialType.find((it) => it.value === parseInt(props.item.reportType));
            setReportType(type);
            setColorCode(props.item.colorCode);
            setPdfFileName(props.item.path);
        }
    }, [isAdd, props]);

    const getPdfFile = async(item) =>{
        await setPdfFile(item[0].file);
        await setPdfFileName(item[0].file.name);
        setIsFileChanged(true);
    }

    const handleReport = (item) => {
        setReportType(item)
    };

    const handleStartYear = (event) => {
        const value = event.target.value.replace(/[a-z]/ig, '');
        setStartYear(value)
    };

    const handleEndYear = (event) => {
        const value = event.target.value.replace(/[a-z]/ig, '');
        setEndYear(value)
    };

    const handleSelectedColor = (label) => {
        setColorCode(label)
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            id: id,
            startYear: startYear,
            endYear: endYear,
            path: isFileChanged ? `/report/${pdfFileName}` : pdfFileName,
            colorCode: colorCode,
            reportType: reportType ? reportType.value : '',
            userId: userId,
        };

        if (isFileChanged) {
            UploadFiles(pdfFile, data)
        } else {
            insertOrUpdate(data);
        }
    }


    const UploadFiles = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/report/");
        await data.append("image", file);
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        console.log('photos : ', data);
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };

    const insertOrUpdate = async (data) => {
        await FinancialService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <Strip
            id="tst-add-people"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="modal-header-container bg-crimson-red">
                        <Row className="no-margin align-items-center py-2">
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <p className="external-section--title fw-blod">
                                    {isAdd ? "ADD" : "UPDATE "}
                                </p>
                            </Col>
                            <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                className="cursor-pointer"
                                onClick={toggle}
                            >
                                <p className="external-section--title fw-blod align-right">close</p>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={5} className="m-auto">
                            <Row className="m-auto">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="pt-3 pb-5"
                                >
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form>
                                                <FormGroup
                                                    row
                                                    className="mx-0 align-items-center"
                                                >
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Report Type :
                                                        </Label>
                                                        <Select
                                                            value={reportType}
                                                            onChange={handleReport}
                                                            options={FinancialType}
                                                            placeholder={"Select Location"}
                                                            classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                                            isSearchable={true}
                                                            isClearable
                                                            maxMenuHeight={200}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup
                                                    row
                                                    className="mx-0 align-items-center"
                                                >
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Start Year :
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Start Year"
                                                            id="Start Year"
                                                            name="Start Year"
                                                            className="form-control common-form-control"
                                                            value={startYear}
                                                            pattern="\d*" 
                                                            maxLength="4" 
                                                            onChange={event => handleStartYear(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup
                                                    row
                                                    className="mx-0 align-items-center"
                                                >
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            End Year :
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="End Year"
                                                            id="End Year"
                                                            name="End Year"
                                                            className="form-control common-form-control"
                                                            value={endYear}
                                                            pattern="[0-9]*" 
                                                            maxLength="4" 
                                                            onChange={event => handleEndYear(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup
                                                    row
                                                    className="mx-0 align-items-center"
                                                >
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left d-flex align-items-center">
                                                            <div>
                                                                Color Code:
                                                            </div>
                                                            <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                                                            </div>

                                                        </Label>
                                                        <div className="d-flex flex-wrap">
                                                            {ColorCode.map((it, index) => (
                                                                <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColor(`${it.label}`)}>

                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup
                                                    row
                                                    className="mx-0 align-items-center"
                                                >
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Upload Report File :
                                                        </Label>
                                                        <FileUploader
                                                            isImg={false}
                                                            getPdfFile={(item) =>
                                                                getPdfFile(item)
                                                            }
                                                            value={pdfFileName}
                                                            isEditable={false}
                                                            labelName="Upload Report File"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="no-margin">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={{ offset: 0, size: 6 }}
                                            lg={{ offset: 0, size: 6 }}
                                            xl={{ offset: 0, size: 6 }}
                                            className="m-auto mt-3"
                                        >
                                            <Row>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Button
                                                        className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                        onClick={toggle}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Button

                                                        className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                        onClick={() => handleSubmit()}
                                                        disabled={
                                                            startYear && endYear && reportType && pdfFileName && colorCode ? false : true
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <div className="loader-position">
                            <Loader containerClassName="" />
                        </div>
                    ) : null}
                </Col>
            </Row>

        </Strip>
    )
}

export default AddFinancial;