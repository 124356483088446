import React, { useState } from "react";
import Axios from "axios";
import Select from "react-select";
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    Input,
} from "reactstrap";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ProjectService from "../../../../api/services/ProjectService";
import {
    alertDangerConfirmMessage,
    alertSuccessMessage
} from "../../../customComponent/ConfirmationMessage/ConfirmationMessage";
import FileUploader from "../../../customComponent/FileUploader/FileUploader";
import CountDetails from "./components/CountInformation/CountDetails";
import LocationDetails from "./components/LocationDetails/LocationDetails";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";
import Loader from "../../../customComponent/Loader/Loader";
import SectionTypeData from "../../../Utils/Json/sectionType";
import ColorCode from "../../../Utils/Json/colorCode";

const TemplateOne = props => {
    const { toggle, isAdd } = props;
    const [logo, setLogo] = useState('');
    const [logoFileName, setLogoFileName] = useState('');
    const [logoFile, setLogoFile] = useState('');
    const [isLogoFileChanged, setIsLogoFileChanged] = useState(false);

    const [bannerImg, setBannerImg] = useState("");
    const [bannerFileName, setBannerFileName] = useState('');
    const [bannerFile, setBannerFile] = useState('');
    const [isBannerFileChanged, setIsBannerFileChanged] = useState(false);

    const [locationMap, setLocationMap] = useState("");
    const [locationMapFile, setLocationMapFile] = useState("");
    const [locationMapFileName, setLocationMapFileName] = useState("");
    const [isLocationMapFileChanged, setIsLocationMapFileChanged] = useState("");

    const [supporterImg, setSupporterImg] = useState("");
    // const [supporterFileName, setSupporterFileName] = useState('');
    // const [supporterFile, setSupporterFile] = useState('');
    // const [issupporterFileChanged, setIsSupporterFileChanged] = useState(false);
    const [projectImageList, setProjectImageList] = useState([
        {
            files: ''
        },
        {
            files: ''
        },
        {
            files: ''
        },
    ]);

    const [projectTitle, setProjectTitle] = useState("");
    const [projectUrl, setProjectUrl] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [points, setPoints] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [projectVideoURL, setProjectVideoURL] = useState("");
    const [countList, setCountList] = useState([]);
    const [supportedByName, setSupportedByName] = useState('');
    const [sectionType, setSectionType] = useState('');
    const [projectLocation, setProjectLocation] = useState('');
    const [borderColor, setBorderColor] = useState('fff');
    const [backgroundColor, setBackgroundColor] = useState('fff');
    const [pjUrl, setPJUrl] = useState('');

    React.useEffect(() => {
        if (isAdd) {
            setProjectId("");
            setProjectTitle("");
            setProjectUrl("");
            setProjectDescription("");
            setPoints("");
            setCountList([]);
            setLocationList([]);
            setLogo('');
            setBannerImg('');
            setLocationMap('');
            setSupporterImg('');
            setProjectVideoURL('')
            setSupportedByName('');
            setSectionType('');
            setBorderColor('fff');
            setBackgroundColor('fff');
            setPJUrl('')
        } else {
            setProjectId(props.item.id);
            setProjectTitle(props.item.title);
            setProjectUrl(props.item.websiteUrl);
            setProjectDescription(props.item.description);
            setPoints(props.item.points);
            setCountList(props.item.projectCountViewer ? JSON.parse(props.item.projectCountViewer) : []);
            setLocationList(props.item.projectLocationDetails ? JSON.parse(props.item.projectLocationDetails) : []);
            setLogo(props.item.logoPath);
            setBannerImg(props.item.bannerPath);
            setLocationMap(props.item.locationMapPath);
            setSupporterImg(props.item.supportedLogpath ? JSON.parse(props.item.supportedLogpath) : []);
            setProjectVideoURL(props.item.videoLink);
            setSupportedByName(props.item.supportedByName);
            const it = SectionTypeData.find((it) => it.value === props.item.sectionType)
            setSectionType(it);
            setProjectLocation(props.item.location);
            setBackgroundColor(props.item.backgroundColorCode ? props.item.backgroundColorCode : 'fff');
            setBorderColor(props.item.borderColorCode ? props.item.borderColorCode : 'fff');
            setPJUrl(props.item.projectUrl);
        }
        setLocationData(props.locationData);
    }, [props, isAdd]);

    const handleTitle = event => {
        setProjectTitle(event.target.value);
    };

    const handleWebsiteURL = event => {
        setProjectUrl(event.target.value);
    };

    const handleDescription = event => {
        setProjectDescription(event.target.value);
    };

    const handlePoints = event => {
        setPoints(event.target.value);
    };

    const handleSupportedByName = event => {
        setSupportedByName(event.target.value);
    };

    const handleVideoURL = (event) => {
        setProjectVideoURL(event.target.value)
    }

    const getLogo = (item) => {
        setLogo(item[0].base64);
        setLogoFile(item[0].file);
        setLogoFileName(item[0].file.name);
        setIsLogoFileChanged(true);
        projectImageList[0].files = item[0].file;
        setProjectImageList([...projectImageList])
    };

    const getBannerImg = (item) => {
        setBannerImg(item[0].base64);
        setBannerFile(item[0].file);
        setBannerFileName(item[0].file.name);
        setIsBannerFileChanged(true);
        projectImageList[1].files = item[0].file;
        setProjectImageList([...projectImageList])
    };

    const getSupporterImg = (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setSupporterImg([...supporterImg, ...item])
                }

            };
            reader.readAsDataURL(file);
        });
    };

    const handleSupporterDelte = (index) => {
        supporterImg.splice(index, 1);
        setSupporterImg([...supporterImg]);
    }

    const getLocationMap = (item) => {
        setLocationMap(item[0].base64);
        setLocationMapFile(item[0].file);
        setLocationMapFileName(item[0].file.name);
        setIsLocationMapFileChanged(true);
        projectImageList[2].files = item[0].file;
        setProjectImageList([...projectImageList])
    };

    const handleCountList = (item) => {
        setCountList([...item])
    }

    const handleLocationList = (item) => {
        setLocationList([...item])
    }

    const handleSectionType = (item) => {
        setSectionType(item)
    }
    const handleProjectLocation = (event) => {
        setProjectLocation(event.target.value)
    }

    const handleBackgroundColor = (label) => {
        setBackgroundColor(label)
    }
    const handleBorderColor = (label) => {
        setBorderColor(label)
    }

    const handlePJURL =(event) => {
        setPJUrl(event.target.value);
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem("userId");
        let finalFile = [];
        let finalArrayList = [];
        let finalGalleryObj = {};
        let finalPdfObj = {};
        let finalImgObj ={};
        let finalPdfArray = [];
        let finalImgArray = [];
        let finalSupporterList = [];
        let finalSupporterFile = [];
        let finalSupportObj = {};
        let finalGalleryArray = [];
        if (locationList.length > 0) {
            console.log("location", locationList);
            locationList.map((it) => {
                if (!it.isVideo) {
                    if(it.templateType === 8) {
                        if (it.imgGallery.length > 0) {
                            finalImgArray = [];
                            it.imgGallery.map((it) => {
                                if (it.imgFile) {
                                    let obj = {
                                        files: it.imgFile
                                    }
                                    finalFile.push(obj);
                                    finalImgObj = {
                                        imgPath: it.imgFile ? `/pjgalleries/${it.imgFile.name}` : it.imgPath,
                                        imgTitle: it.imgTitle,
                                        imgDescription: it.imgDescription,
                                        hexCode: it.hexCode,
                                    }
                                    finalImgArray.push(finalImgObj);
                                } else {
                                    finalImgObj = {
                                        imgPath: it.imgPath,
                                        imgTitle: it.imgTitle,
                                        imgDescription: it.imgDescription,
                                        hexCode: it.hexCode,
                                    }
                                    finalImgArray.push(finalImgObj);
                                }
                            })
                        }
                        const newArray = finalImgArray;
                        it.imgGallery = newArray;
                        finalArrayList.push(it);
                    }else{
                       if (it.galleryList.length > 0) {
                            finalGalleryArray = [];
                            it.galleryList.map((item) => {
                                if (item.file) {
                                    let obj = {
                                        files: item.file
                                    }
                                    finalFile.push(obj);
                                }
                                finalGalleryObj = {
                                    imgPath: item.file ? `/pjgalleries/${item.file.name}` : item.imgPath,
                                }
                                finalGalleryArray.push(finalGalleryObj);
                            });
                       }
                    const newArray = [...finalGalleryArray];
                    it.galleryList = newArray;
                    finalArrayList.push(it);
                   }
                } else if (it.isVideo && it.templateType === 5) {
                    if (it.videoGalleryList.length > 0) {
                        finalPdfArray = [];
                        it.videoGalleryList.map((it) => {
                            if (it.pdfFile) {
                                let obj = {
                                    files: it.pdfFile
                                }
                                finalFile.push(obj);
                                finalPdfObj = {
                                    pdfPath: it.pdfFile ? `/pjgalleries/${it.pdfFile.name}` : it.pdfPath,
                                    videoLink: it.videoLink,
                                    videoTitle: it.videoTitle,
                                    videoDescription: it.videoDescription,
                                    hexCode: it.hexCode,
                                }
                                finalPdfArray.push(finalPdfObj);
                            } else {
                                finalPdfObj = {
                                    pdfPath: it.pdfPath,
                                    videoLink: it.videoLink,
                                    videoTitle: it.videoTitle,
                                    videoDescription: it.videoDescription,
                                    hexCode: it.hexCode,
                                }
                                finalPdfArray.push(finalPdfObj);
                            }
                        })
                    }
                    const newArray = finalPdfArray;
                    it.videoGalleryList = newArray;
                    finalArrayList.push(it);
                }
                else {
                    finalArrayList.push(it)
                }
            });

        };


        if (supporterImg.length > 0) {
            supporterImg.map((it) => {
                if (it.file) {
                    let obj = {
                        files: it.file
                    }
                    finalSupporterFile.push(obj);
                }
                finalSupportObj = {
                    imgPath: it.file ? `/pjgalleries/${it.file.name}` : it.imgPath,
                }
                finalSupporterList.push(finalSupportObj);
            });
        }


        let data = {
            userId: userId,
            id: projectId,
            title: projectTitle,
            projectUrl: pjUrl,
            websiteUrl: projectUrl,
            sectionType: sectionType ? sectionType.value : '',
            location: projectLocation,
            videoLink: projectVideoURL,
            logoPath: isLogoFileChanged ? `/pjgalleries/${logoFileName}` : logo,
            bannerPath: isBannerFileChanged ? `/pjgalleries/${bannerFileName}` : bannerImg,
            locationMapPath: isLocationMapFileChanged ? `/pjgalleries/${locationMapFileName}` : locationMap,
            supportedLogpath: finalSupporterList.length > 0 ? JSON.stringify(finalSupporterList) : '[]',
            description: projectDescription,
            points: points,
            status: "1",
            projectCountViewer: JSON.stringify(countList),
            projectLocationDetails: JSON.stringify(finalArrayList),
            supportedByName: supportedByName,
            backgroundColorCode: backgroundColor,
            borderColorCode: borderColor
        };
        //console.log("finalArrayList", finalArrayList);
        // console.log("finalFile", finalFile);
        if (isLogoFileChanged || isBannerFileChanged || isLocationMapFileChanged || finalFile.length > 0 || finalSupporterFile.length > 0) {
            const imagesList = [...projectImageList, ...finalFile, ...finalSupporterFile];
            const imagesArrayList = imagesList.filter((it) => it.files !== "");
            UploadImage(imagesArrayList, data)
        } else {
            insertOrUpdate(data);
        }
    };

    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/pjgalleries/");
        await file.map((item) => {
            data.append("image", item.files);
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        if (data) {
            Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
                .then(async (res) => {
                    if (res.status === 200) {
                        try {
                            if (res.data.code === 0) {
                                await insertOrUpdate(insertOrUpdateData);
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${res.data.message}`);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((err) => {
                    console.log("We will be back soon.");
                    return err;
                });
        }
    };

    const insertOrUpdate = async data => {
        await setIsLoading(true);
        await ProjectService.insertOrUpdate(data)
            .then(async res => {
                if (res.status === 200) {
                    try {
                        res.text().then(async res => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    };

    return (
        <Row className="mx-0">
            <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="m-auto px-0"
            >
                <Row className="mt-2">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="d-flex position-relative justify-content-center">
                                {!logo ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getLogo(item)
                                        }
                                        isEditable={false}
                                        //isDefault={isDefaultThumbnail}
                                        labelName="Project Logo"
                                    />
                                ) : (
                                    <img
                                        src={`${isLogoFileChanged ? `${logo}` : `${ApiConfig.URL}${logo}`}`}
                                        alt={""}
                                    />
                                )}
                                {logo ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getLogo(item)
                                        }
                                        isEditable={true}
                                        labelName="Project Logo"
                                    />
                                ) : null}
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="d-flex position-relative justify-content-center">
                                {!bannerImg ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getBannerImg(item)
                                        }
                                        isEditable={false}
                                        //isDefault={isDefaultThumbnail}
                                        labelName="Project Banner"
                                    />
                                ) : (
                                    <img
                                        src={`${isBannerFileChanged ? `${bannerImg}` : `${ApiConfig.URL}${bannerImg}`}`}
                                        alt={""}
                                    />
                                )}
                                {bannerImg ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getBannerImg(item)
                                        }
                                        isEditable={true}
                                        labelName="Project Banner"
                                    />
                                ) : null}
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="d-flex position-relative justify-content-center">
                                {!locationMap ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getLocationMap(item)
                                        }
                                        isEditable={false}
                                        //isDefault={isDefaultThumbnail}
                                        labelName="Project Location Map"
                                    />
                                ) : (
                                    <img
                                        src={`${isLocationMapFileChanged ? `${locationMap}` : `${ApiConfig.URL}${locationMap}`}`}
                                        alt={""}
                                    />
                                )}
                                {locationMap ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getLocationMap(item)
                                        }
                                        isEditable={true}
                                        labelName="Project Location Map"
                                    />
                                ) : null}
                            </Col>

                            {/* <Col xs={12} sm={12} md={3} lg={3} xl={3} className="d-flex position-relative justify-content-center">
                                {!supporterImg ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getSupporterImg(item)
                                        }
                                        isEditable={false}
                                        labelName="Supported Logo"
                                    //isDefault={isDefaultThumbnail}
                                    />
                                ) : (
                                    <img
                                        src={`${issupporterFileChanged ? `${supporterImg}` : `${ApiConfig.URL}${supporterImg}`}`}
                                        alt={""}
                                    />
                                )}
                                {supporterImg ? (
                                    <FileUploader
                                        isImg={true}
                                        getThumbnail={(item) =>
                                            getSupporterImg(item)
                                        }
                                        isEditable={true}
                                        labelName="Upload Supported Logo"
                                    />
                                ) : null}
                            </Col> */}
                        </FormGroup>

                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Project Title*:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Project Title"
                                    id="Project Title"
                                    name="Project Title"
                                    className="form-control common-form-control"
                                    value={projectTitle}
                                    onChange={event => handleTitle(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Project URL:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Project URL"
                                    id="Project URL"
                                    name="Project URL"
                                    className="form-control common-form-control"
                                    value={projectUrl}
                                    onChange={event => handleWebsiteURL(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Project Video:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Project Video URL"
                                    id="Project Video URL"
                                    name="Project Video URL"
                                    className="form-control common-form-control"
                                    value={projectVideoURL}
                                    onChange={event => handleVideoURL(event)}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Project Location:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Project Location"
                                    id="Project Location"
                                    name="Project Location"
                                    className="form-control common-form-control"
                                    value={projectLocation}
                                    onChange={event => handleProjectLocation(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Project Supported By:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Project Supported By Name"
                                    id="Project Supported By Name"
                                    name="Project Supported By Name"
                                    className="form-control common-form-control"
                                    value={supportedByName}
                                    onChange={event => handleSupportedByName(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Section Type:
                                </Label>
                                <Select
                                    value={sectionType}
                                    onChange={handleSectionType}
                                    options={SectionTypeData}
                                    placeholder={"Select Layout Type"}
                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                    isSearchable={true}
                                    isClearable
                                    maxMenuHeight={200}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Background Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${backgroundColor}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleBackgroundColor(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>

                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Border Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${borderColor}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleBorderColor(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Supported Logo:
                                </Label>
                                <div
                                    className={`position-relative`}
                                >
                                    <div className="file-form-container">
                                        <input type="file" value={""} placeholder="Upload File" multiple={true} accept="image/jpeg, image/jpg, image/png" className="form-control common-form-control" onChange={getSupporterImg} />
                                        <p className="file-text">{supporterImg.length > 0 ? `${supporterImg.length} uploaded` : 'upload Files'}</p>
                                    </div>

                                    <div className="pdf-thumbnail-container">
                                        <CloudUploadIcon
                                            style={{
                                                color: "#fff",
                                                fontSize: "2rem",
                                                borderRadius: 4,
                                                padding: 2,
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Row>
                                    {supporterImg.length > 0 && supporterImg.map((it, index) => (
                                        <Col xs={6} ms={6} md={6} lg={3} xl={3} kye={index}>
                                            <div className="position-relative">
                                                <img src={it.imgPath ? `${ApiConfig.URL}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                                <div className="trash-icon-container" onClick={() => handleSupporterDelte(index)}>
                                                    <DeleteIcon style={{
                                                        color: "#fff",
                                                        fontSize: "1rem",

                                                    }} />
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    URL*:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Project Description"
                                    id="Project Description"
                                    name="Project Description"
                                    className="form-control common-form-control"
                                    value={pjUrl}
                                    onChange={event => handlePJURL(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Project Description*:
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Project Description"
                                    id="Project Description"
                                    name="Project Description"
                                    className="form-control common-form-control"
                                    value={projectDescription}
                                    onChange={event => handleDescription(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Points:
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Points"
                                    id="Points"
                                    name="Points"
                                    className="form-control common-form-control"
                                    value={points}
                                    onChange={event => handlePoints(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="align-items-center mt-3">
                            <CountDetails handleCountList={handleCountList} countList={countList} />
                        </FormGroup>

                        <FormGroup className="mx-0 align-items-center mt-3">
                            <LocationDetails locationList={locationList} locationOptions={locationData} handleLocationList={handleLocationList} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="no-margin bg-zircon-grey py-2">
                    <Col
                        xs={12}
                        sm={12}
                        md={4}
                        lg={3}
                        xl={3}
                        className={`${isLoading ? 'm-auto' : 'ml-auto'}`}
                    >
                        {!isLoading ?
                            <Row>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Button
                                        className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                        onClick={toggle}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Button
                                        className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                        onClick={handleSubmit}
                                        disabled={projectTitle && projectDescription && logo ? false : true}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row> : null}
                        {isLoading ? (
                            <div className="d-flex align-item-center justify-content-center">
                                <Loader containerClassName="" />
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default TemplateOne;
