import React, { useState } from 'react';
import { Row, Col, CardBody, Card, FormGroup, Label, Button } from 'reactstrap';
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import { isEmailValid, isPwdValid } from '../../Utils/customValidators';
import Loader from '../../customComponent/Loader/Loader';
import debugLogger from '../../Utils/debugLogger';
import { useAuth } from '../../context/AuthProvider';

const Login = (props) => {
    const [, { login }] = useAuth();
    const [emailId] = useState('');
    const [password] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all"
    });
    const onSubmit = async (data) =>{
        setIsLoading(true);
        try {
            const res = await login(data);
            if (res.code === 0) {
                debugLogger('login res', res);
                setIsLoading(false);
                props.history.push(`/banner-list`)
            } else {
                setIsLoading(false);
                alert(
                    `Invalid Credentials, Please try again`
                );
            }
        } catch (error) {
            setIsLoading(false);
            alert(
                error
            );
        }
    }


    return (
        <RenderPage
            id="login-page"
            className="render-page mt-0 full-height d-flex align-items-center login-bg-color"
            containerType="container-fluid "
        >
            <Strip
                id="tst-login"
                className="strip strip-no-padding"
                containerType="container"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={10} xl={9} className="m-auto">
                        <Card className="card-container">
                            <CardBody className="card-body--container p-0">
                                <Row className="ht-80vh align-items-center">
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="ht-80vh bg-zircon-grey login-mobile-banner--position">
                                        <img src={application_contants.schoolImg} alt="" className="login-banner-img"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Row>
                                            <Col xs={6} sm={6} md={3} lg={3} xl={3} className="m-auto py-3">
                                                <img src={application_contants.logoImg} alt="" />
                                            </Col>
                                        </Row>
                                       <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mx-0 px-3">
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                                    <FormGroup>
                                                        <Label className="common-label">Email</Label>
                                                        <input
                                                            {...register("emailId", {
                                                                required: "emailId is required.",
                                                                pattern: {
                                                                    value: isEmailValid,
                                                                    message: "please enter valid emailId."
                                                                },
                                                            })}
                                                            defaultValue={password}
                                                            className="form-control common-form-control"
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="emailId"
                                                            render={({ messages }) => {
                                                                console.log("messages", messages);
                                                                return messages
                                                                    ? Object.entries(messages).map(([type, message]) => (
                                                                        <p key={type} className="p-small error">{message}</p>
                                                                    ))
                                                                    : null;
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                                    <FormGroup>
                                                        <Label className="common-label">Password</Label>
                                                        <input
                                                            type="password"
                                                            {...register("password", {
                                                                required: "password is required.",
                                                                pattern: {
                                                                    value: isPwdValid,
                                                                    message: "please enter valid password."
                                                                },
                                                            })}
                                                            defaultValue={emailId}
                                                            className="form-control common-form-control"
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="password"
                                                            render={({ messages }) => {
                                                                console.log("messages", messages);
                                                                return messages
                                                                    ? Object.entries(messages).map(([type, message]) => (
                                                                        <p key={type} className="p-small error">{message}</p>
                                                                    ))
                                                                    : null;
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-4 pt-3">
                                                    <Button className="btn btn-block button-mat custom-theme-btn">Submit</Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        {isLoading ? (
                                        <div className="loader-position">
                                            <Loader containerClassName="" />
                                        </div>
                                    ) : null}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Strip>
        </RenderPage>
    )
};


export default Login;