import React from 'react';
import { Col, Row } from 'reactstrap';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import application_contants from '../../constants/application_contant';

const CardCaursolInfo = (props) => {
    const { item, handleToggleIndex, index, title } = props;
    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={6} lg={5} xl={4} className="m-auto ">
                        <div className="bg-zircon-grey gallery-card-img-container cursor-pointer">
                            <img src={`${ApiConfig.URL}${item.imgPath}`} alt="" className="img-item--position" onClick={() => handleToggleIndex(index)} />
                        </div>
                        <div>
                            {/* {title === 'Goal Goa' ? */}
                                {/* <div> */}
                                    {item.imgTitle ?
                                        <h6 className="h6-small external-title pb-2 pt-3" style={{ color: `#${item.hexCode}` }}>{`${item.imgTitle}`}</h6>
                                        : null}
                                    <p className={`p-small external-section--title pb-1 lh-25px pt-3 ${title === application_contants.GOALGOA ? `align-center`: ''}`} style={{ color: `#${item.hexCode}` }}>{`${item.imgDescription}`}</p>
                                {/* </div> : null} */}

                        </div>
                    </Col>
                    {/* {title !== 'Goal Goa' ?
                        <Col xs={12} sm={12} md={6} lg={7} xl={8}>
                            {item.imgTitle ?
                                <h6 className="h6-small external-title pb-2 pt-2" style={{ color: `#${item.hexCode}` }}>{`${item.imgTitle}`}</h6>
                                : null}
                            <p className={`p-small external-section--title pb-1 lh-25px`} style={{ color: `#${item.hexCode}` }}>{`${item.imgDescription}`}</p>

                        </Col> : null} */}
                </Row>

            </Col>
        </Row>
    )
};


export default CardCaursolInfo;