import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import ImageCaursolGrid from "../ImageCaursolGrid/ImageCaursolGrid";
import ImageGrid from "../ImageGrid/ImageGrid";
import PDFGridViewer from "../PDFGridViewer/PDFGridViewer";
import PlayStoreAndSupportedDetails from "../PlayStoreAndSupportedDetails/PlayStoreAndSupportedDetails";
import SupportedImage from "../SupportedImage/SupportedImage";
import TitleandParagraph from "../TitleandParagraph/TitleandParagraph";
import VideoGrid from "../VideoGrid/VideoGrid";
import VideoSingleGrid from "../VideoSingleGrid/VideoSingleGrid";


const PreviewModal = (props) => {
    const [data, setData] = useState([]);
    const { toggle, isMapofme, websiteUrl, title } = props;
    React.useEffect(() => {
        if (props.data) {
            setData([props.data])
        }
    }, [props.data])

    return (
        <div>
            <div>
                <Row className="mx-0 bg-crimson-red align-items-center py-2">
                    <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                        <p className="external-section--title fw-blod">
                            Preview Gallery
                        </p>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <p className="external-section--title fw-blod align-right cursor-pointer" onClick={toggle}>
                            Close
                        </p>
                    </Col>
                </Row>
            </div>
            {props.data.length > 0 ?
                <div className="pt-18px">
                    {props.data.map((it, index) => (
                        <div key={index}>
                            {it.templateType === 1 ?
                                <div>
                                    <ImageGrid data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                            {it.templateType === 2 ?
                                <div className="pt-18px">
                                    <VideoGrid data={it} sectionTitle={false} isBtn={false} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                            {it.templateType === 3 ?
                                <div>
                                    <VideoSingleGrid data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                            {it.templateType === 4 ?
                                <div className="pt-18px">
                                    <VideoGrid data={it} sectionTitle={true} isBtn={false} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                            {it.templateType === 5 ?
                                <div className="pt-18px">
                                    <VideoGrid data={it} sectionTitle={true} isBtn={true} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                            {it.templateType === 6 ?
                                <div className="pt-18px">
                                    <PlayStoreAndSupportedDetails data={it} title={title} />
                                </div>
                                : null}
                            {it.templateType === 7 ?
                                <div className="pt-18px">
                                    <PDFGridViewer data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                            {it.templateType === 8 ?
                                <div>
                                    <ImageCaursolGrid data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                             {it.templateType === 9 ?
                                <div>
                                    <SupportedImage data={it}  isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                            : null}
                            {it.templateType === 10 ?
                                <div>
                                    <TitleandParagraph data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title} />
                                </div>
                                : null}
                        </div>
                    ))}
                </div> :
                <p className="external-section--title fw-blod align-right cursor-pointer" onClick={toggle}>
                    Data not found
                </p>}
        </div>
    )
}


export default PreviewModal;