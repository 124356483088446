import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Col, Label, Row, Input } from "reactstrap";
import Strip from "../../../../../customComponent/Strip/Strip";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        padding: 18,
        border: 0,
        width: '18rem',
        '&:focus': {
            outline: 'none',
        },
    },
}));

const PostitonModal = (props) => {
    const classes = useStyles();
    const [position, setPosition] = useState('');

    const handlePosition = (event) => {
        setPosition(event.target.value)
    }

    return (
        <div>
            <Strip
                id="tst-PostitonModal"
                className="strip strip-no-padding"
                containerType="container-fluid"
            >
                <Row>
                    <Col xs={10} sm={10} md={12} lg={12} xl={12}>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={props.isModal}
                            onClose={props.handleToggle}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            style={{ overflow: 'scroll' }}
                        >
                            <Fade in={props.isModal}>
                                <div className={`${classes.paper}`}>


                                    <Row className="no-margin">
                                        <Col
                                            xs={12}
                                            ms={12}
                                            md={10}
                                            lg={12}
                                            xl={12}
                                            className="m-auto px-0"
                                        >
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    className="m-auto px-0 pb-3"
                                                >
                                                    <Label className="common-label text-left">
                                                        Section Title :
                                                </Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Section Title"
                                                        id="Section Title"
                                                        name="Section Title"
                                                        className="form-control common-form-control"
                                                        value={position}
                                                        onChange={event =>
                                                            handlePosition(event)
                                                        }
                                                    />
                                                    <p className="p-small common-label">Number sholud be 0 to {props.len.length - 1}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="no-margin">
                                        <Col
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            className="m-auto px-0 bg-danger p-3"
                                            onClick={() => props.handleToggle()}
                                        >
                                            <p className="external-section--title title-white text-center cursor-pointer">
                                                Cancel
                                      </p>
                                        </Col>
                                        <Col
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            className="m-auto px-0 bg-mosque-green p-3"
                                            onClick={() => props.handlePositionSave(position)}
                                        >
                                            <p className="external-section--title title-white text-center cursor-pointer">
                                                Submit
                                       </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Fade>
                        </Modal>
                    </Col>
                </Row>
            </Strip>
        </div>
    );
};

export default PostitonModal;
