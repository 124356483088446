import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
import Img1 from "../../../assets/GoalNew/section4/sec4-Img.png";
const Section4 = () => {
  return (
    <>
       <Strip
        className="strip strip-no-padding goalfour"
        containerType="container-fluid  px-0 ">
        <Strip
          className="strip strip-no-padding"
          containerType="container ">
          <Row className='pt-md-2'>
           <Col xs={12} className="mt-4">
              <div className='goal-ball'>
                <img src={Img1}/>
              </div>
           </Col>
           <Col xs={1} className="mt-3">

           </Col>
           <Col xs={10} className="mt-3">
            <div className='goal-p'>
              <p>
              The Outdoor School for Girls runs in schools in Goa, Mumbai and Bengaluru, with football and skills, all week-long, outside. All because of a movie, made in the monsoon.
              </p>
            </div>
           </Col>
           <Col xs={1} className="mt-3">
            
           </Col>
           <Col xs={1} className="mb-5">
            
           </Col>
           <Col xs={12} md={5} className="mb-5">
            <div className='goal-tv1'>
            <iframe  src="https://www.youtube.com/embed/3bq8l8EGZI0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
           </Col>
           <Col xs={12} md={5} className="mb-5">
            <div className='goal-tv1'>
            <iframe src="https://www.youtube.com/embed/3JfSRjUYV1A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>
           </Col>
           <Col xs={12} md={1}className="mb-md-5">
            
            </Col>
            <Col  xs={12} md={1} className="mb-md-5">

            </Col>
            <Col xs={12} md={10} className="mb-5 pb-5">
              <div className='goal-tv2'>
              <iframe  src="https://www.youtube.com/embed/1YGEghLfshY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>

            </Col>
            <Col  xs={12} md={1} className="mb-md-5">

            </Col>
            <Col xs={12} className="mb-md-5 mt-md-5">

            </Col>
          </Row>
          </Strip>
          </Strip>
    </>
  )
}

export default Section4