import React, { useState } from 'react';
import PropTypes from "prop-types";
// import VideoPlay from '../VideoPlay/VideoPlay';
//import VideoPlay from '../VideoPlay/VideoPlay';
import VideoPlay from '../../../customComponent/VideoPlay/VideoPlay';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Row, Col } from 'reactstrap';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        border: 0,
        '&:focus': {
            outline: 'none',
        },
    },
}));
const VideoViewer = ({ isModal, setIsModal,videoItem,handleToggle ,playList}) => {
    const classes = useStyles();
   
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isModal}
            onClose={handleToggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isModal}>
                <div className={`${classes.paper} image-modal-container`}>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                          { playList?
                          <iframe className='usa-play-pop' src="https://www.youtube.com/embed/videoseries?list=PL1ue9VzrTsiikALENPjq9vr3Ct9d9eMuQ&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>: 
                          <iframe className='usa-play-pop' src="https://www.youtube.com/embed/videoseries?list=PLWs87yxhEyVRMs_9FcuVcV34hU7KhQ6L0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          
                        //   <VideoPlay videoLink={videoItem} playing={true}/>
                            }
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Modal>
    )
}
export default VideoViewer;