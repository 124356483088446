import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import Slider from "react-slick";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import application_contants from '../../constants/application_contant';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import CardDetails from '../../customComponent/CardDetails/CardDetails';
import { useWindowSize } from '@react-hook/window-size';
import AboutService from '../../../api/services/AboutService';
import ImageViewer from '../../customComponent/ImageViewer/ImageViewer';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import VideoPlay from '../../customComponent/VideoPlay/VideoPlay';
import Magazine from "../../assets/magazine.jpg";
import ReportBtn from '../../assets/down-btn.png'
const About = () => {
    const [width] = useWindowSize();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [gallerImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [logoList, setLogoList] = useState([]);
    const [usaTitle, setUsaTitle] = useState('');
    const [usaDescription, setUsaDescription] = useState('');
    const [getInTouch, setGetInTouch] = useState('');
    const [usaLogo, setUsaLogo] = useState('');

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined className="lr-arrow-green" />
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined className="lr-arrow-green" />
            </div>
        );
    }

    const settings = {
        className: "slider",
        dots: false,
        infinite: true,
        loop: true,
        speed: 500,
        slidesToShow: width >= 1025
            ? 3 : width >= 1024 && width <= 1024 ? 2
                : width >= 768 && width <= 1023
                    ? 2
                    : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, []);

    const getList = async () => {
        await AboutService.getList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {

                            let result = JSON.parse(res);
                            console.log(result)
                            if (result.code === 0) {
                                if (result.data) {
                                    result.data.list.map((it) => {
                                        if (it.status === 1) {
                                            setTitle(it.title);
                                            setDescription(it.description);
                                            setGalleryImgList(it.galleryList ? JSON.parse(it.galleryList) : [])
                                            setLogoList(it.logoList ? JSON.parse(it.logoList) : []);
                                            setUsaTitle(it.usaTitle);
                                            setUsaDescription(it.usaDescription);
                                            setUsaLogo(it.usaLogo);
                                            setGetInTouch(it.getInTouch);
                                        }
                                    });
                                }
                                setIsLoading(false);

                            } else {
                                setTitle('');
                                setDescription('');
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...gallerImgList];
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }

    return (
        <ExternalLayout>
            <SEOComponent title="About Going to School" subtitle="Design-thinking, 21st Century Skills, Content to equip young people with sustainable skills to take on Climate Change" description="Going to School works with State Governments in four states to be able to equip one million children and young people in each state with the dynamic skills they need to solve for Climate Change where they live."
            />
            <RenderPage
                id="about-page"
                className="render-page render-no-padding"
                containerType="container-fluid"
            >
                <div className='about-p'>
                <Strip
                    id="tst-about"
                    className="strip strip-no-padding"
                    containerType="container-fluid"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                            <img src={application_contants.AboutBanner} alt="" className="full-height-perc" />
                        </Col>
                    </Row>
                </Strip>
                <Strip
                    id="tst-section-title"
                    className="strip strip-no-padding"
                    containerType="container px-0"
                >
                    <Row className="mx-0 pt-5 ">
                        <Col xs={12} sm={12} md={12} lg={10} xl={10} className="px-0 text-start">
                            <h1>About Us</h1>
                            <p className='pt-3'>Going to School is a creative not-for-profit education trust that makes design-driven
                                stories to equip millions of children and young people with the 21st Century Sustainable
                                Entrepreneurial skills they need to be able to solve for Climate Change where they live.</p>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2} className="px-0 m-auto text-md-end">
                            <img src={application_contants.logoImg} alt="about logo" className='about-us-logo-container' />
                        </Col>
                    </Row>
                    <Row className="mx-0 pt-2 ">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 text-start">
                            <p>
                                On national television, online and on the ground in 3,000 Government Schools in India, Going to School's programs are
                                designed to reach young people who are offline and enrolled in the government school system.
                            </p>
                            <p> Design leads what we do. </p>
                            <p>We listen to what young people want.</p>
                            <p>We design large-scale programs to enable children and young people, with a special focus on girls, to complete their education and transition from school to sustainable enterprise of their choice, transforming cities to become giant sustainable places to play and becoming the Climate Change entrepreneurs we need to change everything.
                            </p>
                            <p>
                                From Grades 4-12, age 6-17, outside in football pitches, at school, during school hours, and for start-up sustainable entrepreneurs age 18+, Going to School programs run at scale in four states: Bihar, Maharashtra, Karnataka and Goa.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mx-0 pt-3 pb-3 ">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 text-start">
                            <h1>Play City, The Children’s Scrappy News Service</h1>
                            <p className='pt-2'>
                                Play City, The Children’s Scrappy News Service runs on TV, online and on the ground in 1000+ government schools, designed to equip one million children with the skills they need to make their city a giant sustainable place to play and take on Climate Change.
                            </p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="px-0 pb-3 text-start">
                            <div className='me-md-5 bg-black p-2 pt-0'>
                                <VideoPlay videoLink="https://www.youtube.com/watch?v=UMdYL7s580A" />

                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="px-0  pb-3 text-center">
                            <div className='bg-black p-2 pt-0 ms-md-5 '>
                                <VideoPlay videoLink="https://www.youtube.com/watch?v=zQdAIpQVPUs" />

                            </div>
                        </Col>
                    </Row>
                </Strip>
                <Strip id="tst-about "
                    className="strip strip-no-padding about-out-bg"
                    containerType="container-fluid">
                    <Strip className="strip strip-no-padding"
                        containerType="container px-0 ">
                        <Row className='pt-3 pb-3'>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="px-0 d-flex align-items-center pb-3">
                                <div >
                                    <h1 className='text-light text-start'>The Outdoor School for Girls</h1>
                                    <p className='text-light text-start'>The Outdoor School for Girls is designed to enable girls to learn sustainable enterprise skills they need to become entrepreneurs in the city,  problem-solving for Climate Change</p></div>

                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="px-0  pb-3">
                                <div className='bg-whitee p-2 pt-0 ms-md-5'>
                                    <VideoPlay videoLink="https://www.youtube.com/watch?v=3bq8l8EGZI0" />

                                </div>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
                <Strip className="strip strip-no-padding"
                    containerType="container px-0">
                    <Row className='pt-3 pb-3'>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="px-0 d-flex align-items-center flex-column pb-3 mt-5">
                            <div className='ps-4 pe-4'>
                                <h1 className=' text-start'>Get a Plan</h1>
                                <div className='about_last_section'>
                                    <p className=' text-start'>Get a Plan teaches 21st Century Skills to young people, mainly girls, in Grades 9 to 12 in 1,000 Government Secondary Schools in Bihar to equip them to learn green skills and make the transition from school to equitable and sustainable work, job or business of their choice, solving for Climate Change where they live.</p>
                                    {/* </div>
                            <div> */}
                            <div className='bg-black p-2 pt-0'>
                            <VideoPlay videoLink="https://www.youtube.com/watch?v=idpvAOa4pjU" />
                            </div>
                                    
                                </div>
                            </div>

                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mt-5" >
                            <div>
                            <div className='about_magazine_img mt-2'>
                                <img src={Magazine} alt="banner" />
                            </div>
                           
                            <a href="/Youth Voices, Bihar Report 2022_30 June 2022_Final_CTC_For Website.pdf" target="_blank" className="text-decoration-none text-dark"> <img src={ReportBtn} alt="report-button" className='report-btn'/></a>
                           
                           
                            </div>
                          
                            {/* <div className='download_report_btn  '>
                               Download The Report
                            </div> */}

                        </Col>
                    </Row>
                </Strip>
                </div>
               


            </RenderPage>
        </ExternalLayout >
    )
};


export default About;