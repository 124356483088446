const TeamType = [
    {
        label: "Our Team",
        value: 1
    },
    {
        label: "Board of Directors",
        value: 2
    },
    {
        label: "Trustees",
        value: 3
    }
];

export default TeamType;