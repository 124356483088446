import React, { useState } from "react";
import {
    Row,
    Col,
    Form,
    Button,
    Label,
    FormGroup,
    Input,
} from "reactstrap";
import Axios from "axios";
import AboutService from "../../../../api/services/AboutService";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../../customComponent/ConfirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponent/Loader/Loader";
import Strip from "../../../customComponent/Strip/Strip";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";
import GalleryImage from "./GalleryImage";
import PostitonModal from "../Project/components/LocationDetails/PostitonModal";
import LogoList from './LogoList';
import FileUploader from '../../../customComponent/FileUploader/FileUploader';


const AddAbout = props => {
    const { isModal, toggle, isAdd } = props;
    const [id, setId] = useState('')
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [logoListData, setLogoListData] = useState([]);
    const [logoFileName, setLogoFileName] = useState('');
    const [logoFile, setLogoFile] = useState('');
    const [isReportEdit, setIsReportEdit] = useState(false);
    const [reportEditIndex, setReportEditIndex] = useState(0);
    const [galleryList, setGalleryList] = useState([]);
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);
    const [usaTitle, setUsaTitle] = useState('');
    const [usaLogoFileName, setUsaLogoFileName] = useState('');
    const [usaLogoFile, setUsaLogoFile] = useState('');
    const [isUsaLogoChanged, setIsUsaLogoChanged] = useState(false);
    const [usaDescription, setUsaDescription] = useState('');
    const [getInTouch, setGetInTouch] = useState('');

    React.useEffect(() => {
        if (isAdd) {
            setId("");
            setTitle("");
            setUsaTitle('');
            setUsaDescription('');
            setGetInTouch('');
            setUsaLogoFileName('');
        } else {
            setId(props.item.id);
            setTitle(props.item.title);
            setDescription(props.item.description);
            setLogoListData(props.item.logoList ? JSON.parse(props.item.logoList) : [])
            setGalleryList(props.item.galleryList ? JSON.parse(props.item.galleryList) : [])
            setUsaTitle(props.item.usaTitle);
            setUsaDescription(props.item.usaDescription);
            setGetInTouch(props.item.getInTouch);
            setUsaLogoFileName(props.item.usaLogo ? props.item.usaLogo.split('/')[2] : '');
        }
    }, []);


    const handleTitle = (e) => {
        setTitle(e.target.value)
    };

    const handleDescription = (e) => {
        setDescription(e.target.value)
    };

    const handleUSATitle = (e) => {
        setUsaTitle(e.target.value)
    };

    const handleGetInTouch = (e) => {
        setGetInTouch(e.target.value)
    };

    const handleUSADescription = (e) => {
        setUsaDescription(e.target.value)
    };


    const handleLogoCancel = () => {
        setLogoFileName('');
        setLogoFile('');
    }

    const handleLogoSave = () => {
        let data;
        if (isReportEdit) {
            const item = logoListData[reportEditIndex];
            item.fileName = logoFileName;
            setLogoListData([...logoListData]);
        } else {
            data = [
                {
                    fileName: logoFileName,
                    file: logoFile,

                }
            ];
            setLogoListData([...logoListData, ...data]);
        }
        handleLogoCancel();
        setIsReportEdit(false);
    }

    const handleLogoDelete = (index) => {
        logoListData.splice(index, 1);
        setLogoListData([...logoListData]);
        setIsReportEdit(false);
    }

    const handleLogoEdit = (index) => {
        const item = logoListData[index];
        setLogoFile(item.file);
        setLogoFileName(item.fileName);
        setIsReportEdit(true);
        setReportEditIndex(parseInt(index));
    }

    const handleLogoListFile = async (item) => {
        await setLogoFile(item[0].file);
        await setLogoFileName(item[0].file.name);
    }

    const handleUsaLogoFile = async (item) => {
        await setUsaLogoFile(item[0].file);
        await setUsaLogoFileName(item[0].file.name);
        setIsUsaLogoChanged(true)
    }

    const getThumbnail = async (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setGalleryList([...galleryList, ...item])
                }

            };
            reader.readAsDataURL(file);
        });
    }


    const handleGalleryImageDelete = (index) => {
        galleryList.splice(index, 1);
        setGalleryList([...galleryList]);
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem("userId");
        let finalFile = [];
        let finalArrayList = [];
        let finalListObj = {};
        let finalGalleryFile = [];
        let finalGalleryArrayList = [];
        let finalGalleryListObj = {};
        if (logoListData.length > 0) {
            logoListData.map((it) => {
                if (it.file) {
                    finalFile.push(it.file);
                }
                finalListObj = {
                    fileName: it.file ? `/gallery/${it.fileName}` : `${it.fileName}`,
                }
                finalArrayList.push(finalListObj);
            });
        }

        if (galleryList.length > 0) {
            galleryList.map((it) => {
                if (it.file) {
                    finalGalleryFile.push(it.file);
                }
                finalGalleryListObj = {
                    imgPath: it.file ? `/gallery/${it.file.name}` : it.imgPath,
                }
                finalGalleryArrayList.push(finalGalleryListObj);
            })
        }

        if (isUsaLogoChanged) {
            finalGalleryFile.push(usaLogoFile);
        }

        let data = {
            id: id,
            userId: userId,
            title: title,
            description: description,
            galleryList: finalGalleryArrayList.length > 0 ? JSON.stringify(finalGalleryArrayList) : '[]',
            usaTitle: usaTitle,
            usaDescription: usaDescription,
            usaLogo: isUsaLogoChanged ? `/gallery/${usaLogoFileName}` : `/gallery/${usaLogoFileName}`,
            logoList: finalArrayList.length > 0 ? JSON.stringify(finalArrayList) : '[]',
            getInTouch: getInTouch
        }

       // console.log("data", data, finalGalleryArrayList, galleryList)
        setIsLoading(true);
        if (finalFile.length > 0 && finalGalleryFile > 0) {
            UploadGallery(finalGalleryFile, data, false, finalFile)
        } else if (finalFile.length > 0) {
            UploadLogo(finalFile, data, true);
        } else if (finalGalleryFile.length > 0) {
            UploadGallery(finalGalleryFile, data, true, "");
        } else {
            insertOrUpdate(data);
        }
        setIsLoading(false);
    }

    const UploadGallery = async (galleryFiles, insertOrUpdateData, isInsert, finalFile) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/gallery/");
        await galleryFiles.map((item) => {
            data.append("image", item);
            console.log(item)
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        if (data) {
            Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
                .then(async res => {
                    if (res.status === 200) {
                        try {
                            if (res.data.code === 0) {
                                if (isInsert) {
                                    await insertOrUpdate(insertOrUpdateData);
                                } else {
                                    await UploadLogo(finalFile, insertOrUpdateData, true);
                                }
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${res.data.message}`);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch(err => {
                    console.log("We will be back soon.");
                    return err;
                });
        }
    }

    const UploadLogo = async (files, insertOrUpdateData, isInsert) => {
        //console.log("file before", files, insertOrUpdateData);
        const data = await new FormData();
        await data.append("floderPath", "uploads/gallery/");
        await files.map((item) => {
            data.append("image", item);
            console.log(item)
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        if (data) {
            Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
                .then(async res => {
                    if (res.status === 200) {
                        try {
                            if (res.data.code === 0) {
                                if (isInsert) {
                                    await insertOrUpdate(insertOrUpdateData);
                                }
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${res.data.message}`);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch(err => {
                    console.log("We will be back soon.");
                    return err;
                });
        }
    };

    const insertOrUpdate = async (data) => {
        await AboutService.insertOrUpdate(data)
            .then(async res => {
                if (res.status === 200) {
                    try {
                        res.text().then(async res => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handlePositionSave = (toIndex) => {
        const list = [...logoListData];
        //list.splice(0, 0, list.splice(index, 1)[0]);
        var element = list[positionIndex];
        list.splice(positionIndex, 1);
        list.splice(toIndex, 0, element);
        setLogoListData([...list]);
        setIsPositionModal(!isPositionModal);
    }

    const handleLogoPosition = (index) => {
        setPositionIndex(index)
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionToggle = () => {
        setIsPositionModal(!isPositionModal)
    }


    return (
        <Strip
            id="tst-add-project"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row className="mx-0 box-shadow">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                    <div className="bg-crimson-red">
                        <Row className="no-margin align-items-center py-2">
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <p className="external-section--title fw-blod py-1">
                                    {isAdd ? "ADD" : "UPDATE "}
                                </p>
                            </Col>
                            <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                className="cursor-pointer"
                                onClick={toggle}
                            >
                                <p className="external-section--title fw-blod align-right cursor-pointer">
                                    Back
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Row className="mx-0">
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="m-auto px-0"
                        >
                            <Row className="m-auto">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="pt-3 pb-5"
                                >
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Title* :
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Title"
                                                            id="Title"
                                                            name="Title"
                                                            className="form-control common-form-control"
                                                            value={title}
                                                            onChange={event => handleTitle(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Description* :
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            placeholder="Description"
                                                            id="description"
                                                            name="description"
                                                            className="form-control common-form-control"
                                                            value={description}
                                                            onChange={event => handleDescription(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <GalleryImage getThumbnail={(item) => getThumbnail(item)} galleryList={galleryList} handleGalleryImageDelete={(index) => handleGalleryImageDelete(index)} />
                                                <FormGroup
                                                    row
                                                    className="mx-0 align-items-center"
                                                >
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            USA Logo File :
                                                        </Label>
                                                        <FileUploader
                                                            isImg={true}
                                                            isSingleGrid={true}
                                                            getThumbnail={(item) =>
                                                                handleUsaLogoFile(item)
                                                            }
                                                            value={usaLogoFileName}
                                                            isEditable={false}
                                                            labelName="Upload USA Logo File"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            USA Title* :
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="USA Title"
                                                            id="USATitle"
                                                            name="USATitle"
                                                            className="form-control common-form-control"
                                                            value={usaTitle}
                                                            onChange={event => handleUSATitle(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            USA Description* :
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            placeholder="USA Description"
                                                            id="USADescription"
                                                            name="USADescription"
                                                            className="form-control common-form-control"
                                                            value={usaDescription}
                                                            onChange={event => handleUSADescription(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Get In Touch (emailId)* :
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Get In Touch"
                                                            id="Get In Touch"
                                                            name="Get In Touch"
                                                            className="form-control common-form-control"
                                                            value={getInTouch}
                                                            onChange={event => handleGetInTouch(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center mt-3">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <h6 className="h6-small external-section--title title-black-pearl bg-zircon-grey p-2">
                                                            Logo Details :
                                                        </h6>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={8}
                                                        xl={8}
                                                        className="m-auto mt-4"
                                                    >
                                                        <LogoList
                                                            handleLogoCancel={handleLogoCancel}
                                                            handleLogoSave={handleLogoSave}
                                                            logoLists={logoListData}
                                                            handleLogoDelete={handleLogoDelete}
                                                            handleLogoEdit={handleLogoEdit}
                                                            getFile={handleLogoListFile}
                                                            logoFileName={logoFileName}
                                                            handleLogoPosition={handleLogoPosition}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="no-margin py-2 mt-4 mx-0">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                            className={`${isLoading ? 'm-auto' : 'ml-auto'}`}
                                        >
                                            {!isLoading ?
                                                <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Button
                                                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                            onClick={toggle}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Button
                                                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                            onClick={handleSubmit}
                                                            disabled={title && description ? false : true}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </Row> : null}
                                            {isLoading ? (
                                                <div className="d-flex align-item-center justify-content-center">
                                                    <Loader containerClassName="" />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isPositionModal ?
                <PostitonModal isModal={isPositionModal} handlePositionSave={handlePositionSave} handleToggle={handlePositionToggle} len={logoListData} /> : null}
        </Strip>
    );
};

export default AddAbout;