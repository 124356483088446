import React from "react";
import { Row, Col } from "reactstrap";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import application_contants from "../../constants/application_contant";

const PlayStoreAndSupportedDetails = (props) => {
    return (
        <Row className="mx-0 pt-5 pb-5" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.sectionTitle ?
                            <div>
                                <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</h2>
                                {/* <p className="external-section--title robotoSlab-Bold title-crimson-red pb-1">{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</p> */}

                            </div>
                            : null}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                        <Row className="mx-0 pt-5">
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Row className="mx-0 align-items-center">
                                    {props.data.galleryList.length > 0 ?
                                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                            <img src={`${ApiConfig.URL}${props.data.galleryList[0].imgPath}`} alt="" />
                                        </Col> : null}
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        {props.data.storeUrl ?
                                            <a href={`${props.data.storeUrl}`} rel="noreferrer" target="_blank">
                                                <img src={application_contants.PlayStoreIcon} alt="" />
                                            </a>
                                            : null}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <p className="p-small external-section--title robotoSlab-Regular title-black-pearl roboto-Bold pb-1 lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description ? `${props.data.description}` : ''}</p>
                                {props.data.supportedBy ?
                                    <Row className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3 px-0 pb-3">
                                            <p className="external-section--title robotoSlab-Regular title-black-pearl">{` Supported by ${props.data.supportedBy}`}</p>
                                        </Col>
                                    </Row> : null}
                                {props.data.galleryList.length > 1 ?
                                    <Row className="mx-0">
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="px-0">
                                            <div className="bg-white p-3 br-4px">
                                              <img src={`${ApiConfig.URL}${props.data.galleryList[1].imgPath}`} alt="" />
                                            </div>
                                        </Col>
                                    </Row> : null}
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default PlayStoreAndSupportedDetails;
