const ColorCode = [
    {
        value: 1,
        label: 'DC0D45'
    },
    {
        value: 2,
        label: '050708'
    },
    {
        value: 3,
        label: '1B88D2'
    },
    {
        value: 4,
        label: 'BC0DDC'
    },
    {
        value: 5,
        label: 'F77DD1'
    },
    {
        value: 6,
        label: '0B96AD'
    },
    {
        value: 7,
        label: 'EC9207'
    },
    {
        value: 8,
        label: 'F77DD1'
    },
    {
        value: 9,
        label: '09C987'
    },
    {
        value: 10,
        label: '05585E'
    },
    {
        value: 11,
        label: 'f9ec21',
    },
    {
        value: 12,
        label: 'afe959'
    },
    {
        value: 13,
        label: 'f7f7f7'
    },
    {
        value: 14,
        label: 'F46A6A'
    },
    {
        value: 15,
        label: 'e7516f'
    },
    {
        value: 16,
        label: 'fed828'
    },
    {
        value: 17,
        label: '7974fe'
    },
    {
        value: 18,
        label: '467efd'
    },
    {
        value: 19,
        label:'32623d'
    }, 
    {
        value: 20,
        label:'e660aa'
    },
    {
        value: 21,
        label:'7BFFA8'
    },
    {
        value: 22,
        label:'7BDBFF'
    },
    {
        value: 23,
        label:'FFCB1F'
    },
    {
        value: 24,
        label:'52E8A3'
    },
    {
        value: 25,
        label:'5263E8'
    },
    {
        value: 26,
        label:'ffffff'
    },
    {
        value: 27,
        label:'04AF95'
    },
    {
        value: 28,
        label:'24343C'
    },
    {
        value: 29,
        label:'05688D'
    },
    {
        value: 30,
        label:'52D179'
    },
    {
        value: 31,
        label:'FA713F'
    },
    {
        value: 32,
        label:'5C1EDC'
    }
]

export default ColorCode;