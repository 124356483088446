import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
import DoorSlider from '../component/DoorSlider'
import Img1 from '../../../assets/GoalNew/section2/sec2-img.png';
import Tape from '../../../assets/GoalNew/section2/tape.png';
import Slide1 from '../../../assets/GoalNew/section2/slider/1.jpg'
import Slide2 from '../../../assets/GoalNew/section2/slider/2.jpg'
import Slide3 from '../../../assets/GoalNew/section2/slider/3.jpg'
import Slide4 from '../../../assets/GoalNew/section2/slider/4.jpg'
import Slide5 from '../../../assets/GoalNew/section2/slider/5.jpg'
import Slide6 from '../../../assets/GoalNew/section2/slider/6.jpg'
import Slide7 from '../../../assets/GoalNew/section2/slider/7.jpg'
import Slide8 from '../../../assets/GoalNew/section2/slider/8.jpg'
import Slide9 from '../../../assets/GoalNew/section2/slider/9.jpg'
import Slide10 from '../../../assets/GoalNew/section2/slider/10.jpg'
import Slide11 from '../../../assets/GoalNew/section2/slider/11.jpg'
import Slide12 from '../../../assets/GoalNew/section2/slider/12.jpg'
import Slide13 from '../../../assets/GoalNew/section2/slider/13.jpg'
import Slide14 from '../../../assets/GoalNew/section2/slider/14.jpg'
import Slide15 from '../../../assets/GoalNew/section2/slider/15.jpg'
const Section2 = () => {
  const  sliderList=[
    Slide1, Slide2, Slide3, Slide4, Slide5,Slide6,Slide7,Slide8,Slide9,Slide10,Slide11,Slide12,Slide13,Slide14,Slide15
] 
  return (
    <>
      <Strip
        className="strip strip-no-padding goaltwo"
        containerType="container-fluid  px-0 ">
        <Strip
          className="strip strip-no-padding "
          containerType="container ">
          <Row className='pt-md-2'>
            <Col xs={12} className="goal2-img mb-lg-5">
              <div className='img-sec'>
                <img src={Img1} />
              </div>
            </Col>
            <Col xs={12} md={6} className='mt-5 pt-md-5 mb-lg-5'>
              <div className=' play-p pt-lg-5 pb-md-5'>
                <p>
                  <span>Goal Goa</span>, the feature film, chased footballers and the monsoon across Goa during the pandemic. In search of how football came to be in the blood of every Goan, the film met the first winning women's football team and discovered, inadvertently, what would come next.
                </p>
              </div>

            </Col>
            <Col xs={12} md={6} className='mt-5 mb-md-5 pt-3'>
               <div className='goal-sli2 '>

               
                  <div className='goal-i'> 
                  <img src={Tape}/>
                   </div>
                    <DoorSlider images={sliderList} slide={1} />
                 </div>
                
            </Col>
          </Row>
        </Strip>
      </Strip>
    </>
  )
}

export default Section2