import React from "react";
import {
  Row,
  Col,
} from "reactstrap";
import Strip from "../../../customComponent/Strip/Strip";
import TemplateOne from "./TemplateOne";

const AddProject = props => {
  const { toggle, isAdd } = props;


  return (
    <Strip
      id="tst-add-project"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row className="mx-0 box-shadow">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
          <div className="bg-crimson-red">
            <Row className="no-margin align-items-center py-2">
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <p className="external-section--title fw-blod py-1">
                  {isAdd ? "ADD" : "UPDATE "}
                </p>
              </Col>
              <Col
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                className="cursor-pointer"
                onClick={toggle}
              >
                <p className="external-section--title fw-blod align-right cursor-pointer">
                  Back
                </p>
              </Col>
            </Row>
          </div>
          <Row className="mx-0">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="m-auto px-0"
            >
              <Row className="m-auto">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pt-3 pb-5"
                >
                  <Row className="mt-2">
                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormGroup row className="mx-0 align-items-center">
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Label className="common-label text-left">
                            Select Layout* :
                          </Label>
                            <Select
                              value={layoutType}
                              onChange={handleProjectLayout}
                              options={ProjectLayoutData}
                              placeholder={"Select Location"}
                              classNamePrefix="common-select-drop-down common-select-drop-down-large"
                              isSearchable={true}
                              isClearable
                              maxMenuHeight={200}
                          />
                        </Col>
                      </FormGroup>
                    </Col> */}
                  
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TemplateOne {...props}/>
                    </Col> 
                  </Row>

                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Strip>
  );
};

export default AddProject;
