import React, { useState } from "react";
import PDFViewer from 'mgr-pdf-viewer-react'
import { useWindowSize } from "@react-hook/window-size";
import Slider from "react-slick";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
// import PdfDetails from "./PdfDetails";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";


function SampleNextArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowForwardIosOutlined style={{ color: `#${arrowColor}`, fontSize: '2rem' }} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowBackIosOutlined style={{ color: `#${arrowColor}`, fontSize: '2rem' }}/>
        </div>
    );
}


const PDFGridViewer = (props) => {
    const [width] = useWindowSize();
    const [pdfData, setPDFData] = useState([]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: width >= application_contants.MOBILE_BREAKPOINT ? 4 : (width >= 768 && width <= 1023) ? 2 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow arrowColor={props?.data.arrowColor}/>,
        prevArrow: <SamplePrevArrow arrowColor={props?.data.arrowColor}/>
    };

    React.useEffect(() => {
        if (props.data) {
            setPDFData(props.data.galleryList);
        }
    }, [props.data])


    const handlePreview = (url) => {
        window.open(
            `${url}`,
            "_blank"
        );
    }

    return (
        <Row className="mx-0 pt-5 pb-5" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.sectionTitle ?
                            <div>
                                <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</h2>
                                <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description ? `${props.data.description}` : ''}</p>

                            </div>
                            :
                            <div>
                                {props.isMapofme ?
                                    <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>
                                            <a href={`https://${props.websiteUrl ? props.websiteUrl : ''}`} className="text-decoration--none" rel="noreferrer" target="_blank" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `MOM ${props.data.locationName}` : ''}</a>
                                        </h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                    : <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `${props.data.locationName}` : ''}</h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>

                                    </div>}
                            </div>}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pdf-viewer-container">
                        <Slider {...settings}>
                            {pdfData.length > 0 && pdfData.map((item, index) => (
                                <Row key={index} className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                        {/* <PdfDetails item={item}  index={index} /> */}
                                        <div onClick={() => handlePreview(`${ApiConfig.URL}${item.imgPath}`)}>
                                            <PDFViewer
                                            document={{
                                            //url: `https://arxiv.org/pdf/quant-ph/0410100.pdf`
                                            url: `${ApiConfig.URL}${item.imgPath}`
                                            }}
                                            page={1}
                                            hideNavbar={true}
                                            showThumbnail={{ scale: 3 }}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default PDFGridViewer;
