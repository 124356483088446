import logoImg from "../assets/logo.png";
import teamImg from "../assets/team1.png";
import menuImg from "../assets/menu.png";
import map from "../assets/map.png";
// import contactMapImg from '../assets/contact-map.png';
//import contactMap from "../assets/contacts_map.png";
import contactMap from "../assets/contactMap.png";
import platinumLogo from "../assets/platinum-logo.png";
import instagramImg from "../assets/instagram.png";
import facebookImg from "../assets/facebook.png";
import linkedinImg from "../assets/linkedin.png";
import youtubeImg from "../assets/youtube.png";
import planIcon from "../assets/getaplan-icon.png";
import mapofmeIcon from "../assets/mapofme-icon.png";
import playCityIcon from "../assets/playcity-icon.png";
// import mobileMap from '../assets/contact-map.png';
import childImg from "../assets/child.png";
import compliantImg from "../assets/compliant.png";
import partyBlowerImg from "../assets/party-blower.png";
import waveBannerImg from "../assets/wave-banner.png";
import schoolImg from "../assets/schoolImg.png";
//import lunaImg from '../assets/luna.png';
//import lunaMapImg from '../assets/luna-map.png';
import PlayStoreIcon from "../assets/playstore-icon.png";
import CloseIcon from "../assets/Close.png";
import GoalGoaCircleImg from "../assets/goal-goa-circle.png";
import GoalGoaImg from "../assets/Goal-Goa.png";
import ChildernsWithHoney from "../assets/childrens-with-honeybee.jpg";
import MapBihar from "../assets/IndiaMapwithGetAPlanLogo.png";
import GetPlan from "../assets/get-a-plan.jpg";
import LandOfLove from "../assets/landoflove-honey.jpg";
import YouTubeLogo from "../assets/Youtub -logo.png";
import Bee from "../assets/Bee.png";
import LandofloveHoney from "../assets/landoflove-honey.jpg";
import LandofloveHoney2 from "../assets/landoflove-Honey2.jpg";
import LandofloveHoney3 from "../assets/landoflove-honey3.jpg";
import SportBall from "../assets/sport-ball.png";
import GirlsPlaying from "../assets/GirlsPlaying.jpg";
import GirlsPlaying2 from "../assets/GirlsPlaying2.jpg";
import GirlsPlaying3 from "../assets/GirlsPalying3.jpg";
import GirlsPlaying4 from "../assets/GirlsPlaying4.jpg";
import bannerBackGrand from "../assets/bannerBackGrand.png";
import BGYellow from "../assets/BG-Yellow.jpg";
import BGGreen from "../assets/BG-Green.jpg";
import YesLogo from "../assets/YesLogo.png";
import YoungEntrepreneurs from "../assets/YoungEntrepreneurs.jpg";
import YoungEntrepreneurs1 from "../assets/YoungEntrepreneurs1.jpg";
import YoungEntrepreneurs2 from "../assets/YoungEntrepreneurs2.JPG";
import YoungEntrepreneurs3 from "../assets/YoungEntrepreneurs3.JPG";

import kidsWithPhone from "../assets/kidsWithPhone.jpg";
import GooglePlay from "../assets/gp.png";
import Entrepreneurs from "../assets/Entrepreneurs.jpg";
import IKEA from "../assets/IKEA.png";
import Mobile_all from "../assets/Mobile_all.png";
import Self_Defence1 from "../assets/Self_Defence1.png";
import Self_Defence2 from "../assets/Self_Defence2.jpg";
import Self_Defence3 from "../assets/Self_Defence3.jpg";
import Self_Defence4 from "../assets/Self_Defence4.jpg";
import GreenEnterpriseInternships1 from "../assets/GreenEnterpriseInternships1.png";
import GreenEnterpriseInternships2 from "../assets/GreenEnterpriseInternships2.png";
import GreenEnterpriseInternships3 from "../assets/GreenEnterpriseInternships3.png";
import BannerForMobile from "../assets/banner_Mobile.jpg";
import BannerForDesktop from "../assets/banner_Desktop.jpg";
import sportBall1 from "../assets/sportBall1.jpg";
import sportBall2 from "../assets/sportBall2.jpg";
import kidswithphone2 from "../assets/kids_with_phone_2.jpg";
import kidswithphone3 from "../assets/kids_with_phone_3.jpg";

// import GoalGoaImg1 from '../assets/GoalGao/GoalGoaImg1.jpg'
// import GoalGoaImg2 from '../assets/GoalGao/GoalGoaImg2.JPG'
// import GoalGoaImg4 from '../assets/GoalGao/GoalGoaImg4.JPG'
// import GoalGoaImg5 from '../assets/GoalGao/GoalGoaImg5.JPG'
// import GoalGoaImg6 from '../assets/GoalGao/GoalGoaImg6.jpg'
// import GoalGoaImg7 from '../assets/GoalGao/GoalGoaImg7.jpg'
// import GoalGoaImg8 from '../assets/GoalGao/GoalGoaImg8.jpg'
// import GoalGoaImg9 from '../assets/GoalGao/GoalGoaImg9.JPG'
// import GoalGoaImg10 from '../assets/GoalGao/GoalGoaImg10.jpg'
// import GoalGoaImg11 from '../assets/GoalGao/GoalGoaImg11.JPG'
// import GoalGoaImg12 from '../assets/GoalGao/GoalGoaImg12.JPG'
// import GoalGoaImg13 from '../assets/GoalGao/GoalGoaImg13.jpg'
// import GoalGoaImg14 from '../assets/GoalGao/GoalGoaImg14.JPG'
// import GoalGoaImg15 from '../assets/GoalGao/GoalGoaImg15.JPG'
// import GoalGoaImg17 from '../assets/GoalGao/GoalGoaImg17.JPG'
// import GoalGoaImg18 from '../assets/GoalGao/GoalGoaImg18.png'
// import GoalGoaImg19 from '../assets/GoalGao/GoalGoaImg19.png'
// import GoalGoaImg20 from '../assets/GoalGao/GoalGoaImg20.jpg'
// import GoalGoaImg21 from '../assets/GoalGao/GoalGoaImg21.JPG'
// import GoalGoaImg22 from '../assets/GoalGao/GoalGoaImg22.JPG'
// import GoalGoaImg23 from '../assets/GoalGao/GoalGoaImg23.JPG'
// import GoalGoaImg24 from '../assets/GoalGao/GoalGoaImg24.jpg'
// import GoalGoaImg25 from '../assets/GoalGao/GoalGoaImg25.jpg'
// import GoalGoaImg30 from '../assets/GoalGao/GoalGoaImg30.jpg'
// import GoalGoaImg31 from '../assets/GoalGao/GoalGoaImg31.jpg'
// import GoalGoaImg32 from '../assets/GoalGao/GoalGoaImg32.jpg'
// import GoalGoaImg33 from '../assets/GoalGao/GoalGoaImg33.jpg'
// import GoalGoaImg34 from '../assets/GoalGao/GoalGoaImg34.jpg'
// import GoalGoaImg35 from '../assets/GoalGao/GoalGoaImg35.jpg'
// import GoalGoaImg36 from '../assets/GoalGao/GoalGoaImg36.jpg'
// import GoalGoaImg37 from '../assets/GoalGao/GoalGoaImg37.jpg'
// import GoalGoaImg38 from '../assets/GoalGao/GoalGoaImg38.jpg'
// import GoalGoaImg39 from '../assets/GoalGao/GoalGoaImg39.jpg'
// import GoalGoaImg40 from '../assets/GoalGao/GoalGoaImg40.jpg'
// import GoalGoaImg41 from '../assets/GoalGao/GoalGoaImg41.jpg'
// import GoalGoaImg42 from '../assets/GoalGao/GoalGoaImg42.jpg'
// import GoalGoaImg43 from '../assets/GoalGao/GoalGoaImg43.jpg'
// import GoalGoaImg44 from '../assets/GoalGao/GoalGoaImg44.jpg'
// import GoalGoaImg45 from '../assets/GoalGao/GoalGoaImg45.jpg'
// import GoalGoaImg46 from '../assets/GoalGao/GoalGoaImg46.jpg'
// import GoalGoaImg47 from '../assets/GoalGao/GoalGoaImg47.jpg'
// import GoalGoaImg48 from '../assets/GoalGao/GoalGoaImg48.jpg'
// import GoalGoaImg49 from '../assets/GoalGao/GoalGoaImg49.jpg'
// import GoalGoaImg50 from '../assets/GoalGao/GoalGoaImg50.jpg'
// import GoalGoaImg51 from '../assets/GoalGao/GoalGoaImg51.jpg'
// import GoalGoaImg52 from '../assets/GoalGao/GoalGoaImg52.jpg'
// import GoalGoaImg53 from '../assets/GoalGao/GoalGoaImg53.jpg'
// import GoalGoaImg54 from '../assets/GoalGao/GoalGoaImg54.jpg'
// import GoalGoaImg55 from '../assets/GoalGao/GoalGoaImg55.jpg'
// import GoalGoaImg56 from '../assets/GoalGao/GoalGoaImg56.jpg'
// import GoalGoaImg57 from '../assets/GoalGao/GoalGoaImg57.jpg'
// import GoalGoaImg58 from '../assets/GoalGao/GoalGoaImg58.jpg'
// import GoalGoaImg59 from '../assets/GoalGao/GoalGoaImg59.jpg'
// import GoalGoaMap1 from '../assets/GoalGao/goalgoaMap1.jpg'
// import GoalGoaBanner from '../assets/GoalGao/banner00.jpg'
// import GoalGoaMobileBanner from '../assets/GoalGao/bannerMobile.jpg'
// import GoalGoaImg26 from '../assets/GoalGao/GoalGoaImg26.jpg'
// import GoalGoaSectionTwoImg1 from '../assets/GoalGao/GoalGoaSectionTwoImg1.jpg'
// import GoalGoaSectionTwoImg2 from '../assets/GoalGao/GoalGoaSectionTwoImg2.jpg'
// import GoalGoaSectionTwoImg3 from '../assets/GoalGao/GoalGoaSectionTwoImg3.jpg'
// import GoalGoaSectionTwoImg4 from '../assets/GoalGao/GoalGoaSectionTwoImg4.jpg'
// import GoalGoaSectionTwoImg5 from '../assets/GoalGao/GoalGoaSectionTwoImg5.jpg'
// import GoalGoaSectionTwoImg6 from '../assets/GoalGao/GoalGoaSectionTwoImg6.jpg'
// import GoalGoaThirdSectionInmg1 from '../assets/GoalGao/GoalGoaThirdSectionInmg1.JPG'
// import GoalGoaThirdSectionInmg2 from '../assets/GoalGao/GoalGoaThirdSectionInmg2.jpg'
// import GoalGoaThirdSectionInmg3 from '../assets/GoalGao/GoalGoaThirdSectionInmg3.jpg'
// import GoalGoaThirdSectionInmg4 from '../assets/GoalGao/GoalGoaThirdSectionInmg4.jpg'
// import GoalGoaThirdSectionInmg5 from '../assets/GoalGao/GoalGoaThirdSectionInmg5.jpg'
// import GoalGoaFourthSectionImg1 from '../assets/GoalGao/GoalGoaFourthSectionImg1.jpg'
// import GoalGoaFourthSectionImg2 from '../assets/GoalGao/GoalGoaFourthSectionImg2.jpg'
// import GoalGoaFourthSectionImg4 from '../assets/GoalGao/GoalGoaFourthSectionImg4.jpg'
// import GoalGoaFourthSectionImg5 from '../assets/GoalGao/GoalGoaFourthSectionImg5.jpg'
// import GoalGoaFourthSectionImg6 from '../assets/GoalGao/GoalGoaFourthSectionImg6.jpg'
// import GoalGoaFourthSectionImg7 from '../assets/GoalGao/GoalGoaFourthSectionImg7.JPG'
// import goalgoa27 from '../assets/GoalGao/goalgoa27.jpeg'
// import GoalGoafifthSectionImg1 from '../assets/GoalGao/GoalGoafifthSectionImg1.jpg'
// import GoalGoafifthSectionImg2 from '../assets/GoalGao/GoalGoafifthSectionImg2.jpg'
// import GoalGoafifthSectionImg3 from '../assets/GoalGao/GoalGoafifthSectionImg3.JPG'
// import GoalGoafifthSectionImg4 from '../assets/GoalGao/GoalGoafifthSectionImg4.jpg'
// import GoalGoafifthSectionImg5 from '../assets/GoalGao/GoalGoafifthSectionImg5.jpg'
// import GoalGoafifthSectionImg6 from '../assets/GoalGao/GoalGoafifthSectionImg6.jpg'
// import GoalGoafifthSectionImg7 from '../assets/GoalGao/GoalGoafifthSectionImg7.jpg'
// import GoalGoafifthSectionImg8 from '../assets/GoalGao/GoalGoafifthSectionImg8.jpg'
// import GoalGoafifthSectionImg9 from '../assets/GoalGao/GoalGoafifthSectionImg9.jpg'
// import GoalGoafifthSectionImg10 from '../assets/GoalGao/GoalGoafifthSectionImg10.jpg'
// import GoalGoaBT1 from '../assets/GoalGao/BT_Sport_logo.png';
// import GoalGoaBT2 from '../assets/GoalGao/GoalGoaBT2.jpg';
import AboutBanner from "../assets/about_banner.png";

const application_contants = {
  LANDING: "/",
  PEOPLES: "/people-list",
  SUPPORTER: "/supporter-list",
  LOGIN: "/login",
  PROJECT: "/project-list",
  BANNER: "/banner-list",
  ABOUT: "/about-list",
  LOCATION: "/location-list",
  FINANACIAL: "/financial-list",
  GOALGOA: "Goal Goa",
  YOUNG: "young-entrepreneurs-skills-school-marathi",
  IKEAFOUNDATIONIMG: "IKEAs.png",
  GETAPLAN: "get-a-plan",
  playStoreId: "https://play.google.com/store/apps/details?id=com.yessapp",
  YoungEntrepreneursPlayStoreId: "https://webtw.in/bc3c3",
  PLAYLISTID_Graphic_Novel_videos: "PL1ue9VzrTsihAfZy_LWOwgYvIRbPIEgLF",
  PLAYLISTID_Green_Enterprise_Adventures: "PL1ue9VzrTsiiPjo8Vj1k3-wOKzHEsIsAk",
  YOUTUBE_API_KEY: "AIzaSyBYi5FhlATB3v6cx4uMmqBLxzeNiYUs9zw",

  MOBILE_BREAKPOINT: 1023,
  logoImg: logoImg,
  teamImg: teamImg,
  menuImg: menuImg,
  mapImg: map,
  // contactMapImg: contactMapImg,
  contactMapImg: contactMap,
  platinumLogoImg: platinumLogo,
  instagramImg: instagramImg,
  facebookImg: facebookImg,
  linkedinImg: linkedinImg,
  youtubeImg: youtubeImg,
  playCityIcon: playCityIcon,
  mapofmeIcon: mapofmeIcon,
  planIcon: planIcon,
  // mobileMap: mobileMap,
  childImg: childImg,
  compliantImg: compliantImg,
  partyBlowerImg: partyBlowerImg,
  waveBannerImg: waveBannerImg,
  schoolImg: schoolImg,
  PlayStoreIcon: PlayStoreIcon,
  CloseIcon: CloseIcon,
  GoalGoaCircleImg: GoalGoaCircleImg,
  GoalGoaImg: GoalGoaImg,
  ChildernsWithHoney: ChildernsWithHoney,
  MapBihar: MapBihar,
  GetPlan: GetPlan,
  LandOfLove: LandOfLove,
  YouTubeLogo: YouTubeLogo,
  Bee: Bee,
  LandofloveHoney: LandofloveHoney,
  LandofloveHoney2: LandofloveHoney2,
  LandofloveHoney3: LandofloveHoney3,
  SportBall: SportBall,
  GirlsPlaying: GirlsPlaying,
  GirlsPlaying2: GirlsPlaying2,
  GirlsPlaying3: GirlsPlaying3,
  GirlsPlaying4: GirlsPlaying4,
  bannerBackGrand: bannerBackGrand,
  BGYellow: BGYellow,
  BGGreen: BGGreen,
  YesLogo: YesLogo,
  YoungEntrepreneurs: YoungEntrepreneurs,
  YoungEntrepreneurs1: YoungEntrepreneurs1,
  YoungEntrepreneurs2: YoungEntrepreneurs2,
  YoungEntrepreneurs3: YoungEntrepreneurs3,
  kidsWithPhone: kidsWithPhone,
  GooglePlay: GooglePlay,
  Entrepreneurs: Entrepreneurs,
  IKEA: IKEA,
  Mobile_all: Mobile_all,
  Self_Defence1: Self_Defence1,
  Self_Defence2: Self_Defence2,
  Self_Defence3: Self_Defence3,
  Self_Defence4: Self_Defence4,
  GreenEnterpriseInternships1: GreenEnterpriseInternships1,
  GreenEnterpriseInternships2: GreenEnterpriseInternships2,
  GreenEnterpriseInternships3: GreenEnterpriseInternships3,
  BannerForMobile: BannerForMobile,
  BannerForDesktop: BannerForDesktop,
  sportBall1: sportBall1,
  sportBall2: sportBall2,
  // GoalGoaImg1 : GoalGoaImg1,
  // GoalGoaImg2 : GoalGoaImg2,
  // GoalGoaImg4 : GoalGoaImg4,
  // GoalGoaImg5 : GoalGoaImg5,
  // GoalGoaImg6 : GoalGoaImg6,
  // GoalGoaImg7 : GoalGoaImg7,
  // GoalGoaImg8 : GoalGoaImg8,
  // GoalGoaImg9 : GoalGoaImg9,
  // GoalGoaImg10 : GoalGoaImg10,
  // GoalGoaImg11 : GoalGoaImg11,
  // GoalGoaImg12 : GoalGoaImg12,
  // GoalGoaImg13 : GoalGoaImg13,
  // GoalGoaImg14 : GoalGoaImg14,
  // GoalGoaImg15 : GoalGoaImg15,
  // GoalGoaImg17 : GoalGoaImg17,
  // GoalGoaImg18 : GoalGoaImg18,
  // GoalGoaImg19 : GoalGoaImg19,
  // GoalGoaImg20 : GoalGoaImg20,
  // GoalGoaImg21 : GoalGoaImg21,
  // GoalGoaImg22 : GoalGoaImg22,
  // GoalGoaImg23 : GoalGoaImg23,
  // GoalGoaImg24 : GoalGoaImg24,
  // GoalGoaImg25 : GoalGoaImg25,
  // GoalGoaImg30 : GoalGoaImg30,
  // GoalGoaImg31 : GoalGoaImg31,
  // GoalGoaImg32 : GoalGoaImg32,
  // GoalGoaImg33 : GoalGoaImg33,
  // GoalGoaImg34 : GoalGoaImg34,
  // GoalGoaImg35 : GoalGoaImg35,
  // GoalGoaImg36 : GoalGoaImg36,
  // GoalGoaImg37 : GoalGoaImg37,
  // GoalGoaImg38 : GoalGoaImg38,
  // GoalGoaImg39 : GoalGoaImg39,
  // GoalGoaImg40 : GoalGoaImg40,
  // GoalGoaImg41 : GoalGoaImg41,
  // GoalGoaImg42 : GoalGoaImg42,
  // GoalGoaImg43 : GoalGoaImg43,
  // GoalGoaImg44 : GoalGoaImg44,
  // GoalGoaImg45 : GoalGoaImg45,
  // GoalGoaImg46 : GoalGoaImg46,
  // GoalGoaImg47 : GoalGoaImg47,
  // GoalGoaImg48 : GoalGoaImg48,
  // GoalGoaImg49 : GoalGoaImg49,
  // GoalGoaImg50 : GoalGoaImg50,
  // GoalGoaImg51 : GoalGoaImg51,
  // GoalGoaImg52 : GoalGoaImg52,
  // GoalGoaImg53 : GoalGoaImg53,
  // GoalGoaImg54 : GoalGoaImg54,
  // GoalGoaImg55 : GoalGoaImg55,
  // GoalGoaImg56 : GoalGoaImg56,
  // GoalGoaImg57 : GoalGoaImg57,
  // GoalGoaImg58 : GoalGoaImg58,
  // GoalGoaImg59 : GoalGoaImg59,
  // goalgoa27 : goalgoa27,
  // GoalGoaMap1 : GoalGoaMap1,
  // GoalGoaBanner : GoalGoaBanner,
  // GoalGoaMobileBanner : GoalGoaMobileBanner,
  // GoalGoaImg26 : GoalGoaImg26,
  // GoalGoaSectionTwoImg1 : GoalGoaSectionTwoImg1,
  // GoalGoaSectionTwoImg2 : GoalGoaSectionTwoImg2,
  // GoalGoaSectionTwoImg3 : GoalGoaSectionTwoImg3,
  // GoalGoaSectionTwoImg4 : GoalGoaSectionTwoImg4,
  // GoalGoaSectionTwoImg5 : GoalGoaSectionTwoImg5,
  // GoalGoaSectionTwoImg6 : GoalGoaSectionTwoImg6,
  // GoalGoaThirdSectionInmg1 : GoalGoaThirdSectionInmg1,
  // GoalGoaThirdSectionInmg2 : GoalGoaThirdSectionInmg2,
  // GoalGoaThirdSectionInmg3 : GoalGoaThirdSectionInmg3,
  // GoalGoaThirdSectionInmg4 : GoalGoaThirdSectionInmg4,
  // GoalGoaThirdSectionInmg5 : GoalGoaThirdSectionInmg5,
  // GoalGoaFourthSectionImg1 : GoalGoaFourthSectionImg1,
  // GoalGoaFourthSectionImg2 : GoalGoaFourthSectionImg2,
  // GoalGoaFourthSectionImg4 : GoalGoaFourthSectionImg4,
  // GoalGoaFourthSectionImg5 : GoalGoaFourthSectionImg5,
  // GoalGoaFourthSectionImg6 : GoalGoaFourthSectionImg6,
  // GoalGoaFourthSectionImg7 : GoalGoaFourthSectionImg7,
  // GoalGoafifthSectionImg1 : GoalGoafifthSectionImg1,
  // GoalGoafifthSectionImg2 : GoalGoafifthSectionImg2,
  // GoalGoafifthSectionImg3 : GoalGoafifthSectionImg3,
  // GoalGoafifthSectionImg4 : GoalGoafifthSectionImg4,
  // GoalGoafifthSectionImg5 : GoalGoafifthSectionImg5,
  // GoalGoafifthSectionImg6 : GoalGoafifthSectionImg6,
  // GoalGoafifthSectionImg7 : GoalGoafifthSectionImg7,
  // GoalGoafifthSectionImg8 : GoalGoafifthSectionImg8,
  // GoalGoafifthSectionImg9 : GoalGoafifthSectionImg9,
  // GoalGoafifthSectionImg10 : GoalGoafifthSectionImg10,
  kidswithphone2: kidswithphone2,
  kidswithphone3: kidswithphone3,
  // GoalGoaBT1 : GoalGoaBT1,
  // GoalGoaBT2 : GoalGoaBT2,
  AboutBanner: AboutBanner,
};

export default application_contants;
