
const ProjectLayoutData = [
    {
        label: 'Template - Image',
        value: 1
    },
    {
        label: 'Template - Video Grid',
        value: 2
    },
    {
        label: 'Template - Single Video Grid',
        value: 3
    },
    {
        label: 'Template - Video - Section Title',
        value: 4
    },
    {
        label: 'Template - Read & Watch',
        value: 5
    },
    {
        label: 'Template - PlayStore and Supported By',
        value: 6
    },
    {
        label: 'Template - PDF',
        value: 7
    },
    {
        label: 'Image With Description',
        value: 8
    },
    {
        label: 'Template-Section Level Supported Image',
        value: 9
    },
    {
        label: 'Template- Title and Paragraph',
        value: 10
    },
]
export default ProjectLayoutData;