import React from "react";
import { Row, Col } from "reactstrap";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";

const SupportedImage = (props) => {
    return (
        <Row className="mx-0" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                        <Row className="mx-0 pb-5">
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Row className="mx-0 align-items-center">
                                    {props.data.galleryList.length > 0 && props.data.galleryList.map((it, index) =>(
                                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                            <img src={`${ApiConfig.URL}${it.imgPath}`} alt="" />
                                        </Col>
                                    ))} 
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default SupportedImage;
