import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import SEOComponent from '../../customComponent/SEO/SEOComponent';

const WhistleBlowerPolicy = () => {
    return (
        <ExternalLayout>
             <SEOComponent title="Going to School" subtitle="Privacy Whistle Blower Policy"  description=""
             />
            <RenderPage
                id="policies-page"
                className="render-page bg-zircon-grey"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-policies"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-col-no--padding">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto mobile-col-no--padding">
                                    <h6 className="h6-small external-title pt-5 pb-4"> <Link to={'/policies'} className="link--underline--none ">{`Our Policies >>`}</Link>
                                        <font className="pl-8px">Whistle Blower Policy</font>
                                    </h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto pb-5 mobile-col-no--padding">
                                    <Card className="card-container">
                                        <CardBody className="card-body--container">
                                            <Row className="mx-0">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="policies-container">
                                                        <img src={application_contants.partyBlowerImg} alt="" className="policies-icon" />
                                                    </div>
                                                    <h6 className="h6-small external-title pb-3 card-title--position">Whistle Blower Policy</h6>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <div>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            The Board of Trustees of Going to School Fund have adopted the WhistleBlower
                                                            Policy so that the employees, colleagues, participants, full-time, part-time or freelance
                                                            colleagues and/or external observers in the organization’s programs have a secure
                                                            mechanism to report any concerns that they may have of actual, suspected or planned
                                                            wrongdoings (hereinafter "wrongdoings") involving a Going to School project, program,
                                                            consultant, vendor, anyone involved in any program implemented by Going to School
                                                            fund, full-time, or part-time, freelance, including its directors, officers, colleagues and participants.
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2 lh-25px">
                                                        Going to School’s employees, colleagues, participants of the not-for-profit trust’s
                                                        program and projects, full-time or part time, have a right to report any such
                                                        concerns through this policy, knowing full well that such an act of whistle
                                                        blowing on his / her part would not lead to any discrimination or recrimination against them.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The CEO, CFO and externally appointed auditor are to whom the colleague can cite their concern.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Correspondence may be addressed to the CEO, CFO, External Auditor at the address
                                                        of the Registered Office at 4/23, 4th Floor, Birbal Road, F-Block Jangpura
                                                        Extension, New Delhi, 110014 or by email to whistleblower@goingtoschool.com
                                                        finance@goingtoschool.com and/or lisa@goingtoschool.com,
                                                        gagan@goingtoschool.com, adeeshmehra@yahoo.com or to call (m) 9810621876
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        The Whistleblower is Protected
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Any colleague/participant of the trust’s programs, making a report in good faith, can do
                                                        so in the knowledge and confidence that the Board of Trustees will ensure that the act
                                                        will not lead to the colleague facing any recrimination, punishment or victimization whatsoever.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Reports made in good faith must be based on a reasonable belief that a Wrongdoing
                                                        has occurred or is likely to occur. If a subsequent investigation reveals that there was no
                                                        Wrong doing the employee making the report would not be subjected to any victimization
                                                        or disciplinary action if he/she had acted in good faith.
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Policy Scope
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The policy applies to all Going to School projects and programs in India.
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        WrongDoing for WhistleBlowing Purposes, includes the following (This is an illustrative list and colleagues can report other concerns)
                                                    </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Child Protection Violation</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Sexual Harassment</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Criminal activities</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Fraud, forgery of project data, materials, misrepresentation of work completed </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Bribery or corruption</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Provision of misleading information or the falsification of financial or other records within the project/program</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Breaches of copyright, patents and licenses for Going to School content</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Violation of environmental/Climate Change commitments the project has made to recycle, repurpose, use recycled paper, avoid plastic</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Failure to comply with legal obligations either within the project, program or by the Government of India on location on the stated project/program</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Violation of the Code of Conduct which is to state directly violation of the Child Protection policy and/or Sexual Harassment Policy</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Violations of human rights, of child rights, use of child labour, workplace harassment, bullying, unfair treatment of colleagues</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Falsification of reports and data - baseline, midline, endline, from children, young people or impact data in the program</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Falsification of inventory registers for goods received, their current status and/or theft</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Falsification of bills submitted to the trust for reimbursement or corroboration with third party vendors to increase prices and take a payback/cut/favor at another time</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Misusing Going to School office premises, materials (cameras, computers)</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Selling Going to School content, books to other organizations</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Pretending to represent the organization in hiring and/or in other ways</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Willfully maligning the work, reputation and/or directors, management, colleagues of the organization verbally, online or to other colleagues, partners, participants for no apparently valid or substantiated reason</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Breaches of other policies and procedures (including, without limitation, breaches of financial controls and reporting requirements) AND concealing or overlooking any of the above, sharing, selling or making use of internal data without permission for personal and/or employment gain</p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        This is an illustrative list and colleagues can report any other concerns that might not have been covered on the above list.
                                                    </p>
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Procedures
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Where an participant of a project suspects there is a Wrongdoing or has been asked to participate in a Wrongdoing, the participant has a paramount duty to report the concern immediately
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Reports should be made to the address, emails and individuals referred to in paragraph ONE above and in Annex 1
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        If a participant has a concern that the Code of Conduct is not being properly
                                                        complied with, she should report it, in the first instance, to her immediate
                                                        superior. In case the concern involves the superior or the colleague believes that
                                                        his/her report has not been acted upon, he / she should report the matter to the
                                                        CEO, CFO and External Auditor. Wherever possible an Going to School
                                                        Employee should make a report disclosing his/her identity. However, if he/she
                                                        makes a disclosure anonymously then consideration will only be given to such disclosures if:-
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">The subject matter is serious enough to justify it;</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Sufficient information is provided to take further action;</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Anonymity does not harm the credibility of the disclosure; and anonymity does not prevent the confirmation of details with other sources</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">All reports under this Policy will be promptly and thoroughly investigated, and all information disclosed during the course of the investigation will remain confidential, except as necessary to conduct the investigation and take any remedial action, in accordance with applicable law. </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">All reports will be investigated by persons with the appropriate authority and who are not directly linked with any aspect of the Wrongdoing as may be instructed to by the CEO, CFO and the External Auditor. </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Wherever necessary, or required by law, a report may be referred to an external body for further investigation. Where feasible any such referral will be subject to the agreement of the CEO, CFO and External Auditor.</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">All colleagues/participants of the Going to School Fund Program have a duty to cooperate in the investigation of reports of Questionable Accounting / Audit Matters or the reporting of fraudulent financial information or of Grave Misconduct or of discrimination, retaliation or harassment resulting from the reporting or investigation of such matters. </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">A colleague/participant shall be subject to disciplinary action, including the
                                                        termination of their employment, if the colleague fails to cooperate in an
                                                        investigation, or deliberately provides false information during an investigation. If,
                                                        at the conclusion of its investigation, the organization determines that a violation
                                                        of policy has occurred, the organization will take effective remedial action
                                                        commensurate with the severity of the offense. This action may include
                                                        disciplinary action against the accused party, up to and including termination.
                                                        Reasonable and necessary steps will also be taken to prevent any further violations of policy.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">All documents related to the reporting, investigation and enforcement of this
                                                        policy, as a result of a report of questionable accounting, internal accounting
                                                        controls, or auditing matters, or the reporting of fraudulent financial information or
                                                        matter pertaining to Misconduct, or of the discrimination, retaliation or
                                                        harassment of an employee who made such a report, shall be kept in
                                                        accordance with the retention policy under applicable law.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The results of an investigation shall be communicated to the GTS colleague if
                                                        considered appropriate by the CEO, CFO, External Auditor provided the
                                                        colleague concerned has agreed to maintain confidentiality.
                                                    </p>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">Amendments</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The Board of Directors of the Organization may modify this Policy. Modification may be
                                                        necessary, among other reasons, to maintain compliance with local, state and central
                                                        regulations and / or to accommodate organizational changes within the Organization.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px"></p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">This policy has been updated as of February 2021.</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Annex 1 </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Contact Details for Filing a WhistleBlower Complaint</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Correspondence may be addressed to the CEO, CFO, External Auditor at the address
                                                        of the Registered Office at 4/23, 4th Floor, Birbal Road, F-Block Jangpura
                                                        Extension, New Delhi, 110014 or by email to whistleblower@goingtoschool.com
                                                        finance@goingtoschool.com and/or lisa@goingtoschool.com,
                                                        gagan@goingtoschool.com, adeeshmehra@yahoo.com or to call (m) 9810621876
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px">Lisa Heydlauff</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px">CEO</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px">lisa@goingtoschool.com</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">(m) 9810621876</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px">Gagan Gena</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px">CFO</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px">gagan@goingtoschoo.com</p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
}

export default WhistleBlowerPolicy;