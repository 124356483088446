
import React from 'react';
import { Row, Col, Card, CardBody, } from 'reactstrap';
import {useHistory } from 'react-router-dom';
import ArrowForward from "@material-ui/icons/ArrowForward";
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import PoliciesData from '../../Utils/Json/policies';
import SEOComponent from '../../customComponent/SEO/SEOComponent';

const Policies = () => {
    const history = useHistory();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleLink = (link) => {
        setTimeout(() => {
            history.push(`${link}`)
        }, 400)
    }

    return (
        <ExternalLayout>
            <SEOComponent title="Our Policies" subtitle="Going to School"  description="Going to School Policies."
             />
            <RenderPage
                id="policies-page"
                className="render-page bg-zircon-grey"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-policies"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-col-no--padding">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto mobile-col-no--padding">
                                    <h6 className="h6-small external-title title-denim-blue-light pt-5 pb-4">Our Policies</h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto pb-5 mobile-col-no--padding">
                                    <Row className="pb-5">
                                        {PoliciesData.map((item, index) => (
                                            <Col xs={12} sm={12} md={12} lg={4} xl={4} key={index}>
                                                <Card className="card-container supporter__effect" onClick={() => handleLink(item.link)}>
                                                    <CardBody className="card-body--container">
                                                        {/* <Link to={item.link} className="link--underline-none"> */}
                                                        <div className="policies-container">
                                                            <img src={item.icon} alt="" className={`${index === 1 || index === 2 ? 'policies-icon' : ''}`} />
                                                        </div>
                                                        <h6 className="h6-small external-title pb-3 card-title--position">{item.title}</h6>
                                                        <p className="external-section--title title-denim-blue-light link--underline">{item.linkText}</p>
                                                        {/* </Link> */}
                                                    </CardBody>
                                                    {/* <div class="go-corner" href="#">
                                                        <div class="go-arrow">
                                                            <ArrowForward />
                                                        </div>
                                                    </div> */}
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};


export default Policies;


