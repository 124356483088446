import React, { useState } from 'react';
import ReactPlayer from "react-player";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Row, Col } from 'reactstrap';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        padding: 18,
        border: 0,
        '&:focus': {
            outline: 'none',
        },
    },
}));

const VideoDetailsModal = (props) => {
    const classes = useStyles();
    const [isShow, setIsShow] = useState(false);
    const { open, toggle, item  } = props;
    const handleReadMore = () => {
        setIsShow(!isShow)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={toggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            style={{ overflow: 'scroll' }}
        >
            <Fade in={open}>
                <div className={`${classes.paper} modal-container-position`}>
                    <Row className="project-title--container mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="player-wrapper">
                                <ReactPlayer
                                    className="react-player"
                                    url={`${item.videoLink}`}
                                    width="100%"
                                    height="100%"
                                    playing={false}
                                    controls={true}
                                    volume={0.5}
                                    // poster={`${AwsConfig.url}${it.thumbnail}`}
                                    // onReady={true}
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 },
                                        },
                                        attributes: {
                                            controlsList: "download",
                                            poster: `${item.videoLink}`,
                                        },
                                    }}
                                //onProgress={(item) => handlePlayVideo(item, it)}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                            <h5 className="h5-medium external-section--title title-cornflower-blue robotoSlab-Bold pb-2">{item.videoTitle}</h5>
                            <div>
                                {isShow && item.videoDescription.length > 200 ?
                                    <div>
                                        {item.videoDescription.split(/[\n\r]/g).map((it, index) => (
                                            <p className="p-small external-section--title title-black-rock pb-1" key={index}>
                                                {it}
                                                {item.videoDescription.split(/[\n\r]/g).length - 1 === index ?
                                                    <font className="pl-8px external-title title-crimson-red cursor-pointer" onClick={() => handleReadMore()}>{`${isShow ? 'show less' : 'read more...'}`}</font> : null}
                                            </p>
                                        ))}
                                    </div>
                                    :
                                    <p className="p-small external-section--title title-black-rock pb-1">
                                        {`${item.videoDescription.length > 200 ? `${item.videoDescription.substr(0, isShow ? item.videoDescription.length : 200)}` : `${item.videoDescription}`}`}
                                        <font className="pl-8px external-title title-crimson-red cursor-pointer" onClick={() => handleReadMore()}>{`${item.videoDescription.length > 200 ? `${isShow ? 'show less' : 'read more...'}` : ""}`}</font>
                                    </p>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Modal>
    );
}

export default VideoDetailsModal;