import React, { useState } from 'react';
import PropTypes from "prop-types";
// import VideoPlay from '../VideoPlay/VideoPlay';
import VideoPlay from '../../VideoPlay/VideoPlay';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Row, Col } from 'reactstrap';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        border: 0,
        '&:focus': {
            outline: 'none',
        },
    },
}));
const VideoViewer = ({ isModal, setIsModal,videoItem,handleToggle }) => {
    const classes = useStyles();
   
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isModal}
            onClose={handleToggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isModal}>
                <div className={`${classes.paper} image-modal-container`}>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                            <VideoPlay videoLink={videoItem} playing={true}/>
                            
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Modal>
    )
}
export default VideoViewer;