
// function isNumeric(value) {
//     const constraint = /^[0-9\b]+$/;
//     return constraint.test(value);
// }

// // Alpha numeric values + white spaces
// function isAlphaNumeric(value) {
//     const constraint = /^[A-Za-z0-9- ]+$/;
//     return constraint.test(value);
// }


// function isText(value) {
//     return true;
// }

// function isEmailValid(value){
//     const constraint =  /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
//     return constraint.test(value)
// }


// function isDecimal(value) {
//     const constraint = /^(\+|-)?(\d*\.?\d*)$/;
//     console.log("boolean", constraint.test(value))
//     return constraint.test(value)
// }

export const isEmailValid = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
export const isPwdValid =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
export const isAlphaNumeric = /^[A-Za-z0-9- ]+$/;
// export {
//     isNumeric,
//     isAlphaNumeric,
//     isText,
//     isDecimal,
//     isEmailValid,
// };
