import React from "react";
import { Row, Col } from "reactstrap";
import VideoDetails from "./VideoDetails";


const VideoSingleGrid = (props) => {
    return (
        <Row className="mx-0 pt-5 pb-5" style={{ backgroundColor: `#${props.data.bgColorCode}` }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={9} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.sectionTitle ?
                            <div>
                                <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.sectionTitle ? `${props.data.sectionTitle}` : ''}</h2>
                                <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                            </div> :
                            <div>
                                {props.isMapofme ?
                                    <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>
                                            <a href={`https://${props.websiteUrl ? props.websiteUrl : ''}`} className="text-decoration--none" rel="noreferrer" target="_blank" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `MOM ${props.data.locationName}` : ''}</a>
                                        </h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                    : <div>
                                        <h2 className="external-section--title robotoSlab-Bold pb-1" style={{ color: `#${props.data.colorCode}` }}>{props.data.locationName ? `${props.data.locationName}` : ''}</h2>
                                        <p className="p-small external-section--title pb-4 robotoSlab-Regular lh-30px" style={{ color: `#${props.data.colorCode}` }}>{props.data.description}</p>
                                    </div>
                                }
                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {props.data.videoGalleryList.length > 0 && props.data.videoGalleryList.map((item, index) => (
                            <Row key={index} className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3 pb-3 px-0">
                                    <VideoDetails item={item} classNames="pl-0px" paramsClassNames={'video-single-info--position'} />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                    {/* {isModal ?
            <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
            : null} */}
                </Row>
            </Col>
        </Row>
    )
}

export default VideoSingleGrid;
