import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowSize } from '@react-hook/window-size';
import {
    ArrowForwardIosOutlined,
    ArrowBackIosOutlined
} from "@material-ui/icons";
import Left from "../../../assets/Homepage/left.png"
import Right from "../../../assets/Homepage/right.png"
import Arrow1 from '../../../assets/Homepage/yellow.png';
// import Card1 from '../../../assets/Homepage/card1.png';
// import Tv from '../../../assets/Homepage/plantv.png'
import logo from '../../../assets/Homepage/project/goallogo.png';
import VideoPlay from '../../VideoPlay/VideoPlay';
const Homeslider = ({ images, slide }) => {
    const [width] = useWindowSize();
    // const [imageList, setImageList] = useState(images)
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} next-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                {slide === 1 ? <img src={Right} /> : < ArrowForwardIosOutlined className="lr-arrow blue-arrow" />}
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} prev-icon-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                {slide === 1 ? <img src={Left} /> : < ArrowForwardIosOutlined className="lr-arrow blue-arrow" />}
            </div>
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: width >= 1024 ? 1 : 1,
        slidesToScroll: width >= 1024 ? 1 : 1,
        // slidesToShow: slide,
        // slidesToScroll: slide,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <Slider {...settings}>
                    {/* {slide === 1 ? */}
                       
                            {images && images.map((item, index) => (

                                <Row key={index} className="mx-0">

                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                        <div className="">
                                            <img src={item} alt="slider image " />
                                        </div>

                                    </Col>
                                </Row>
                            ))}
                        {/* </> : <> */}
                            

                          
                      
{/* 
                    } */}



                </Slider>
            </Col>
        </Row>
    )
}

export default Homeslider