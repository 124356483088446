import React, { useState } from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../Strip/Strip";
import ExternalLayout from '../ExternalLayout/ExternalLayout';
import DoorSlider from './component/DoorSlider';
import SliderOneBg from '../../assets/OutDoor/First/slider-bg.png';
import Cele from '../../assets/OutDoor/First/cele.png';
import SliderOne1 from '../../assets/OutDoor/First/Slider/slider1-1.jpg';
import SliderOne2 from '../../assets/OutDoor/First/Slider/slider1-2.jpg';
import SliderOne3 from '../../assets/OutDoor/First/Slider/slider1-3.jpg';
import SliderOne4 from '../../assets/OutDoor/First/Slider/slider1-4.jpg';
import SliderOne5 from '../../assets/OutDoor/First/Slider/slider1-5.jpg';
import SliderOne6 from '../../assets/OutDoor/First/Slider/slider1-6.jpg';
import SliderOne7 from '../../assets/OutDoor/First/Slider/slider1-7.jpg';
import SliderOne8 from '../../assets/OutDoor/First/Slider/slider1-8.jpg';
import SliderOne9 from '../../assets/OutDoor/First/Slider/slider1-9.jpg';
import FootBall from '../../assets/OutDoor/second/football.png';
import Slidertwo1 from '../../assets/OutDoor/second/slider/slider2-1.jpg';
import Slidertwo2 from '../../assets/OutDoor/second/slider/slider2-2.jpg';
import Slidertwo3 from '../../assets/OutDoor/second/slider/slider2-3.jpg';
import Slidertwo4 from '../../assets/OutDoor/second/slider/slider2-4.jpg';
import Slidertwo5 from '../../assets/OutDoor/second/slider/slider2-5.jpg';
import Slidertwo6 from '../../assets/OutDoor/second/slider/slider2-6.jpg';
import Slidertwo7 from '../../assets/OutDoor/second/slider/slider2-7.jpg';
import Slidertwo8 from '../../assets/OutDoor/second/slider/slider2-8.jpg';
import Sliderthree1 from '../../assets/OutDoor/third/slider/slider3-1.jpg';
import Sliderthree2 from '../../assets/OutDoor/third/slider/slider3-2.jpg';
import Sliderthree3 from '../../assets/OutDoor/third/slider/slider3-3.jpg';
import Sliderthree4 from '../../assets/OutDoor/third/slider/slider3-4.jpg';
import Sliderthree5 from '../../assets/OutDoor/third/slider/slider3-5.jpg';
import Sliderthree6 from '../../assets/OutDoor/third/slider/slider3-6.jpg';
import Sliderthree7 from '../../assets/OutDoor/third/slider/slider3-7.jpg';
import Sliderthree8 from '../../assets/OutDoor/third/slider/slider3-8.jpg';
import Sust from "../../assets/OutDoor/third/sust.png";
import FootData from "../../assets/OutDoor/third/foot-data.png";
import PinkBall from "../../assets/OutDoor/forth/pinkball.png";
import  TreeFour from "../../assets/OutDoor/forth/tree.png";
import FourVideo from "../../assets/OutDoor/forth/video-img1.png";
import Goal from "../../assets/OutDoor/fifth/goal.png";
import GoalGoa from "../../assets/OutDoor/fifth/goa.png";
import Logo from "../../assets/OutDoor/fifth/logo.png";
import FifftVideo from "../../assets/OutDoor/fifth/fifth-ss.png";
import TreeElement from "../../assets/OutDoor/First/treeElement.png";
import VideoPlay from '../VideoPlay/VideoPlay';
import FifthTree from '../../assets/OutDoor/fifth/fifth-tree.png'
const OutDoor = () => {
    const[show,setShow]=useState(false);
    const[shows,setShows]=useState(false)
    const  sliderList=[
        SliderOne1, SliderOne2, SliderOne3, SliderOne4, SliderOne5, SliderOne6, SliderOne7, SliderOne8, SliderOne9,
    ] 
    const  sliderList2=[
        Slidertwo1, Slidertwo2, Slidertwo3, Slidertwo4, Slidertwo5,Slidertwo6,Slidertwo7,Slidertwo8,
    ]
    const  sliderList3=[
        Sliderthree1, Sliderthree2, Sliderthree3, Sliderthree4, Sliderthree5,Sliderthree6,Sliderthree7,Sliderthree8,
    ]
    return (
        <>
          
        {/* Section-1 start*/}

                <Strip
                    className="strip strip-no-padding out-one"
                    containerType="container-fluid  px-0 mt-5">
                        <Strip
                    className="strip strip-no-padding size-data"
                    containerType="container ">
                    <Row className=' pt-md-2'>
                     <Col xs={2} md={2} className="mt-md-5"> 
                     </Col>
                     <Col xs={10} md={9} className="mt-5 pt-lg-5 pt-md-3">
                     <div className='out1 '>
                                
                                <div className='sli-img'>
                                    <div className='sli'>
                                      
                                        <DoorSlider images={sliderList} slide={1}/>
                                    </div>
                                </div>
                                <img src={Logo} className='beach' />
                                <img src={TreeElement} className="sli-tree"/>
                            </div>
                     </Col>
                     <Col xs={12} md={1} className="mt-md-5">
                     </Col>
                     <Col xs={2} md={1} className="mt-md-5">
                      
                     </Col>
                     <Col xs={10} md={11} className="mb-md-3 mt-md-4 mb-2 ">
                        <div className='cele'>
                            <img src={Cele}/>
                        </div>
                     </Col>
                    </Row>

                </Strip>
                </Strip>
                   {/* Section-1 end*/}
                       {/* Section-2 start*/}
                <Strip
                    className="strip strip-no-padding out-two"
                    containerType="container-fluid  px-0 ">
                        <Strip
                    className="strip strip-no-padding "
                    containerType="container px-0">
                    <Row>
                    <Col xs={12} md={12} className="mt-3">
                      <div className='foot'>
                        <img src={FootBall}/>
                      </div>
                      </Col>
                      <Col xs={12} md={2} className="mt-md-3">
                     
                      </Col>
                      <Col xs={12} md={4} className="mt-lg-5 mt-md-4  pt-md-3 px-0 pb-md-5 ">
                      <div className='girl-pa 
                      '>
                        <div className='ps-md-3 pe-lg-5  pe-md-1 ms-lg-2'> 
                        <p><span>The Outdoor School for Girls</span> equips girls with the skills they need to problem-solve for Climate Change in India by playing football and learning skills outside.</p>
                      <p>
                      For the first time, football is being played and taught at school for girls, during school hours.
                      </p>
                      <p>Three days a week girls play football, the other three days a week, girls learn skills.</p>
                      <p>
                      Sustainable Enterprise, Life, Digital Skills, outside.
                      </p>
                        </div>
                     
                      <p className='high-bold ps-lg-4  ps-md-3 ms-lg-1'>2022-5 The Outdoor School has an audacious goal of reaching 2.7 million girls with football and skills.</p>
                      </div>
                      </Col>
                      <Col xs={12} md={5} className=" ps-md-4 pe-3  ps-3 pt-0 pb-md-5 ">
                      <div className='second-sli'>
                      <DoorSlider images={sliderList2} slide={1}/>
                      </div>
                      </Col>
                      <Col xs={12} md={1} className="mt-md-5 ">
                      
                      </Col>
                    </Row>

                </Strip>
                </Strip>
                    {/* Section-2 end*/}
                      {/* Section-3 start*/}

                <Strip
                    className="strip strip-no-padding out-three"
                    containerType="container-fluid  px-0 ">
                        <Strip
                    className="strip strip-no-padding "
                    containerType="container px-0">
                    <Row>
                    <Col xs={12} md={12} className="mt-3">
                      <div className='foot'>
                        <img src={Sust}/>
                      </div>
                      </Col>
                      <Col xs={12} md={2} className="mt-md-3">
                     
                      </Col>
                      <Col xs={12} md={4} className="mt-md-5  ps-md-0 pe-md-0 pe-5 ps-5 ms-md-0 ms-4 pb-lg-0 ">
                       <div className='sust-img'>
                    <img src={FootData}/>
                       </div>
                       <div className='foot-para ps-md-5 ps-2 pe-3 pt-3 '>
                        <p>Through partnerships with the Governments of Goa, Mumbai and Bengaluru. Going to School Teams deliver The Outdoor School for Girls outside in football pitches, allocated to Government Schools.</p>
                        <p>Three days a week, the Outdoor School for Girls is football played, taught, for the first time in India at school for girls.</p>
                        <p>The other three days, still outside, the Outdoor School for Girls is skills. Going to School teams bring scrapbooks and technology to girls, to the pitch, to enable them to learn.</p>
                       </div>
                      </Col>
                      <Col xs={12} md={5} className="ps-3 pe-3 ps-md-4 pe-md-4 ms-4 pt-0 pb-lg-0 pb-4" >
                      <div className='second-sli2 '>
                      <DoorSlider images={sliderList3} slide={1}/>
                      </div>
                      </Col>
                      <Col xs={12} md={1} className="mt-md-4">
                      
                      </Col>
                    </Row>

                </Strip>
                </Strip>
                   {/* Section-3 end*/}
                   {/* Section-4 start*/}
                <Strip
                    className="strip strip-no-padding out-four"
                    containerType="container-fluid  px-0 ">
                        <Strip
                    className="strip strip-no-padding "
                    containerType="container px-0">
                    <Row>
                    <Col xs={12} md={12} className="mt-3">
                      <div className='foot'>
                        <img src={PinkBall}/>
                      </div>
                      </Col>
                      <Col xs={12} md={2} lg={2} className="mt-md-3 mg ">
                     
                      </Col>
                      <Col xs={12} md={4} lg={3} className="mt-md-3 mb-xl-2 px-0 pb-md-5  mg">
                       <div className='tree-img'>
                        <img src={TreeFour}/>
                       </div>
                       <div className='tree-para ps-md-5 pt-3 '>
                        <p><span>The Outdoor School for Girls TV series</span> follows teams of girls as they play football and learn skills in the city, communicating the program nationwide.</p>
                       
                       </div>
                      </Col>
                      <Col xs={12} md={5} lg={6} className=" ps-md-4 mb-xl-2 mt-md-3 pb-md-5  mg" >
                     <div className='four-tv'>
                    {show ?
                 
                    <iframe  src="https://www.youtube.com/embed/videoseries?list=PL1ue9VzrTsigO9To_JxWUZ0xus8Hnyzmk&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    // <VideoPlay videoLink="/outdoor-tv1.mp4" playing={true}  /> 
                    :<img src={FourVideo} onClick={()=>setShow(!show)}/>}
                     </div>
                      </Col>
                      <Col xs={12} md={1} lg={1}className="pt-4   pb-md-5  mg" >
                     
                      </Col>
                    </Row>

                </Strip>
                </Strip>
                   {/* Section-4 end*/}
                   {/* Section-5 start*/}
                <Strip
                    className="strip strip-no-padding out-five"
                    containerType="container-fluid  px-0 ">
                        <Strip
                    className="strip strip-no-padding "
                    containerType="container px-0">
                    <Row>
                    <Col xs={3} md={3} >
                    <div className='goal1'>
                        <img src={Goal}/>
                      </div>
                     </Col>
                   
                      <Col xs={5} md={5} >
                    
                      </Col>
                      <Col xs={4} md={4} >
                      <div className='goal3'>
                        <img src={Logo}/>
                      </div>
                      </Col>
                      <Col xs={2} md={2} lg={2} className="mt-md-3">
                     
                      </Col>
                      <Col xs={12} md={4} lg={3} className="ps-5  mb-lg-5 goa ">
                      <div className='goal2'>
                        <img src={GoalGoa}/>
                      </div>
                       <div className='tree-para ps-md-3 pt-3 '>
                        <p className='mb-0'>Where did this all begin? </p>
                        <p>Once upon a time in a beach in North Goa,<span> Aliga Praia</span>, the beach football league for girls began, in the pandemic, with schools and pitches closed, girls and boys came out to the beach to play.</p>
                       <p>Under the purple monsoon clouds, fun footballers decided to make make a film, Goal Goa, about how the great game could get us out of this and into a new world where we could design and deliver differently to girls.</p>
                       <p>Goal Goa aired on national TV in India and on BT Sport in the UK, and so while we went global, we came back home to make our promise come true of teaching football at school for girls, inspired by what we knew of beach football we took the great game to wide open spaces in Goa, Mumbai and Bengaluru, at school, for girls.</p>
                       </div>
                      </Col>
                      <Col xs={12} md={5} lg={6} className="pe-4" >
                     <div className='five-tv'>
                    {shows ?
                    //  <VideoPlay videoLink="/outdoor-tv2.mp4" playing={true} padding={true} /> 
                    <iframe  src="https://www.youtube.com/embed/videoseries?list=PL1ue9VzrTsigwDu5hhNsA-acyUPVVwms3&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    :<img src={FifftVideo} onClick={()=>setShows(!shows)}/>}
                     </div>
                      </Col>
                      <Col xs={12} md={1} lg={1} className="pt-md-5 pt-2" >
                     
                      </Col>
                      {/* <Col xs={12} md={12} className="fifth-tree">
                           <div className='ff'>
                            <img src={FifthTree}/>
                           </div>
                      </Col> */}
                    </Row>

                </Strip>
                </Strip>
                   {/* Section-5 end*/}
         
        </>
    )
}

export default OutDoor