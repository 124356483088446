import Ikea from '../../assets/support_section/IKEAs.png';
import Plan from '../../assets/support_section/Plans.png';
import Plan1 from '../../assets/support_section/Plan12.png';
import Girl from '../../assets/support_section/girl.png';
import Play from '../../assets/support_section/play.png';
import British from '../../assets/support_section/img2.png';
import BtLogo from '../../assets/support_section/bat.png';
import Echidna from '../../assets/support_section/Echidna.png';
import Oracle from '../../assets/support_section/Oracle-logo.png';
import Prospero from '../../assets/support_section/Prospero logo.png'

const data = [{
    company_logo: [Ikea],
    heading1: "Get a Plan",
    side_logo: Plan,
    para:
        [
            " Get a Plan is a Government of Bihar and IKEA Foundation supported program that runs in 1,000 Government Secondary Schools in Bihar, India. 300,000 young people in Grade 9-12 are learning green skills through graphic novel adventures that they read outside, as they experience green skills in action through Green Enterprise Internships with Sustainable Entrepreneurs problem-solving for Climate Change.",
            " Get a Plan has many goals, one of them is working to keep girls in school, equipping girls with the skills they need to transition from school to sustainable enterprise of her choice after Grade 12, solving for Climate Change where she lives and creating green jobs for young people.",
            "Going to School is equipping secondary school students in Bihar, India, with the skills to set up a business, find a job or succeed in higher education.",
            "By co-creating new skills-to-work content with young people and training teachers in 1,000 schools to support students as they take their first steps towards employment, entrepreneurship or higher education, Going to School will lift the career prospects of 400,000 young people in one of India’s poorest states.",
            "To learn more about the program please do download the Youth Voices Report, 2022."
        ],
},
{
    company_logo: [Oracle],
    heading1: "Play City, The Children’s Scrappy News Service",
    side_logo: Play,
    para:
        [
            "Play City, The Children’s Scrappy News Service is a news-talk-game show edutainment series made in the cities of Mumbai and Bengaluru. Aired on local and nationwide channels, Play City, Scrappy, equips children with the dynamic STEM, life and problem-solving skills they need to take on the biggest challenge our cities face: Climate Change.",
            "Play City wants to make India’s mega cities giant sustainable places to play for one million children to take on Climate Change where they live.",
            "TV, online, on the ground in schools, Oracle supports Scrappy to equip children with the 21st Century Skills they need to transform the world around them.",
            "Oracle has been operating in India for over 25 years. Today, the company serves more than 15,000 companies in the private and public sectors. As one of the first multinational software firms to set up base in India, Oracle today employs more than 38,000 professionals representing a variety of business functions. These business functions include sales, marketing, consulting, support, finance, R&D or product development, global business units and education operations for domestic and global clients. Oracle offers integrated suites of applications plus secure, autonomous infrastructure in the Oracle Cloud. Through Oracle Giving, Oracle supports many non-profit organizations across various states in India.",

        ],
},
{
    company_logo: [BtLogo],
    heading1: "The Outdoor School for Girls",
    side_logo: Girl,
    para:
        [
            "The Outdoor School for Girls is supported by BAT and BT in Mumbai and Bengaluru. Reclaiming urban spaces for skills and sport, 280,000 girls, over three years, will learn digital, sustainable enterprise, life skills online, outside in football pitches. Running in India’s mega cities of Mumbai and Bengaluru, girls will learn the digital skills they need  to problem-solve for Climate Change in the City and become Climate Change entrepreneurs transforming cities for everyone.",

        ],
},
{
    company_logo: [Echidna],
    heading1: "Get a Plan, Madhubani",
    side_logo: Plan1,
    para:
        [
            "Echidna Foundation supports Get a Plan in Madhubani, Bihar to deliver to 10,000 girls.",
        ],
    heading2: "The Outdoor School for Girls",
    side_logo1: Girl,
    para1: [
        "Echidna Foundation supports The Outdoor School for Girls to deliver life skills to adolescent girls during school hours, outside in football pitches, equipping a generation of girls in Goa, Mumbai and Bengaluru’s Government Schools with the skills they need to one day, after Grade 12, become sustainable entrepreneurs problem-solving for Climate Change where they live.",
    ]
},
{
    company_logo: [Ikea],
    heading1: "Play City, The Children’s Scrappy News Service ",
    side_logo: Play,
    para:
        [
            "Play City, The Children’s Scrappy News Service, a 26-part, nationwide TV series designed to equip children with the skills they need to problem-solve for Climate Change is supported by IKEA Foundation.",
            "Play City is implemented on the ground through an upcycled, recycled scrapbook, a project-based activity series that is run in 1,200 BMC schools, offline, finding solutions for Climate Change in the city.",
            "Play City has an online skills universe and a Scrappy APP.",
            "The IKEA Foundation is funding Going to School because we believe that empowering young people to move from education to sustainable employment will help them afford a better life for themselves and their families.",
            "To see more please visit www.scrappynews.com .",

        ],
},
{
    company_logo: [Prospero],
    heading1: "Going to School is an Adopted Prospero World Charity",

    para:
        [
            "Prospero World is a Registered UK Charity (1163952) aiming to empower positive change through education, collaboration, and action. ",

        ],
}]
export default data;