import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import Strip from "../Strip/Strip";
import VideoPlay from "../VideoPlay/VideoPlay";
import Climate from "../../assets/GetPlan/climate.png";
import Energy from "../../assets/GetPlan/energy.png";
import Youth from "../../assets/GetPlan/Youth.png";
import Polar from "../../assets/GetPlan/polar.png";
// import Video1 from '../../assets/GetPlan/Rough-cut.mp4'
import Video2 from "../../assets/GetPlan/video2.png";
import Video4 from "../../assets/GetPlan/video4.png";
import GetSlider from "./component/GetSlider";
import Novel1 from "../../assets/GetPlan/novel/novels1.jpg";
import Novel2 from "../../assets/GetPlan/novel/novel2.jpg";
import Novel3 from "../../assets/GetPlan/novel/novel3.jpg";
import Novel4 from "../../assets/GetPlan/novel/novel4.jpg";
import Novel5 from "../../assets/GetPlan/novel/novel5.jpg";
import Digital1 from "../../assets/GetPlan/InfoSlider/digital1.jpg";
import Digital2 from "../../assets/GetPlan/InfoSlider/digital2.jpg";
import Digital3 from "../../assets/GetPlan/InfoSlider/digital3.jpg";
import Digital4 from "../../assets/GetPlan/InfoSlider/digital4.jpg";
import Digital5 from "../../assets/GetPlan/InfoSlider/digital5.jpg";
import Digital6 from "../../assets/GetPlan/InfoSlider/digital6.jpg";
import Digital7 from "../../assets/GetPlan/InfoSlider/digital7.jpg";
import Digital8 from "../../assets/GetPlan/InfoSlider/digital8.jpg";
import Digital9 from "../../assets/GetPlan/InfoSlider/digital9.jpg";
import Digital10 from "../../assets/GetPlan/InfoSlider/digital10.jpg";
import Ikea from "../../assets/GetPlan/ikea.png";
import Girl from "../../assets/GetPlan/girl.png";
import Button from "../../assets/GetPlan/button.png";
import SkyData from "../../assets/GetPlan/banner.jpg";
import ExternalLayout from "../ExternalLayout/ExternalLayout";
import SecondBg from "../../assets/GetPlan/grass.png";
import Polaroid from "../../assets/GetPlan/polaroid.png";
import GPText from "../../assets/GetPlan/gp-1.png";
const GetPlan = () => {
  const NovelSlider = [Novel1, Novel2, Novel3, Novel4, Novel5];
  const youthSlider = [
    Digital1,
    Digital2,
    Digital3,
    Digital4,
    Digital5,
    Digital6,
    Digital7,
    Digital8,
    Digital9,
    Digital10,
  ];
  const sliderBg = ["slider_bg"];
  const [show, setShow] = useState(false);
  return (
    <>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 banner-section"
      >
        <Row>
          <Col xs={12} md={12} lg={12} className="px-0">
            <div className="banner-photo d-flex justify-content-center mt-3">
              <img src={Climate} alt="climate" className="ms-mt-4  " />

              <img src={Energy} alt="climate" className="ms-4 " />
              <div className="youth-img">
                <img src={Youth} alt="climate" className="ms-4 " />
              </div>
            </div>
            <div className="banner-bg"></div>
          </Col>
        </Row>
      </Strip>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 section-two"
      >
        <Row className="pt-5 ">
          <div xs={12} className="grass-img">
            <Row>
              <Col xs={1} md={2}></Col>
              <Col xs={5} md={4} lg={4} className="px-0 get-para mt-3 mt-md-0">
                <div className="para-img text-md-start ">
                  <img src={Polar} />
                  <div className="para-data">
                    <img src={GPText} />
                    {/* <p className='mt-md-5 mt-3'>
                                        <span className='span-data'>Get a Plan </span> is a large-scale green
                                        skills at school project, run at school,
                                        during school hours in 1,000 Government
                                        Secondary Schools in Bihar, India.
                                        Get a Plan is designed to equip
                                        300,000 young people in the
                                        government school system with
                                        the skills they need to complete their
                                        education and transition from school
                                        to sustainable enterprise of her choice,
                                        one very soon becoming young start-up
                                        entrepreneurs solving for Climate Change.
                                    </p> */}
                  </div>
                </div>
                {/* <div className='get-data'>
                                    <img src={GPText} />
                                </div> */}
              </Col>

              <Col xs={5} md={4} lg={4} className="px-0 get-video mt-3 mt-md-0">
                <div className="paper-section text-md-start ">
                  <img src={Polar} className="poloimg" />
                  <div className="video-paper">
                    {show ? (
                      <VideoPlay
                        videoLink="https://youtube.com/shorts/y_m33fwkTDw?feature=share"
                        playing={true}
                      />
                    ) : (
                      <div className="video-img" onClick={() => setShow(true)}>
                        <img src={Polaroid} />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={1} md={2}></Col>
            </Row>
            {/* <img src={SecondBg} className="img-grass"/> */}
          </div>
        </Row>
      </Strip>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 section-three"
      >
        <Strip
          className="strip strip-no-padding"
          containerType="container px-0 "
        >
          <Row className="pt-lg-4">
            <Col
              xs={12}
              md={12}
              lg={12}
              className="px-0 d-flex justify-content-center"
            >
              <div className=" slider-head d-flex justify-content-center">
                <p className=" mt-2">
                  In partnership with the Government of Bihar and supported by
                  IKEA Foundation, Going to School has designed graphic novel
                  adventures to equip young people with the sustainable,
                  entrepreneurial, green skills she needs to complete her
                  education and transition from school to sustainable enterprise
                  of her choice.
                </p>
              </div>
            </Col>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={8} className="mb-lg-5">
              <GetSlider
                images={NovelSlider}
                slide={1}
                styles={sliderBg}
                arrow={true}
              />
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
        </Strip>
      </Strip>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 section-four"
      >
        <Strip className="strip strip-no-padding" containerType="container  ">
          <Row className="pt-md-3 pt-4 ">
            <Col
              xs={12}
              md={12}
              lg={12}
              className="px-0 d-flex justify-content-center pb-3"
            >
              <div className="four-head d-flex flex-column align-items-center">
                <p className=" mt-3">
                  <span className="span-data"> Get a Plan runs outside.</span>
                </p>
                <p className="text-start">
                  Outside, young people in the government school system elect to
                  complete green enterprise internships.
                </p>
                <p className="text-start text-md-center">
                  Outdoors with sustainable entrepreneurs in sectors that are
                  solving for Climate Change, clean energy, garbage, sustainable
                  packaging to replace plastic and organic farming, young people
                  get front line experience of what it means to run an
                  enterprise solving for Climate Change.
                </p>
                <p className="d-md-flex flex-md-column text-start text-md-center">
                  <span>
                    When a young person signs up for a green enterprise
                    internship she is given a graphic novel to
                  </span>
                  <span>take home and read across the next 21 days.</span>
                </p>
                <p className="text-start text-md-center">
                  Enterprise Curricula is delivered in two ways, through the
                  experience of the green enterprise internship and through
                  content and action projects where young people go to market to
                  check competition, pricing and give the entrepreneurs they are
                  interning with a sustainability overhaul.
                </p>
                <p className="text-start text-md-center">
                  Going to School teams are present every day, logging
                  attendance through an APP, running mini pre and post quizzes
                  and the larger evaluation of baseline to endline.
                </p>
              </div>
            </Col>

            <Col xs={12} md={12} lg={12}>
              <div className="video-two">
                <img src={Video2} />
                <iframe
                  src="https://www.youtube.com/embed/6UbjgZ5ZUgM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Strip>
      </Strip>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 section-five"
      >
        <Strip className="strip strip-no-padding" containerType="container  ">
          <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={7} lg={7} className="px-0">
              <div className="video-three">
                <img src={Video4} />
                <iframe
                  src="https://www.youtube.com/embed/YpPT8H2BOOc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 mt-md-0">
                <div className="trans-para">
                  <p className="m-0">
                    {" "}
                    Rough Cut: young people's TV about their green enterprise
                    internship,aired on state-wide TV.
                  </p>
                </div>
              </div>
            </Col>

            <Col xs={12} md={3} lg={3} className=" px-0 mt-3 ">
              <div className="girl-img">
                <img src={Girl} />
              </div>
              <div className="btn-img">
                <a
                  href="/Youth Voices, Bihar Report 2022_30 June 2022_Final_CTC_For Website.pdf"
                  target="_blank"
                  className="text-decoration-none text-dark"
                >
                  <img src={Button} />
                </a>
              </div>
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>
        </Strip>
      </Strip>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 section-six"
      >
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div>
              <img src={SkyData} />
            </div>
          </Col>
        </Row>
      </Strip>
      <Strip
        className="strip strip-no-padding"
        containerType="container-fluid px-0 bg-seven"
      >
        <Strip
          className="strip strip-no-padding"
          containerType="container px-0 "
        >
          <Row>
            <Col xs={12} md={1} className="pt-md-3"></Col>
            <Col xs={12} md={10} className="pt-3">
              <GetSlider images={youthSlider} slide={1} styles={sliderBg} />
            </Col>
            <Col xs={12} md={1} className="pt-md-3"></Col>
            <Col xs={12} md={12} className="pt-md-4 pb-3">
              <div className="text-center">
                <h5>Get a Plan is supported by </h5>
                <div className="sup-logo mt-3">
                  <img src={Ikea} />
                </div>
              </div>
            </Col>
          </Row>
        </Strip>
      </Strip>
    </>
  );
};

export default GetPlan;
