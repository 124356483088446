import PlayBanner from '../../../assets/Homepage/Modal/play-mini-bn.png';
import PlayBtn from '../../../assets/Homepage/Modal/play-btn.png';
import Playimg1 from '../../../assets/Homepage/Modal/playimg1.png';
import Playlogo from '../../../assets/Homepage/Modal/play-logo.png';
import PlayDate from '../../../assets/Homepage/Modal/play-date.png';
import OutBanner from '../../../assets/Homepage/Modal/Outdoor-bn.png';
import OutImg1 from '../../../assets/Homepage/Modal/outimg1.png';
import OutBtn from '../../../assets/Homepage/Modal/outbtn.png';
import OutDate from '../../../assets/Homepage/Modal/outdate.png';
import OutLogo from '../../../assets/Homepage/Modal/out-logos.png';
import GetBanner from '../../../assets/Homepage/Modal/get-bn.png';
import GetImg1 from '../../../assets/Homepage/Modal/getimg1.png';
import GetLogo from '../../../assets/Homepage/Modal/ikea.png';
import GetDate from '../../../assets/Homepage/Modal/getdate.png';
import GetBtn from '../../../assets/Homepage/Modal/getbtn.png';
import GoalBanner from '../../../assets/Homepage/Modal/goal-bn1.png';
import GoalImg1 from '../../../assets/Homepage/Modal/goalimg1.png';
import GoalLogo from '../../../assets/Homepage/Modal/goallogo.png';
import GoalBtn from '../../../assets/Homepage/Modal/goal-btn.png';
import YesBanner from '../../../assets/Homepage/Modal/yes-bn.png';
import YesImg1 from '../../../assets/Homepage/Modal/yesimg1.png';
import YesLogo from '../../../assets/Homepage/Modal/oracle.png';
import YesBtn from '../../../assets/Homepage/Modal/yes-btn.png';
import SchoolBanner from '../../../assets/Homepage/Modal/school-bn.png';
import SchoolImg1 from '../../../assets/Homepage/Modal/schoolimg1.png';
import SchoolLogo from '../../../assets/Homepage/Modal/schoollogo.png';
import SchoolParaLogo from '../../../assets/Homepage/Modal/school-para-logo.png';
import SchoolBtn from '../../../assets/Homepage/Modal/school-btn.png';
import Schooltree from '../../../assets/Homepage/Modal/school-tree.png';
import ScrappyBanner from '../../../assets/Homepage/Modal/scrappy-bn.png';
import ScrappyImg1 from '../../../assets/Homepage/Modal/scrappyimg1.png';
import ScrappyLogo from '../../../assets/Homepage/Modal/scrappylogo.png';
import ScrappyBtn from '../../../assets/Homepage/Modal/scrappy-btn.png';
import GirlBanner from '../../../assets/Homepage/Modal/girl-bn.png';
import GirlImg1 from '../../../assets/Homepage/Modal/girlimg1.png';
import GirlLogo from '../../../assets/Homepage/Modal/girl-logo.png';
import GirlBtn from '../../../assets/Homepage/Modal/girl-btn.png';
import LunaBanner from '../../../assets/Homepage/Modal/luna-bn.png';
import LunaImg1 from '../../../assets/Homepage/Modal/lunaimg1.png';
import LunaLogo from '../../../assets/Homepage/Modal/luna-logo.png';
import LunaBtn from '../../../assets/Homepage/Modal/luna-btn.png';
import BeBanner from '../../../assets/Homepage/Modal/be-bn.png';
import BeImg1 from '../../../assets/Homepage/Modal/beimg1.png';
import BeLogo from '../../../assets/Homepage/Modal/be-logo.png';
import BeBtn from '../../../assets/Homepage/Modal/be-btn.png';
import BeDate from '../../../assets/Homepage/Modal/be-date.png';
import CodeBanner from '../../../assets/Homepage/Modal/code-bn.png';
import CodeImg1 from '../../../assets/Homepage/Modal/codeimg1.png';
import CodeLogo from '../../../assets/Homepage/Modal/code-logo.png';
import CodeBtn from '../../../assets/Homepage/Modal/code-btn.png';
import CodeDate from '../../../assets/Homepage/Modal/code-date.png';
import PitchBanner from '../../../assets/Homepage/Modal/pitch-bn.png';
import PitchImg1 from '../../../assets/Homepage/Modal/pitchimg1.png';
import PitchLogo from '../../../assets/Homepage/Modal/pitch-logo.png';
import PitchBtn from '../../../assets/Homepage/Modal/pitch-btn.png';
import PitchDate from '../../../assets/Homepage/Modal/pitch-date.png';
import GoingBanner from '../../../assets/Homepage/Modal/going-bn.png';
import GoingImg1 from '../../../assets/Homepage/Modal/goingimg1.png';
import GoingLogo from '../../../assets/Homepage/Modal/going-logo.png';
import GoingBtn from '../../../assets/Homepage/Modal/going-btn.png';
import MapBanner from '../../../assets/Homepage/Modal/map-bn1.png';
import MapImg1 from '../../../assets/Homepage/Modal/map-img1.png';
import MapLogo from '../../../assets/Homepage/Modal/map-logo.png';
import MapBtn from '../../../assets/Homepage/Modal/map-btn.png';
import MapMum from '../../../assets/Homepage/Modal/mumbai.png';
import MapKol from '../../../assets/Homepage/Modal/kol.png';
import MapBen from '../../../assets/Homepage/Modal/benga.png';
import MapDel from '../../../assets/Homepage/Modal/delhi.png';
const data = {
    "playData":
    {
        bg: "play-mini",
        banner: PlayBanner,
        btn: PlayBtn,
        img1: Playimg1,
        img2: PlayDate,
        projectUrl:"play-city-the-childrens-scrappy-news-service",
        img3: Playlogo,

        para1: "Play City The Children's Scrappy News Service -",
        para2: "Making India's cities giant sustainable places to play for millions of children to play together and find solutions to Climate Change."
    },
    "mapData":
    {
        bg: "map-mini",
        banner: MapBanner,
        btn: MapBtn,
        img1: MapImg1,
        img3: MapLogo,
        tabs:["#Map of Me","#BT","#Mumbai","#Bengaluru", "#Delhi","#Kolkata"],
        projectUrl:"map-of-me",
        tv1:MapMum,
        mumVideo:"https://youtu.be/uKj9K6iSCgk",
        tv2:MapBen,
        benVideo:"https://youtu.be/9-Kal1LdYB4",
        tv3:MapDel,
        delVideo:"https://youtu.be/3iFEfnx-Gak",
        tv4:MapKol,
        kolVideo:"https://youtu.be/qBZBeDVGNko",
        para1: "Map of Me",
        para2: "Map of Me aired on TV in Mumbai, Bengaluru, Delhi and Kolkata. The daily get to work show explores the skills young women need to move from school to work or sustainable enterprise of her choice. MOM TV aired on TV and online."
    },
    "outData":
    {
        bg: "out-mini",
        banner: OutBanner,
        btn: OutBtn,
        img1: OutImg1,
        img2: OutDate,
        img3: OutLogo,
        projectUrl:"outdoor-school",
        para1: "The Outdoor School for Girls",
        para2: " brings football to school for girls for the first time in government schools in India. Kicking off at scale, the Outdoor School has an audacious goal of equipping 2.7 million girls with the football, life, digital, sustainable enterprise skills she needs to one day soon move from the muddy pitch to becoming start-up sustainable entrepreneurs who solve for Climate Change where she lives."
    },
    "getData":
    {
        bg: "get-mini",
        banner: GetBanner,
        btn: GetBtn,
        img1: GetImg1,
        img2: GetDate,
        img3: GetLogo,
        projectUrl:"get-a-plan",
        para1: "Get A Plan",
        para2: "Graphic novel adventures equip young people with the sustainable, entrepreneurial, green skills to transition from school to sustainable enterprise of her choice. Green Enterprise Internships run outside, with sustainable entrepreneurs give young people a hands-on experience of problem-solving for Climate Change by becoming a sustainable entrepreneur in rural Bihar."
    },
    "goalData":
    {
        bg: "goal-mini",
        banner: GoalBanner,
        btn: GoalBtn,
        img1: GoalImg1,
        img3: GoalLogo,
        projectUrl:"goal-goa",
        para1: "Goal Goa",
        tabs:["#GoalGoa","#TaketheShot","#GirlsFootball","#Goa"],
        para3: "'Football is in the blood of every Goan' chasing footballers young and old, across Goa, this feature film discovers how the great game can enable girls to play football at school for the first time in India."
    } ,
    "yesData":
    {
        bg: "yes-mini",
        banner: YesBanner,
        btn: YesBtn,
        img1: YesImg1,
        img3: YesLogo,
        projectUrl:"young-entrepreneurs-skills-school-marathi",
        para1: "The Young Entrepreneurs Skills School ",
        para2: " in Marathi introduces young people to the joys of learning skills to become an entrepreneur, in classrooms in government schools and on state-run television.",
        para3:"What do you want to be when you grow up? Green energy entrepreneur? Self-defence trainer? Policewoman? Bee keeper? Open an all-women run motor driving school for women? Yes to all but don't know where to learn the skills you need to start your own enterprise? The Yuwa Udhyojak Kaushalya Show, a 26-part TV series in Marathi teaches entrepreneurial and life skills to students in grades 9-12 to help them make the transition from school to ec their choice after grade 12. The TV show premiered on February 2, 2021 on Doordarshan Sahyadri (DD Sahyadri), the state government-run television channel."
    },
    "schoolData":
    {
        bg: "school-mini",
        banner: SchoolBanner,
        btn: SchoolBtn,
        img1: SchoolImg1,
        img3: SchoolLogo,
        projectUrl:"school-tv",
        schooltree:Schooltree,
        paraimg:SchoolParaLogo ,
        para1: "School TV ",
        para2: " A DIY series on Doordarshan Bihar & Doordarshan Rajasthan that explores skills for employability and entrepreneurship for young people led by experts and professionals, from beekeeping to making DIY organic home-cleaners, composting, starting your own shoestring-budget enterprise, coding and more.",
        para3:"Over 200 episodes of Master Classes made during lockdown by experts in their fields who taught what they know in Hindi. The show aired on DD in Bihar and Rajasthan with support from the Government and reached 20 million+ children/day. BT and Oracle volunteers also created several episodes covering different topics from IT to communications to the importance of exercise."
    },
    "scrappyData":
    {
        bg: "scrappy-mini",
        banner: ScrappyBanner,
        btn: ScrappyBtn,
        img1: ScrappyImg1,
        img3: ScrappyLogo,
        projectUrl:"the-childrens-scrappy-news-service",
        para1: "Play City - The Children's Scrappy News Service",
        para2: "~ Scrappy to make something out of nothing to change everything. A news-talk-game-show mass-media program, made in India for a world of children that runs on national television, online, and most importantly, on the ground in government schools in four languages.",
        
    },
    "girlData":
    {
        bg: "girl-mini",
        banner: GirlBanner,
        btn: GirlBtn,
        img1: GirlImg1,
        img3: GirlLogo,
        projectUrl:"a-girls-guide-to-21st-century-india",
        para1: "A Girls Guide to 21st Century India",
        para2: "An offline-to-online toolkit for girls to learn the skills they need to complete their education and make new life plans. For boys it was A Boys Guide to 21st Century India",
        
    },
    "lunaData":
    {
        bg: "luna-mini",
        banner:LunaBanner,
        btn:LunaBtn,
        img1:LunaImg1,
        img3:LunaLogo,
        projectUrl:"luna",
        para1: "Luna",
        para2: "20 stories run in Kasturba Gandhi Balika Vidyalayas or KGBVs in Bihar, with an animated series. The Luna series is a girl's quest to find everything that is wrong in the world and finally set it right. Is there a Luna inside of you?",
        
    },
    "beData":
    {
        bg: "be-mini",
        banner:BeBanner,
        btn:BeBtn,
        img1:BeImg1,
        img2:BeDate,
        img3:BeLogo,
        projectUrl:"be-an-entrepreneur",
        para1: "Be! An Entrepreneur",
        para3: "A series of illustrated books to teach children in Grade 9 entrepreneurial skills with weekly action projects, teacher training and skills project assessment.",
        
    },
    "codeData":
    {
        bg: "code-mini",
        banner:CodeBanner,
        btn:CodeBtn,
        img1:CodeImg1,
        img2:CodeDate,
        img3:CodeLogo,
        projectUrl:"code-of-bihar",
        para1: "Code For Bihar",
        para3: "Code for Bihar equipped girls studying in government schools with problem-solving and design-thinking abilities through coding.",
        
    },
    "pitchData":
    {
        bg: "pitch-mini",
        banner:PitchBanner,
        btn:PitchBtn,
        img1:PitchImg1,
        img2:PitchDate,
        img3:PitchLogo,
        projectUrl:"pitch-it",
        para1: "Pitch it!",
        para3: "A school-change-enterprise challenge that saw children design-think brilliant, practical ideas for change around them from sports pitches for girls to birdhouses in schools with solar panels to keep them warm in the winter, with an on-ground live-streamed final event.",
        
    },
    "goingData":
    {
        bg: "going-mini",
        banner:GoingBanner,
        btn:GoingBtn,
        img1:GoingImg1,
        img3:GoingLogo,
        projectUrl:"going-to-school-in-india",
        para1: "Going to School in India",
        para3: "A 144-page award-winning children's book and TV series that formed the genesis of a 20+ year-long journey that saw children across India answer one question - How do you go to school?",
        para4:"Nine movies, based on the book Going to School in India, were created to explore a day in the life of children going to school in India in a tent, on a mountain top, by wheelchair, children in India explore their worlds learning lessons relevant to their lives."
        
    }
}

export default data;