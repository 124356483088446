import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    PeopleAltRounded,
    ViewCarousel,
    CollectionsBookmark,
    WorkOutline,
    HomeOutlined,
    MapOutlined,
    ReportOffOutlined
} from "@material-ui/icons";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import application_contants from "../../constants/application_contant";
import activeTab from "../../Utils/activeTab";

const SlideMenu = () => {
    const history = useHistory();
    const location = useLocation();
    console.log(location.pathname);
    return (
        <Navigation
            activeItemId={activeTab(location.pathname)}
            onSelect={({ itemId }) => {
                history.push(itemId);
            }}
            items={[
                {
                    title: "Banner",
                    itemId: application_contants.BANNER,
                    elemBefore: () => <ViewCarousel />,
                    //subNav: [],
                },
                {
                    title: "Location",
                    itemId: application_contants.LOCATION,
                    elemBefore: () => <MapOutlined />,
                    //subNav: [],
                },
                {
                    title: "About",
                    itemId: application_contants.ABOUT,
                    elemBefore: () => <HomeOutlined />,
                    //subNav: [],
                },
                {
                    title: "People",
                    itemId: application_contants.PEOPLES,
                    elemBefore: () => <PeopleAltRounded />,
                    //subNav: [],
                },
                {
                    title: "Supporter",
                    itemId: application_contants.SUPPORTER,
                    elemBefore: () => <CollectionsBookmark />,
                    //subNav: [],
                },
                {
                    title: "Projects",
                    itemId: application_contants.PROJECT,
                    elemBefore: () => <WorkOutline />,
                    //subNav: [],
                },
                {
                    title: "Financials",
                    itemId: application_contants.FINANACIAL,
                    elemBefore: () => <ReportOffOutlined />,
                    //subNav: [],
                },
            ]}
        />
    );
};

export default SlideMenu;
