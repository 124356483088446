import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const PeopleService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateTeam } = ApiConfig;

        const url = baseUrl + insertOrUpdateTeam;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            name: `${data.name}`,
            imgPath: `${data.imgPath}`,
            designationId: data.designationId,
            typeId: data.typeId,
            description: `${data.description}`,
            locationName: `${data.locationName}`,
        });

        const peopleServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_peopleService",
            true
        );
        return peopleServicePromise;
    },

    getPeopleList: async () => {
        const { baseUrl, getTeamList } = ApiConfig;

        const url = baseUrl + getTeamList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const peopleServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_people",
            true
        );
        return peopleServicePromise;
    },

    enableDisableTeam: async (data) => {
        const { baseUrl, enableOrDisableTeam } = ApiConfig;

        const url = baseUrl + enableOrDisableTeam;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: data.status,
        });

        const enableDisableUserServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableUser",
            true
        );
        return enableDisableUserServicePromise;
    },

    updateSequenicalOrder: async (data, userId) => {
        const { baseUrl, updateSequenicalOrderForTeam } = ApiConfig;

        const url = baseUrl + updateSequenicalOrderForTeam;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            data: data,
            userId: userId,
        });

        const updateSequenicalOrderServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_updateSequenicalOrderForPeople",
            true
        );
        return updateSequenicalOrderServicePromise;
    },

};

export default PeopleService;
