import React, { useReducer, useMemo, useEffect, useContext, useState } from "react";
import PropTypes from 'prop-types';
import AuthReducer, {
    initialState,
    LOGGED_IN,
    LOGGED_OUT,
} from '../reducers/AuthReducer';
import { asyncStoreClear, asyncStoreGet, asyncStoreSave } from "../Utils/asyncStorage";
import debugLogger from "../Utils/debugLogger";
import UserService from "../../api/services/UserService";

export const LOGIN_KEY = 'is_login';
export const USER_ID = "userId";
export const keys = [LOGIN_KEY];

const initialContext = initialState;

const AuthContext = React.createContext(initialContext);

export function AuthProvider(props) {
    //  const [isLogin, setIsLogin] = useState(false);
    const [authState, dispatch] = useReducer(AuthReducer, initialState || {});

    useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            try {
                //GET TOKEN && USER
                let isAuth = await asyncStoreGet(LOGIN_KEY);
                debugLogger('AuthProvider: bootstrapAsync token:', isAuth);
                //&& user !== null
                if (isAuth !== null) {
                    await login({ isAuth, initialiseUser: true });
                } else await logout();
            } catch (err) {
                throw err.error;
            }
        };

        bootstrapAsync();
    }, []);


    const login = async data => {
        let isAuth = '';
        try {
            let response = '';
            if (data.initialiseUser) {
                dispatch({ type: LOGGED_IN })
            } else {
                return new Promise(async function (resolve, reject) {
                    await UserService.login(data).then(async (res) => {
                        if (res.status === 200) {
                            try {
                                res.text().then(async (res) => {
                                    let result = JSON.parse(res);
                                    if (result.code === 0) {
                                        if (result.data) {
                                            await asyncStoreSave(LOGIN_KEY, true);
                                            await asyncStoreSave(USER_ID, result.data.userId);
                                            dispatch({ type: LOGGED_IN })
                                            resolve(result);
                                        }
                                    } else {
                                        reject(result);
                                    }
                                });
                            } catch (err) {
                                console.log(err);
                            }
                        }
                    });
                });
            }

        } catch (err) {
            debugLogger('login error: ', err);
            await logout();
            throw err.error;
        }

    }
    
    const logout = async () => {
        try {
            //REMOVE DATA
            await asyncStoreClear();
            //DISPATCH TO REDUCER
            dispatch({ type: LOGGED_OUT });
        } catch (err) {
            debugLogger('logout error: ', err);
            throw err.error;
        }
    };


    const value = useMemo(
        () => [
            authState,
            {
                login,
                logout,
            },
        ],
        [authState],
    );

    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    );
}

const useAuth = () => useContext(AuthContext);
export { AuthContext, useAuth };

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

