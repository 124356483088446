import React from "react";
import memoizeOne from "memoize-one";
import application_contants from "../../../constants/application_contant";
import { Button } from "reactstrap";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const TablesColumns = memoizeOne((handleEdit, handleDelete) => [
    {
        name: "Img",
        selector: "imgPath",
        sortable: false,
        grow: 0.5,
        cell: (row) => <div className="py-1">
            <img src={`${ApiConfig.URL}${row.bannerDesktop}`} alt="" className="width-64px img-padding" />
            <img src={`${ApiConfig.URL}${row.bannerMobile}`} alt="" className="width-64px img-padding" />
            </div>,
    },
    {
        name: "Section",
        selector: "name",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{row.sectionName}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        grow: 0.3,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{`${row.status === 1 ? 'Active' : 'InActive'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        grow:  0.4,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleDelete(row)}>
                    <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">Delete</Button>
                </div>
                <div onClick={() => handleEdit(row)} className="ml-12px">
                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">Edit</Button>
                </div>
            </div>
        ),
    },

]);




export default TablesColumns;
