import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
const Section2 = () => {
  return (
    <div>
      <Strip
        className="strip strip-no-padding me-two"
        containerType="container-fluid  px-0">
       
          <Row >
            <Col xs={12} md={6} className="me-red pt-5 pb-5 ps-md-5">
              <div className='me-tv1'>
              <iframe  src="https://www.youtube.com/embed/hIdi3uI7_NU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>

            </Col>
            <Col xs={12} md={6} className="me-blue pt-5 pb-5 ps-md-5">
            <div className='me-tv1'>
            <iframe  src="https://www.youtube.com/embed/9-Kal1LdYB4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              
            </div>
            </Col>
            <Col xs={12} md={6} className="me-purple pt-5 pb-5 ps-md-5">
            <div className='me-tv1'>
            <iframe  src="https://www.youtube.com/embed/3iFEfnx-Gak" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Col>
            <Col xs={12} md={6} className="me-yellow pt-5 pb-5 ps-md-5">
            <div className='me-tv1'>
            <iframe src="https://www.youtube.com/embed/qBZBeDVGNko" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Col>

          </Row>
        </Strip>
      
    </div>
  )
}

export default Section2