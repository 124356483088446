import React, { useState } from 'react';
import Axios from "axios";
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Loader from '../../../customComponent/Loader/Loader';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/ConfirmationMessage/ConfirmationMessage';
import Strip from '../../../customComponent/Strip/Strip';
import SupporterService from '../../../../api/services/SupporterService';
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';

const AddSupporter = (props) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [highInvestorList, setHighInvestorList] = useState([]);
    const [lowInvestorList, setLowInvestorList] = useState([]);
    const { toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setTitle('');
            setDescription('');
            setId('');
        } else {
            setTitle(props.item.title)
            setDescription(props.item.description);
            setId(props.item.id);
            setHighInvestorList(props.item.highInvestorsList ? JSON.parse(props.item.highInvestorsList) : []);
            setLowInvestorList(props.item.lowInvestorsList ? JSON.parse(props.item.lowInvestorsList) : [])
        }
    }, [isAdd, props]);

    const handleTitle = (event) => {
        setTitle(event.target.value)
    };


    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const getInvestorList = (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setHighInvestorList([...highInvestorList, ...item])
                }

            };
            reader.readAsDataURL(file);
        });
    }

    const getLowInvestorList = (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setLowInvestorList([...lowInvestorList, ...item])
                }

            };
            reader.readAsDataURL(file);
        });
    }

    const handleHighInvertorDelte = (index) => {
        highInvestorList.splice(index, 1);
        setHighInvestorList([...highInvestorList]);
    }

    const handleLowInvertorDelte = (index) => {
        lowInvestorList.splice(index, 1);
        setLowInvestorList([...lowInvestorList]);
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId');
        let finalFile = [];
        let finalArrayList = [];
        let finalListObj = {};
        let finalLowInvestorFile = [];
        let finalLowInvestorList = [];
        let finalLowInvestorListObj = {};

        if (highInvestorList.length > 0) {
            highInvestorList.map((it) => {
                if (it.file) {
                    finalFile.push(it.file);
                }
                finalListObj = {
                    imgPath: it.file ? `/investors/${it.file.name}` : it.imgPath,
                }
                finalArrayList.push(finalListObj);
            });
        }

        if (lowInvestorList.length > 0) {
            lowInvestorList.map((it) => {
                if (it.file) {
                    finalLowInvestorFile.push(it.file);
                }
                finalLowInvestorListObj = {
                    imgPath: it.file ? `/investors/${it.file.name}` : it.imgPath,
                }
                finalLowInvestorList.push(finalLowInvestorListObj);
            })
        }

        let data = {
            id: id,
            title: title,
            description: description,
            userId: userId,
            highInvestorsList: finalArrayList.length > 0 ? JSON.stringify(finalArrayList) : '[]',
            lowInvestorsList: finalLowInvestorList.length > 0 ? JSON.stringify(finalLowInvestorList) : '[]',
        };

        if (finalFile.length > 0 && finalLowInvestorFile.length > 0) {
            let allItems = [...finalFile, finalLowInvestorFile];
            UploadImage(allItems, data);
        } else if (finalFile.length > 0) {
            UploadImage(finalFile, data);
        } else if (finalLowInvestorFile.length > 0) {
            UploadImage(finalLowInvestorFile, data);
        } else {
            insertOrUpdate(data);
        }
    }

    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/investors/");
        await file.map((item) => {
            data.append("image", item);
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        if (data) {
            Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
                .then(async (res) => {
                    if (res.status === 200) {
                        try {
                            if (res.data.code === 0) {
                                await insertOrUpdate(insertOrUpdateData);
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${res.data.message}`);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((err) => {
                    console.log("We will be back soon.");
                    return err;
                });
        }
    };


    const insertOrUpdate = async (data) => {
        await SupporterService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-supporter"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="modal-header-container bg-crimson-red">
                        <Row className="no-margin align-items-center py-2">
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <p className="external-section--title fw-blod">
                                    {isAdd ? "ADD" : "UPDATE "}
                                </p>
                            </Col>
                            <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                className="cursor-pointer"
                                onClick={toggle}
                            >
                                <p className="external-section--title fw-blod align-right">close</p>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="m-auto">
                            <Row className="m-auto">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="pt-3 pb-5"
                                >
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            High Investors:
                                                       </Label>
                                                        <div
                                                            className={`position-relative`}
                                                        >
                                                            <div className="file-form-container">
                                                                <input type="file" value={""} placeholder="Upload File" multiple={true} accept="image/jpeg, image/jpg, image/png" className="form-control common-form-control" onChange={getInvestorList} />
                                                                <p className="file-text">{highInvestorList.length > 0 ? `${highInvestorList.length} uploaded` : 'upload Files'}</p>
                                                            </div>

                                                            <div className="pdf-thumbnail-container">
                                                                <CloudUploadIcon
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "2rem",
                                                                        borderRadius: 4,
                                                                        padding: 2,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Row>
                                                            {highInvestorList.map((it, index) => (
                                                                <Col xs={6} ms={6} md={6} lg={3} xl={3} kye={index}>
                                                                    <div className="position-relative">
                                                                        <img src={it.imgPath ? `${ApiConfig.URL}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                                                        <div className="trash-icon-container" onClick={() => handleHighInvertorDelte(index)}>
                                                                            <DeleteIcon style={{
                                                                                color: "#fff",
                                                                                fontSize: "1rem",

                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Low Investors:
                                                       </Label>
                                                        <div
                                                            className={`position-relative`}
                                                        >
                                                            <div className="file-form-container">
                                                                <input type="file" value={""} placeholder="Upload File" multiple={true} accept="image/jpeg, image/jpg, image/png" className="form-control common-form-control" onChange={getLowInvestorList} />
                                                                <p className="file-text">{lowInvestorList.length > 0 ? `${lowInvestorList.length} uploaded` : 'upload Files'}</p>
                                                            </div>

                                                            <div className="pdf-thumbnail-container">
                                                                <CloudUploadIcon
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "2rem",
                                                                        borderRadius: 4,
                                                                        padding: 2,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Row>
                                                            {lowInvestorList.map((it, index) => (
                                                                <Col xs={6} ms={6} md={6} lg={3} xl={3} kye={index} className="mb-2">
                                                                    <div className="position-relative">
                                                                        <img src={it.imgPath ? `${ApiConfig.URL}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                                                        <div className="trash-icon-container" onClick={() => handleLowInvertorDelte(index)}>
                                                                            <DeleteIcon style={{
                                                                                color: "#fff",
                                                                                fontSize: "1rem",

                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Title*:
                                                         </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Name"
                                                            id="name"
                                                            name="name"
                                                            className="form-control common-form-control"
                                                            value={title}
                                                            onChange={(event) => handleTitle(event)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mx-0 align-items-center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Label className="common-label text-left">
                                                            Description*:
                                                                </Label>
                                                        <Input
                                                            type="textarea"
                                                            placeholder="description"
                                                            id="description"
                                                            name="description"
                                                            className="form-control common-form-control"
                                                            value={description}
                                                            onChange={(event) => handleDescription(event)}
                                                            rows={40}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="no-margin">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={{ offset: 0, size: 6 }}
                                            lg={{ offset: 0, size: 6 }}
                                            xl={{ offset: 0, size: 6 }}
                                            className="m-auto mt-3"
                                        >
                                            <Row>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Button
                                                        className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                        onClick={toggle}
                                                    >
                                                        Cancel
                                                          </Button>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Button

                                                        className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                        onClick={() => handleSubmit()}
                                                        disabled={
                                                            title &&
                                                            description ? false : true
                                                        }
                                                    >
                                                        Submit
                                                         </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <div className="loader-position">
                            <Loader containerClassName="" />
                        </div>
                    ) : null}
                </Col>
            </Row>

        </Strip>
    )
}

export default AddSupporter;