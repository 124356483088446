import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ProjectService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateProject } = ApiConfig;

        const url = baseUrl + insertOrUpdateProject;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: `${data.title}`,
            projectUrl: `${data.projectUrl}`,
            description: `${data.description}`,            
            websiteUrl: `${data.websiteUrl}`,
            sectionType: `${data.sectionType}`,
            location:  `${data.location}`,
            videoLink: `${data.videoLink}`,
            logoPath:`${data.logoPath}`,
            bannerPath:`${data.bannerPath}`,
            locationMapPath: `${data.locationMapPath}`,
            supportedLogpath: `${data.supportedLogpath}`,
            points:`${data.points}`,
            status:`${data.status}`,
            projectCountViewer:`${data.projectCountViewer}`,
            projectLocationDetails:`${data.projectLocationDetails}`,
            supportedByName: `${data.supportedByName}`,
            backgroundColorCode: `${data.backgroundColorCode}`,
            borderColorCode: `${data.borderColorCode}`,
        });

       // console.log('params :',params);
        const projectServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_projectService",
            true
        );
        return projectServicePromise;
    },

    getProjectList: async () => {
        const { baseUrl, getProjectList } = ApiConfig;

        const url = baseUrl + getProjectList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const projectServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_project",
            true
        );
        return projectServicePromise;
    },

    enableDisableProject: async (data) => {
        const { baseUrl, enableOrDisableProject } = ApiConfig;

        const url = baseUrl + enableOrDisableProject;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: data.status,
        });

        const enableDisableProjectServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableProject",
            true
        );
        return enableDisableProjectServicePromise;
    },

};

export default ProjectService;
