import application_contants from "../../constants/application_contant";

const PoliciesData = [
    {
        index: 1,
        icon: application_contants.childImg,
        title: 'Child Protection Policy (CPP- Going to school)',
        linkText: 'Click to view the policy document',
        link:'policies/child-protection-policy',
    },
    {
        index: 2,
        icon: application_contants.partyBlowerImg,
        title: 'Whistle Blower Policy',
        linkText: 'Click to view the policy document',
        link:'policies/whistle-blower-policy',
    },
    {
        index: 3,
        icon: application_contants.compliantImg,
        title: 'Privacy Policy',
        linkText: 'Click to view the policy document',
        link:'policies/privacy-policy',
    },
]
export default PoliciesData;