import React from "react";
import memoizeOne from "memoize-one";
import { Button } from "reactstrap";

const TablesColumns = memoizeOne((handleEdit, handleDelete, handleSequentialOrder) => [
    {
        name: "Id",
        selector: "id",
        sortable: false,
        grow: 0.75,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => <div className="py-1 full-width" onClick={() => handleSequentialOrder(row)}><p className="p-small external-section--title title-black-pearl">{row._id}</p></div>,
    },
    {
        name: "Title",
        selector: "title",
        sortable: false,
        grow: 3,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{row.title}</p></div>,
    },
    {
        name: "Description",
        selector: "description",
        grow: 3,
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{`${row.description.length > 50 ? `${row.description.substr(0, 50)}...` : row.description}`}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{`${row.status === 1 ? 'Active' : 'InActive'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleDelete(row)}>
                    <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">Delete</Button>
                </div>
                <div onClick={() => handleEdit(row)} className="ml-12px">
                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">Edit</Button>
                </div>
            </div>
        ),
    },

]);




export default TablesColumns;
