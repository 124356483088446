import React from "react";
import {
    Row,
    Col,
    Label,
    FormGroup,
} from "reactstrap";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const GalleryImage = props => {
    const { galleryList, getThumbnail, handleGalleryImageDelete } = props;
    return (
        <Row className="mX-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="0">
                <FormGroup row className="mx-0 align-items-center">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label text-left">
                            Upload Gallery Images :
                           </Label>
                        <div>
                        </div>
                        <div
                            className={`position-relative`}
                        >
                            <div className="file-form-container">
                                <input type="file" value={""} placeholder="Upload File" multiple={true} accept="image/jpeg, image/jpg, image/png" className="form-control common-form-control" onChange={getThumbnail} />
                                <p className="file-text">{galleryList.length>0 ? `${galleryList.length} uploaded` : 'upload Files'}</p>
                            </div>

                            <div className="pdf-thumbnail-container">
                                <CloudUploadIcon
                                    style={{
                                        color: "#fff",
                                        fontSize: "2rem",
                                        borderRadius: 4,
                                        padding: 2,
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </FormGroup>
                {galleryList.length > 0 ?
                    <FormGroup row className="mx-0 align-items-center">
                        {galleryList.map((it, index) => (
                            <Col xs={6} sm={6} md={3} lg={3} xl={3} key={index} className="mb-2">
                                <div className="position-relative">
                                    <img src={it.imgPath ? `${ApiConfig.URL}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                    <div className="trash-icon-container" onClick={() => handleGalleryImageDelete(index)}>
                                        <DeleteIcon style={{
                                            color: "#fff",
                                            fontSize: "1rem",

                                        }} />
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </FormGroup>
                    : null}
            </Col>
        </Row>
    );
};

export default GalleryImage;