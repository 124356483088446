import React, { useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import BodyWrapper from "./BodyWrapper";
import SlideMenu from "./SlideMenu";
import Header from "./Header";
import application_contants from "../../constants/application_contant";


const DashboardLayout = ( props ) => {
  const [width] = useWindowSize()
  const [isToggle, setIsToggle] = useState(false);

  React.useEffect(() => {
    if (width <= application_contants.MOBILE_BREAKPOINT) {
      setIsToggle(true);
    }
  }, [width]);

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  return (
    <BodyWrapper>
      <div className={isToggle ? "menu-collapse" : "admin-panel"}>
        <Header />
        <div className="d-flex ht-80vh content-position">
          <div className="slide-menu"
            // className={`slide-menu animate__animated ${
            //   isToggle ? "animate__fadeOutLeft" : "animate__fadeInLeft"
            // }`}
          >
            <div>
              <SlideMenu />
            </div>
            <div>
              <div className="slide-collapse" onClick={() => handleToggle()}>
                <div className="trapezoid">
                  <div className="icon_rotate">
                    <MenuOpenIcon
                      style={{ color: "#fff", fontSize: "1.5rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="ht-80vh">
              <main className="width_95 m-auto bg-white br-4px">
                {props.children}
              </main>
            </div>
            <div>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
        {isToggle ? (
          <div className="slide-open" onClick={() => handleToggle()}>
            <div className="trapezoid">
              <div className="icon_rotate">
                <MenuOpenIcon style={{ color: "#fff", fontSize: "1.5rem" }} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </BodyWrapper>
  );
};

export default DashboardLayout;
