const LocationInformation = [
  {
    id: 1,
    icon: "",
    name: "Delhi",
    address:
      "4/23, 4th floor, F block, Birbal Road, Jangpura Extension, New Delhi 110014",
  },
  {
    id: 2,
    icon: "",
    name: "Mumbai",
    // address: 'Bungalow No. 116, Aram Nagar Part 2 Back Road, Machlimaar Nagar Road, Opp Gulshan Colony, Versova, Andheri West, Mumbai 400061',
    address:
      "Bungalow No. 23, Aram Nagar Part 2, Near Ganesh Temple, J.P. Road, Versova, Andheri West, Mumbai 400061",
  },
  {
    id: 3,
    icon: "",
    name: "Patna",
    address: `Shashwat Vihar Colony, Beside ST Karen's High School, Gola Road, Danapur Nizamat, Patna 801503`,
  },

  {
    id: 4,
    icon: "",
    name: "Bengaluru",
    address:
      "No. 46, 29th Cross Ejipura Main Road, off. Koramangala Intermediate Ring Road, Viveknagar P.O., Bangalore – 560047.",
  },
];

export default LocationInformation;
