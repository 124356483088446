import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from '../context/AuthProvider';
import Navigation from "../customComponent/Navigation/Navigation";
import './App.scss';

const App = (props) => {
  return (
    <div className="App">
      <AuthProvider {...props}>
        <BrowserRouter>
            <Navigation/>
          </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
