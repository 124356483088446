import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { useAuth } from "../../context/AuthProvider";
import application_contants from "../../constants/application_contant";

const Header = (props) => {
    const [, { logout }] = useAuth();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleLogout = async () => {
        await logout();
        props.history.push("/");
    };

    return (
        <div className="d-flex admin-header-container">
            <div>
                <div className="logo-position1">
                    <div className="d-flex align-items-center">
                        <div>
                            <Link to={'/banner-list'}>
                                <img src={application_contants.logoImg} className="logo-container" alt="goingtoSchool" />
                            </Link>
                        </div>
                        <div className="px-2">
                            <h2 className="h2-small external-title title-crimson-red logo-title--small">Going to School</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="btn-transparent"
                >
                    <div className="d-flex">
                        <AccountCircleOutlinedIcon
                            style={{ fontSize: '2rem', color: "#304350" }}
                        />
                        <div className="pr-2 pt-1">
                            <p className="mb-0 p-small external-section--title title-bright-grey align-left">
                                Welcome
                           </p>
                            <p className="mb-0 p-small external-section--title title-bright-grey align-left">
                                Admin
                           </p>
                        </div>
                    </div>
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={(event) => handleLogout(event)}>
                                            <p className="mb-0 p-medium external-section--title title-bright-grey align-left">
                                                Logout
                                         </p>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
};

export default withRouter(Header);
