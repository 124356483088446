import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const SectionService = {
    getList: async () => {
        const { baseUrl, getSectionTypeList } = ApiConfig;

        const url = baseUrl + getSectionTypeList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const sectionServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_section",
            true
        );
        return sectionServicePromise;
    },


};

export default SectionService;
