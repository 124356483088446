import React from 'react';
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { useWindowSize } from "@react-hook/window-size";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import { Row, Col } from 'reactstrap';
import application_contants from '../../../constants/application_contant';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowForwardIosOutlined style={{ color: `#000`, fontSize: '2rem' }} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowBackIosOutlined style={{ color: `#000`, fontSize: '2rem' }} />
        </div>
    );
}


const VideoLists = (props) => {
     return (
        <div className="player-wrapper player-border">
            <ReactPlayer
                className="react-player"
                url={`${props.item}`}
                width="100%"
                height="100%"
                playing={false}
                controls={true}
                volume={0.5}
                config={{
                    youtube: {
                        playerVars: { showinfo: 1 },
                    },
                    attributes: {
                        controlsList: "download",
                        poster: `${props.item}`,
                    },
                }}
            />
        </div>
    )
}

const VideoList = (props) => {
    const [width] = useWindowSize();
    const settings = {
        dots: false,
        infinite: true,
        loop: true,
        speed: 500,
        slidesToShow: width >= 1200 ? 4 : (width >= 1200 && width <= 1023) ? 3 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const settingsNotLoop = {
        dots: false,
        infinite: width >= 1200 ? false : (width >= 1200 && width <= 1023) ? true : 1,
        loop: width >= 1200 ? false : (width >= 1200 && width <= 1023) ? true : 1,
        speed: 500,
        slidesToShow: width >= 1200 ? 3 : (width >= 1200 && width <= 1023) ? 2 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {props.data.length > 3 ?
                    <Slider {...settings}>
                        {props.data.length > 0 && props.data.map((item, index) => (
                            <Row key={index} className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                    <VideoLists item={item} index={index} />
                                </Col>
                            </Row>
                        ))}
                    </Slider> :
                    <div>
                        <Slider {...settingsNotLoop}>
                            {props.data.length > 0 && props.data.map((item, index) => (
                                <Row key={index} className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                        <VideoLists item={item} index={index} />
                                    </Col>
                                </Row>
                            ))}
                        </Slider>
                    </div>}
            </Col>
        </Row>
    )
}


export default VideoList;