import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import SEOComponent from '../../customComponent/SEO/SEOComponent';

const ChildProtectionPolicy = () => {
    return (
        <ExternalLayout>
              <SEOComponent title="Going to School" subtitle="Child Protection Policy" description=""
             />
            <RenderPage
                id="policies-page"
                className="render-page bg-zircon-grey"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-policies"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-col-no--padding">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto mobile-col-no--padding">
                                    <h6 className="h6-small external-title pt-5 pb-4"> <Link to={'/policies'} className="link--underline--none">{`Our Policies >>`}</Link>
                                        <font className="pl-16px">Child Protection Policy (CPP- Going to school)</font>
                                    </h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto pb-5 mobile-col-no--padding">
                                    <Card className="card-container">
                                        <CardBody className="card-body--container">
                                            <Row className="mx-0">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="policies-container">
                                                        <img src={application_contants.childImg} alt="" />
                                                    </div>
                                                    <h6 className="h6-small external-title pb-3 card-title--position">Child Protection Policy <br />(CPP- Going to school)</h6>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Going to School is committed to protecting the safety, health, well-being and
                                                        mental health of children and young people in our programs at school, in the
                                                        field, when learning skills through content and/or co-creating content.
                                                  </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        In this commitment, Going to School has adopted the following Child Protection Policy
                                                        (CPP) requiring all Going to School representatives, full-time, part-time, in the office,
                                                        field of work with internships, entrepreneurs, schools to conduct themselves appropriately with children and young people who are part of Going to school programs and to
                                                        take action on colleagues who in your presence, do not adhere to this policy and guidelines by reporting the experience.
                                                  </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Children - Who is a Child, a definition for Going to School Programs
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        ‘Child’ includes all persons within the age range of conception until the age of 18 years.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        For Going to School’s CPP we define children as young people of any age between
                                                        0-18 years who participate and/or are a part of Going to School Programs.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Children are more vulnerable than adults by the fact of being children, dependent on
                                                        others for the realization of their rights.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Going to School states, believes and will uphold that it is imperative for all stakeholders
                                                    of Going to School, that is, full-time employees, part-time employees/consultants, vendors, contractors, trustees and Going to School program participants of any kind, to
                                                    practice the highest standards of child protection.
                                                </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Going to School interventions include skills delivery at school, outside of school in collaboration with entrepreneurs in internships, research, baseline, endline and the co-creation of video and television programs.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The nature of Going to School’s work implies critical interdependence within a complex
                                                        web of relationships that involve children and young people: their schools, teachers,
                                                        parents, local government and freelance research, content teams that Going to School
                                                        brings together to co-deliver and co-create content with young people.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The successful protection of children in the context of Going to School programs is dependent on honesty, integrity and commitment of each member of the Going to School
                                                        team/network and is upheld with the consistent vigilance and enforcement in good faith
                                                        of this policy and it’s implementation with the shared commitment to protect children.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Policy Scope, Going to School’s Child Protection Policy (CPP)
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Going to School: To include all staff, trustees, consultants, interns, volunteers.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Going to School Project Partners: To include directors, coordinators, team members, consultants, interns, volunteers.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Associates of Going to School: To include all vendors, sponsors, volunteers, funding agencies, suppliers of Going to School.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Associates of Going to School Project Partners: To include all vendors, sponsors,
                                                        volunteers, funding agencies, suppliers at the Going to School project of the partner
                                                        organization.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Review of the Policy (Current Review is Effective February 2021)
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        This Policy will be reviewed every three years by Going to School. Upon review,
                                                        changes in the Policy will be intimated to all organizations and persons who come within
                                                        the scope of the policy and compliance with these changes would also be mandatory.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Guiding Principles
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Children and Young People are citizens and rights-holders; the dignity, liberty and
                                                            freedom of every child must be respected. The best interests of children must remain in focus in all matters that affect or concern the child.
                                                     </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Children’s identity must be safeguarded/protected; their data be it baseline, endline
                                                            and/or way to reach them must be safeguarded. A child’s right to privacy and confidentiality must be respected within an overall consciousness and awareness of child
                                                            protection requirements.
                                                     </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Children’s permission must be sought and received (signed by a guardian) before
                                                            appearing in a video or TV program.
                                                     </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Children have the right to freedom of expression and must be given time to be
                                                            heard, no matter what the situation or pressing deadline.
                                                     </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Zero tolerance of any form of abuse of any child whether direct or indirect, verbal,
                                                            physical, suggested, inferred or in jest. Supported i.e. not reporting another colleague for their flouting of the CPP is considered inaction AND implicit collaboration
                                                     </p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Objectives
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        To ensure the practice of the highest levels of child protection standards in Going to
                                                        School Programs in all locations. To ensure a well-defined and practiced reporting
                                                        mechanism for child protection violations and time bound redressal system. To ensure
                                                        assessment and management of child protection in Going to School projects at school,
                                                        outside of school, on location with entrepreneurs in internships. To ensure that Going to
                                                        School’s CPP Standards are upheld by all associates of Going to School Programs. To
                                                        create consciousness and awareness about child protection through an ongoing dialogue internally through inductions and quarterly reviews so all colleagues are up to
                                                        date on what CP means in the context of Going to School Programs and are up to date
                                                        on updated policies and safety actions instituted for children.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Policy Statement
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Going to School, an creative not for profit educational organization is committed to the
                                                        protection and wellbeing of children in its projects, programs and communities. Going to
                                                        School has adopted this Child Protection Policy to ensure practice of the highest standards of child protection amongst all stakeholders and project participants. The policy
                                                        creates a mechanism to report child protection violation and ensure prompt redressal.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        This policy calls for mandatory reporting of all child protection violations. All
                                                        members within the scope of this policy must report as they witness, suspect or
                                                        come to know of any form of child protection violation.
                                                   </p>
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Definitions
                                                   </p>
                                                    <p className="external-title title-dune-grey pb-2">
                                                        + Child Protection
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child Protection is protecting children from or against any perceived or real danger/risk
                                                        to their life, their personhood and childhood during the delivery of Going to School programs and educational projects.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child protection is reducing the vulnerability of the child to any kind of harm and in
                                                        harmful situations. It is about protecting children against social, material, physical, psychological and emotional insecurity and distress
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child Protection refers to protection from violence, exploitation, abuse and neglect. It is
                                                        integrally linked to every other right of the child. Every child has a right to protection.
                                                        This not only includes children who are in difficult circumstances and those who have
                                                        suffered violence, abuse and exploitation, but also those who are not in any of these
                                                        adverse situations and yet, need to be protected to maintain and/or increase the existing environment of safety and positive development.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        + Child Abuse
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child abuse constitutes all forms of physical and/or emotional ill-treatment, sexual
                                                        abuse, neglect or negligent treatment or commercial or other exploitation, resulting in
                                                        actual or potential harm to the child’s health, survival, education, participation in the
                                                        programs and can take place anywhere, at home, on the way to school, at school or in a
                                                        field project outdoors, while making a television show. a. Physical abuse: The intentional use of physical force against a child that results in or has a high likelihood of resulting in harm to the child’s health, survival, development or dignity. This includes hit-ting, beating, kicking, shaking, biting, strangling, scalding, burning, poisoning and suffocating. b. Emotional abuse: Emotional abuse includes the failure to provide a developmentally appropriate, supportive environment, so that the child can develop a stable
                                                        and full range of emotional and social competencies commensurate with his or her personal potential and in the context of the society in which the child dwells. Acts include
                                                        restriction of movement, patterns of belittling, denigrating, scapegoating, threatening,
                                                        scaring, discriminating, ridiculing or other non-physical forms of hostile or rejecting
                                                        treatment. c. Sexual abuse: Sexual abuse is an act or behavior which constitutes a
                                                        sexual offense under the Protection of Children from Sexual Offenses Act, 2012, as
                                                        amended from time to time. Such sexual offenses include penetrative sexual assault
                                                        (including rape), non-penetrative sexual assault (including touching the private parts of
                                                        a Child), sexual harassment (including stalking a Child, showing a Child pornography
                                                        and making a Child exhibit his/her body) and using a Child for pornographic purposes
                                                        (including storing of pornographic material involving a Child for commercial purposes).
                                                        d. An abetment of and an attempt to commit a sexual offense under the Protection
                                                        of Children from Sexual Offenses Act, 2012 are also considered to be offenses.
                                                        Certain offenses under the Protection of Children from Sexual Offenses Act, 2012 are
                                                        considered to be aggravated offenses and are subject to stringent punishments. For instance, sexual assault committed by persons in a position of trust or authority in relation
                                                        to the Child (including relatives of the Child, persons managing or working in an educational institution, police officers or public servants) are considered to be aggravated offenses.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Scope of Application of the Child Protection Policy (CPP)
                                                   </p>
                                                    <p className="external-title title-dune-grey pb-2">
                                                        This CPP is applicable to all part time and full time employees of Going to School
                                                        and any other person who has been bound to the Child Protection Policy as per
                                                        their contractual agreement with Going to School.
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            This policy will be shared and explained to colleagues, full-time, part-time, freelance,
                                                            on induction into each program, project and in the context of that particular project
                                                            i.e. a children’s television program, internship in the field.
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            The CPP will be re-explored each quarter with colleagues in all locations in the context of their current and changing programs with perceived challenges and proposed
                                                            measures to be taken.
                                                   </p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Going to School’s Measures to Prevent Child Abuse and Uphold Child Protection
                                                        in all Going to School Programs
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        When joining Going to School, the induction for all new colleagues involves three
                                                        days of induction, one of which goes into detail on Child Protection and Sexual Harassment and what it means in the context of Going to School’s Programs.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child Protection inductions take place for full-time colleagues on joining the organization with subsequent quarterly reviews in the context of the changing programs
                                                        (as stated above)
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child Protection Inductions take place for part-time or freelance colleagues based
                                                        on the nature of the program i.e. school delivery and/or content creation (TV show).
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Going to School ensures that all colleagues sign a copy of the Child Protection Policy which is included in and referred to in all employment contracts (full-time, parttime, consultant).
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        When filming or co-creating content with young people, young people are always
                                                        together in groups of four (minimum) with two or more Going to School colleagues,
                                                        Going to School colleagues are never alone with a child or young person.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Specifically for children’s TV shows, it would always be two girl reporters and one
                                                        boy, a team of three minimum, with a film-team that is full-time and part-time and
                                                        has completed child protection induction, training and have signed contracts including Going to School’s Child Protection Policy. Women colleagues would be present
                                                        at all times.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Child Protection Violation
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Child Protection Violation in the context of this policy amounts to:
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Any act or behavior that puts a child at risk of abuse.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Any act of behavior that could potentially increase the risk of abuse.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        A failure to act in a situation where a child is being abused.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Failure to follow the code of conduct or any other prescribed protocol for Child Protection within the project you are working on without suitable justification.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Components of the Child Protection Policy
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        There are three components comprising the CPP which are referred to below and can
                                                        be found in detail in the corresponding policy in the Operations Manual and/or on www.goingtoschool.com
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Code of conduct</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">WhistleBlower policy</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Operational Guidelines</p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Code of Conduct
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Going to School recognizes the need for honesty and integrity and respect for the child
                                                        to reflect both in decisions of the organization as well as in the conduct of its personnel.
                                                        As a result, this code of conduct has been articulated for all staff and work associates,
                                                        including permanent and full-time, as well as part-time staff, consultants, and volunteers
                                                        at Going to School and the Going to School project partners.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Going to School Teams Must
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Be conscious of and respect the privacy and dignity of each and every child
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            That is to protect the identity of children in internal and external communications,
                                                            keeping their information and data, safe. Protect the child by not disclosing the identity of the child outside of the team and duty-bearers involved with the project. Uphold the privacy of the child by not disclosing the identity of the child to the media
                                                            without the adult or guardian’s and child’s written consent.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Never be alone with a child on a Going to School project, program; always be visible
                                                            and never in a secluded place, always have another colleague present and interact
                                                            with children in teams of four children. Ensure that there are staff members of the
                                                            same sex as the child/children when escorting the child between locations and also
                                                            when taking care of the child/children at any location, as always, in teams of four
                                                            children and two GTS colleagues with the teams of colleagues changing.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Listen, be open, encourage children to express their issues and concerns to Going
                                                            to School team members in any project location or time. Respect and encourage
                                                            children’s voices and views and take notes to share with Going to School leadership
                                                            you feel that the issue wasn’t effectively dealt with on location and suggestions for
                                                            inclusion in GTS training on CP and updating of this policy.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Be conscious of the possible need to support or adopt a differential response when
                                                            dealing with children who may suffer from trauma or a challenge to ability, making
                                                            them more vulnerable than other children in the same situation.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Inform children about procedure of interventions being taken with respect to them, in
                                                            the context of their lives in this project at school, in internships, making videos, TV
                                                            shows.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Ensure you have a signed agreement from the guardian/teacher for your interaction
                                                            with children and/or a Going to School Project MOU covers, legislates, protects children in your interaction with them.
                                                     </p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Going to School Teams Must Never
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Be alone with a child or allow interaction with a child in a closed environment without
                                                            supervision of the Going to School person in-charge.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Touch, hug, or caress a child.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Take children to the residence of the team members / coordinators / directors for the
                                                            night or sleep in the same room as a child and/or visit a child’s room at night alone -
                                                            always in teams of two GTS colleagues, interacting with teams of four or more children.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Do things for children of a personal nature that they can do themselves, such as use
                                                            the toilet, bathe or change clothes.
 </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Engage children in personal work or employ children at work or at home.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Use language or expressions that are inappropriate for a child in any context even if
                                                            it’s your own concern/life story.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Use corporal punishment or tolerate corporal punishment by GTS teams or adults
                                                            you observe inflicting punishment on children in your care because of a GTS program.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Make any physical gestures in a manner that appear to be inappropriate or have a
                                                            sexual context or association.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Develop any relationship with any child that is or could be assumed to be exploitative or abusive.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Colleagues must never Whatsapp or directly communicate digitally, verbally by
                                                            phone, smart phone, with a child for a purpose other than direct coordination for the
                                                            GTS project; it a colleague receives said communication from a child then it is her/
                                                            his responsibility to immediately report it to the Going to School leadership team to
                                                            document the interaction and take action immediately
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Discriminate against children on any basis such as, for example, community, color,
                                                            language, disabilities, physical features, sexual orientation, caste, class, religion,
                                                            politics. The Constitution of India is itself the anti-discrimination law in the country
                                                            both for individuals and for groups of people who find themselves discriminated on
                                                            grounds only of religion, race, caste, sex, place of birth or any of them [Article 15(1)].
                                                            Colleagues must never stigmatize or humiliate a child; in any manner that puts children at risk; give gifts directly to children; patronize any service that employs children; take any photograph/video that would or could potentially violate the child’s
                                                            dignity, or privacy in any way and/or share it in any domain, online, Whatsapp, email
                                                            or print.
                                                    </p>
                                                    </div>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        There are times, on location, in rural India when only one team/staff member is present
                                                        coordinating a program with children, in such case, the team member should ensure the
                                                        children are outside/outdoors/visible to other adults, and pause the program delivery until the GTS team member can locate another team member, female or male as appropriate depending on the sex and age of the children involved, to come to the location
                                                        and also be present as co-caregiver (skills delivery, content co-creation) until the situation is satisfactorily resolved. It is always desirable to have one woman and one man
                                                        co-media/content creator for the period of time that it is necessary for a child/children to
                                                        be in their charge.
                                                    </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Redressal Mechanisms
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Any person, either employed by Going to School, or a participant, child, young person,
                                                        external observer, can report a violation of Child Protection to the Child Protection
                                                        Committee direct line (m) 9810621876 and/or emailing lisa@goingtoschool.com
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Children, young people, can report to any GTS team member and/or to the contact details above.</p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        GTS team members who receive complaints are morally obligated to immediately report
                                                        the violation to the Child Protection Committee. The GTS team member must report the
                                                        incident immediately (within 24 hours) to the Committee and number, email above.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Child Protection Committee
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Constitution of the Child Projection Committee: An Internal Complaints Committee has
                                                        been formed to redress any complaints of Child Protection Violations. The committee comprises of:
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Presiding Officer, a woman employee at a senior level in the organization or workplace.
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Two members from amongst employees, committed to the cause of children
                                                            and young people and or having legal knowledge, a colleague from a Management Level as well as when the complaint is received, a colleague nominated from
                                                            the same project, state, location as Going to School programs run in Maharashtra,
                                                            Karnataka, West Bengal, Delhi and Bihar, the board supports local representation
                                                            who can best represent the context and local experience.
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            One external member from non-governmental organization, familiar with the
                                                            issues relating to Child Protection
                                                   </p>
                                                    </div>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        At least one half of the total members is women.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The members of this committee are mentioned in Annex 1.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The committee is responsible for: Receiving complaints of violation of the Child Protection Policy; initiating and conducting inquiry as per the established procedure; submitting findings and recommendations of the inquiry; coordinating with the employer in implementing appropriate action; maintaining strict confidentiality throughout the process
                                                        as per established guidelines.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Investigating a complaint
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The committee will promptly investigate any allegation made in a free and fair manner.
                                                        This investigation may include private interviews with the person alleged to have committed the offense and witnesses, if any. All notes from the investigation are kept strictly confidential.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Whistleblower policy
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        If you want to inform the committee about any incident of Child Protection Violation within Going to School Programs that you are aware of, you can do so by sharing the details
                                                        of the employee/colleague responsible and the incident you observed or heard of, or
                                                        were reported to by a child to the phone number, email above/or by post.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Reporting and Redressal of Child Abuse Complaints/Child Protection Violations
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Scenarios under which the Child Protection Policy can be violated.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Where the alleged victim is any child in a Going to School project/program and the alleged offender is a Going to School Team Member.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        WhistleBlower Policy in the Context of Child Protection
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The Whistleblower Policy is framed to ensure that there is an appropriate mechanism in
                                                        place to ensure prompt and fair action in the case of a Child Protection Violation. The
                                                        WhistleBlower Policy for CPP Violations is for everyone in Going to School teams, fulltime, part-time, participants and/or programs and external observers.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Violations in CPP can be reported directly, as above, to the mobile number or email and/or
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Report the matter in writing, directly to the CFO and Executive Director of the organization who will launch the Child Protection Committee investigation
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Report the matter to the team leader/head of the department in writing cc’ing the
                                                        Management Team above who will in turn launch the Child Protection Committee investigation
                                                    </p>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Reporting of child protection violations within the organization is mandatory - any
                                                        person coming across a Child Protection violation must report it.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        While the matter is under investigation the individual who is suspected of having
                                                        committed a child protection violation must be removed from direct contact with children.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The issue is to be addressed and brought to closure within 14 working days of having
                                                        been reported.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The responsibility for this lies with the Child Protection Committee to move swiftly, engage third party evaluators and/or if the abuse falls under IPC or any other relevant
                                                        law, an First Information Report (FIR) must be filed with the local police by GTS.
                                                   </p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Implementation of the Policy:
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The overall implementation of the policy would be the responsibility of the Head of HR &
                                                        Administration. The Executive Director and Management Team mentioned in the
                                                        Whistleblower Policy are constituted as per the Operations Manual of GTS. The Partnership Agreement between GTS and GTS project partners necessarily includes acceptance of this GTS Child Protection Policy and the Operational Guidelines.
                                                        All agreements/ contracts/ terms of reference between GTS and its associates would
                                                        include acceptance of relevant clauses of the GTS child protection policy and Operational Guidelines detailed ahead in this policy document.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px"></p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px"></p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px"></p>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Operational Guidelines
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        This CPP articulates Going to School’s aspiration and commitment to practicing the
                                                        highest level of Child Protection across our operations network. GTS and the GTS partners, colleagues, full-time, part-time, partner organizations, have a proactive stance in
                                                        committing to operational guidelines that be incorporated into all processes and functions of Going to School projects, programs and work.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The content of these operational guidelines has a four-part structure as set out below
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Human Resource Management</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Protection norms for GTS Spaces</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Participation of Children in GTS programs</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Representation of children in the media</p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Human Resource Management
                                                   </p>
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Recruitment
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        All employees having direct or indirect contact with children would face a thorough and
                                                        standardized recruitment process.
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Application received online, by email, through a job portal
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Interview face to face, or by Zoom/Video call.
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            During the interview process: Ask relevant questions to assess the candidate’s attitude and sensibilities regarding child protection, their understanding of Child Protection, Sexual Harassment, what is means in the law and per the guidelines of the
                                                            program, project. Commitment to complete induction and as employment terms extend, quarterly reviews.
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            There should be resolution of issues like employment gap, frequent changes of employment or reasons for leaving employment (if sudden).
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            There should be at least two references that each applicant should provide along
                                                            with the curriculum vitae (CV).
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            For each candidate a thorough reference check should be carried out with the place
                                                            of previous employment or the academic institute attended by the candidate. There
                                                            should be a minimum of two reference checks for each candidate. Specific query
                                                            regarding conduct of the candidate vis-a-vis children should be made.
                                                        </p>
                                                    </div>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        At the time of employment, the organization will need the following from each employee,
                                                        full-time or part-time
                                                   </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Resume (CV)
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Two passport sized photos
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Cancelled Cheque
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Copy of PAN Card
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Copy of Aadhar Card used by Unique Identification Authority of India
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Relieving letter from previous employer and/or Experience Letter
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Last three months salary slips from previous employer
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Educational Certificates
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Date of Birth (High School Certificate)
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            PF Number if any
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Proof of Identity, Proof of Address, Proof of Date Documents
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Copy fo Elector’s Photo IdentityCard
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Copy of Driving License
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Copy of Passport
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Contract
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                            Offer Letter
                                                        </p>
                                                    </div>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Gong to School will commence with a criminal background check and a police report on
                                                        receipt of the above information.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        On joining Going to School
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        + A duly signed Appointment letter/Contract will be issued to the employee mentioning
                                                        the conditions of employment.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        + All new team members will be provided with a copy of the GTS Child Protection Policy and the GTS Operations Manual and asked to read it and then to sign an undertaking acknowledging receipt of the CPP, and stating their agreement and acceptance to
                                                        all components of the Policy.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        + Within the first two weeks of employment, consultant or full-time, new team members
                                                        will have to complete a two day induction into all operations of Going to School, covering, in-depth Child Protection, Sexual Harassment and financially, fraud and/or misuse
                                                        of data.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        On joining, Going to School will maintain the personnel files of teach new team member
                                                        which will include everything above as well as documentation of when the CPP induction was completed and where.
                                                    </p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">CV of the staff</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Photograph of the staff recruited</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Name and contact details of referees</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Outcome of the reference check duly signed by the signing authority</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Copy of appointment letter given to the employee and CPP undertaking signed by the
                                                        employee
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">Identity and proof of residence</p>
                                                    </div>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        CVs of all GTS employees with their photographs and appointment letters should be
                                                        sent to GTS Delhi office within two weeks of this notification.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        As and when an employee leaves the project or joins the project, there should be a written communication to the GTS program director.
                                                    </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        The CV of the new employee with her photograph and appointment letter should be
                                                        sent to GTS program director within a week of employment.
                                                    </p>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">
                                                        Annex 1, Child Protection Committee
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Presiding Officer, a woman employee at a senior level in the organization or workplace: Lisa Heydlauff, Founder, CEO
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        Two members from amongst employees, committed to the cause of children
                                                        and or having legal knowledge, a colleague from a Management Level, Anil
                                                        Sadarangani, Editor-in-Chief or Management Level Leader from the Program, well
                                                        as when the complaint is received, a colleague nominated from the same project,
                                                        state, location as Going to School programs run in Maharashtra, Karnataka, West
                                                        Bengal, Delhi and Bihar, the board supports local representation who can best represent the context and local experience.
                                                   </p>
                                                    <p className="p-small external-section--title title-bright-grey lh-30px pb-12px">
                                                        One external member from non-governmental organization, familiar with the
                                                        issues relating to Child Protection. Vandana Goyal, ex-Akansha CEO.
                                                   </p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
}

export default ChildProtectionPolicy;