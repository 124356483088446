import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
// import Fade from '@material-ui/core/Fade';

import Zoom from '@material-ui/core/Zoom';

import Backdrop from '@material-ui/core/Backdrop';
import { Row, Col, Button } from 'reactstrap';
import Strip from '../../Strip/Strip';
import VideoPlay from '../../VideoPlay/VideoPlay';
import DelhiV from '../../../assets/Homepage/Modal/delhiv.png'
import BengluV from '../../../assets/Homepage/Modal/bengaluruv.png'
import KolV from '../../../assets/Homepage/Modal/kolkata.png'
import MumbaiV from '../../../assets/Homepage/Modal/mumbaiv.png'
import { useWindowSize } from "@react-hook/window-size";
import Cross from "../../../assets/modelcross.png";
const ProjectModel = ({ item, toggle, open, close }) => {
    const [width] = useWindowSize();
    const [viewData, setViewData] = useState(false);
    const [current, setCurrent] = useState(false);
    const [style,setStyle]=useState()
    var strlen = 0;

    var prelen = 0;

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
           
    },
        paper: {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        padding: 18,
        border: 0,
        '&:focus': {
            outline: 'none',
        },
       
    },
    }));
const classes = useStyles();
const playVideo = (item) => {
    setViewData(true);
    setCurrent(item)
}
const closing =()=>{
    setStyle("zoom-out")
    setTimeout(()=>{close(false)},200)
}
return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${classes.modal} ${style}`}
        open={open}
         onClose={closing}
         closeAfterTransition
         
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        style={{ overflow: 'scroll', }}
    >
  
        <Zoom in={open}   style={{ transitionDelay: open ? '400ms' : '0' }} >
            <div className={`home-model `}>
                <Strip className={`container-fluid ${item.bg}`}>
                    <Strip className="container">

                        <Row >
                            {width <= 425 ? <>
                                <Col xs={12} className="text-end mt-2">
                                    <img src={Cross} className="cross-section" onClick={() => close(false)} />
                                </Col>
                            </> : null

                            }
                            <Col xs={12} md={12} lg={12} className="model-upper">
                                <div className='model-banner'>
                                    <img src={item.banner} />
                                </div>
                                {item.schooltree ? <div className='model-tree'>
                                    <img src={item.schooltree} />
                                </div> : null}
                            </Col>

                            <Col xs={12} md={12}>
                                <div className='model-text'>
                                    <p className='text-start mb-0'>
                                        <span className='me-2'>
                                            {item.para1}
                                        </span>
                                        {item.para2 ? item.para2 : null}
                                    </p>
                                    {
                                        item.paraimg ?
                                            <div className='mt-3'>
                                                <img src={item.paraimg} />
                                            </div> : null
                                    }
                                    {
                                        item.para3 ?
                                            <p className='text-start model-para3 mt-3 mb-0'>
                                                {item.para3}
                                            </p> : null
                                    }
                                    {
                                        item.para4 ?
                                            <p className='text-start model-para3 mt-3 mb-0'>
                                                {item.para4}
                                            </p> : null
                                    }
                                </div>
                            </Col>
                            {item.tabs &&
                                <Col xs={12} className="  d-flex tabs-data">
                                    {item.tabs.map((data, index) => (
                                        <>
                                            <div className=' bg-tabs mt-md-2 mb-2 me-2 mt-1' key={index}>
                                                <p className='mb-0'>{data}</p>
                                            </div>
                                        </>
                                    ))}
                                </Col>}
                            {item.tv1 ? <>
                                <Col xs={6} md={6} className="mt-3">
                                    <div className='tv-1'>
                                        <img src={item.tv1} />
                                        <div className='mum'>
                                            {/* https://youtu.be/uKj9K6iSCgk */}
                                            {viewData === true && current === 1 ? <VideoPlay videoLink="https://youtu.be/uKj9K6iSCgk" playing={true} padding={true} /> : <img src={MumbaiV} onClick={() => playVideo(1)} />}
                                        </div>

                                    </div>

                                </Col>
                                <Col xs={6} md={6} className="mt-3">
                                    <div className='tv-2'>
                                        <img src={item.tv2} />
                                        <div className='mum'>

                                            {viewData === true && current === 2 ? <VideoPlay videoLink=" https://youtu.be/9-Kal1LdYB4" playing={true} padding={true} /> : <img src={BengluV} onClick={() => playVideo(2)} />}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} md={6} className="mt-3">
                                    <div className='tv-3'>
                                        <img src={item.tv3} />
                                        <div className='mum'>

                                            {viewData === true && current === 3 ? <VideoPlay videoLink="https://youtu.be/3iFEfnx-Gak" playing={true} padding={true} /> : <img src={DelhiV} onClick={() => playVideo(3)} />}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} md={6} className="mt-3">
                                    <div className='tv-4'>
                                        <img src={item.tv4} />
                                        <div className='mum'>
                                            {viewData === true && current === 4 ? <VideoPlay videoLink="https://youtu.be/qBZBeDVGNko" playing={true} padding={true} /> : <img src={KolV} onClick={() => playVideo(4)} />}
                                        </div>
                                    </div>
                                </Col>

                            </>
                                : null

                            }
                            <Col xs={12} md={12}>
                                <div className='modelImg1'>
                                    <img src={item.img1} />
                                </div>
                            </Col>
                            {/* <Col xs={6} md={6}>
                                    <div className='modelImg1'>
                                        <img src={item.img2} />
                                    </div>
                                </Col> */}
                            {item.img2 && <Col xs={12}>
                                <div className='modeldate pt-2 pb-2'>
                                    <img src={item.img2} />
                                </div>
                            </Col>}
                            <Col xs={12}>
                                <div className='modellogo pt-2 '>
                                    <img src={item.img3} />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='modelbtn pt-2 pb-4'>
                                    <a href={`/project/${item.projectUrl}`}>  <img src={item.btn} /></a>
                                </div>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>

            </div>
        </Zoom>

    </Modal>
)
}

export default ProjectModel