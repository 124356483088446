import application_contants from "../../constants/application_contant";

const SocialInfo = [
    {
        id: 1,
        icon: application_contants.instagramImg,
        // link: 'https://www.instagram.com/schoolchale/',
        link:'https://www.instagram.com/goingtoschooloutside/ '
    },
    {
        id: 2,
        icon: application_contants.facebookImg,
        link: 'https://www.facebook.com/goingtoschoolindia/',
    },
    {
        id: 3,
        icon: application_contants.youtubeImg,
        link: 'https://www.youtube.com/user/gtsindia',
    },
    {
        id: 4,
        icon: application_contants.linkedinImg,
        link: 'https://www.linkedin.com/school/going-to-school/',
    }
];

export default SocialInfo;