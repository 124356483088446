import React from "react";
import { Row, Col } from "reactstrap";
import Strip from "../../Strip/Strip";
import DoorSlider from "../component/DoorSlider";
import SliderOne1 from "../../../assets/GoalNew/section1/slider/slide1.png";
import SliderOne2 from "../../../assets/GoalNew/section1/slider/slide2.jpg";
import SliderOne3 from "../../../assets/GoalNew/section1/slider/slide3.jpg";
import SliderOne4 from "../../../assets/GoalNew/section1/slider/slide4.jpg";
import SliderOne5 from "../../../assets/GoalNew/section1/slider/slide5.jpg";
import SliderOne6 from "../../../assets/GoalNew/section1/slider/slide6.jpg";
import SliderOne7 from "../../../assets/GoalNew/section1/slider/slide7.png";
import SliderOne8 from "../../../assets/GoalNew/section1/slider/slide8.jpg";
import SliderOne9 from "../../../assets/GoalNew/section1/slider/slide9.jpg";
import SliderOne10 from "../../../assets/GoalNew/section1/slider/slide10.jpg";
import SliderOne11 from "../../../assets/GoalNew/section1/slider/slide11.jpg";
import SliderOne12 from "../../../assets/GoalNew/section1/slider/slide12.jpg";
import SliderOne13 from "../../../assets/GoalNew/section1/slider/slide13.jpg";
import SliderOne14 from "../../../assets/GoalNew/section1/slider/slide14.jpg";
import Logo from "../../../assets/GoalNew/section1/goallogo.png";
import TreeElement1 from "../../../assets/GoalNew/section1/T1.png";
import TreeElement3 from "../../../assets/GoalNew/section1/T2.png";
import TreeElement2 from "../../../assets/GoalNew/section1/T2.png";
import TreeMobile from "../../../assets/GoalNew/section1/tt-m.png";
import { useWindowSize } from "@react-hook/window-size";
const Section1 = () => {
  const sliderList = [
    SliderOne1,
    SliderOne2,
    SliderOne3,
    SliderOne4,
    SliderOne5,
    SliderOne6,
    SliderOne7,
    SliderOne8,
    SliderOne9,
    SliderOne10,
    SliderOne11,
    SliderOne12,
    SliderOne13,
    SliderOne14,
  ];
  const [width] = useWindowSize();
  return (
    <>
      <Strip
        className="strip strip-no-padding goalone"
        containerType="container-fluid  px-0 mt-5 "
      >
        <Strip
          className="strip strip-no-padding size-data"
          containerType="container "
        >
          <Row className=" pb-5 pt-md-4">
            <Col xs={1} md={1} className="mt-md-5 mb-md-5 mb-2 pb-md-4"></Col>
            <Col
              xs={10}
              md={10}
              className="mt-5 pt-lg-5 pt-md-3 mb-md-5 mb-2 pb-md-4"
            >
              <div className="goal-tv ">
                <div className="sli-img">
                  <div className="sli">
                    <DoorSlider images={sliderList} slide={1} />
                  </div>
                </div>
                <img src={Logo} className="beach" />

                {/* {
                 
                  width >=426 ? */}
                <>
                  <img src={TreeElement1} className="sli-tree" />
                  <img src={TreeElement3} className="sli-tree3" />
                  <img src={TreeElement2} className="sli-tree1" />
                </>
                {/* :<img src={TreeMobile} className="sli-tree" />
                }  */}
              </div>
            </Col>
            <Col xs={1} md={1} className="mt-md-5 mb-md-5 mb-2 pb-4"></Col>
          </Row>
        </Strip>
      </Strip>
    </>
  );
};

export default Section1;
