export const ApiConfig = {
  login: "login",
  insertOrUpdateTeam: "insertOrUpdate-team",
  getTeamList: "get-team-list",
  enableOrDisableTeam: "enableOrDisable-team",
  insertOrSupporterTeam: "insertOrUpdate-supporter",
  getSupporterList: "get-supporter-list",
  enableOrDisableSupporter: "enableOrDisable-supporter",
  getDesignationList: "getDesignationList",

  // project
  insertOrUpdateProject: "insertOrUpdate-project",
  getProjectList: "get-projects-list",
  enableOrDisableProject: "enableOrDisable-project",

  //section types
  getSectionTypeList: "get-section-type-list",

  // section banner
  insertOrUpdateSectionBanner: "insertOrUpdate-section-banner",
  getSectionBannerList: "get-section-banner-list",
  enableOrDisableSectionBanner: "enableOrDisable-section-banner",
  //about
  enableOrDisableAbout: "enableOrDisable-about",
  getAboutList: "about-list",
  insertOrUpdateAbout: "insertOrUpdate-about",

  //Location
  insertOrUpdateLocation: "insertORUpdate-location",
  getLocationList: "get-location-list",

  //Financial
  getFinancialList: "financial-list",
  insertOrUpdateFinancial: "insertOrUpdate-financial",
  enableOrDisableFinancial: "enableOrDisable-financial",

  playlistItems: "playlistItems",

  updateSequenicalOrderForSupporter: "sequencialOrder-ForSupporter",
  updateSequenicalOrderForTeam: "sequencialOrder-ForTeam",

  // ============URLs================
  //baseUrl: 'http://65.0.160.196:5005/v1/',
  // URL: 'http://65.0.160.196',
  YouTubeBaseUrl: "https://youtube.googleapis.com/youtube/v3/",
  // baseUrl: ' https://www.goingtoschool.com/api/v1/',
  // URL: ' https://www.goingtoschool.com',
  URL: "https://goingtoschool.com",
  baseUrl: "https://goingtoschool.com/api/v1/",
};
