import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const AboutService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateAbout } = ApiConfig;

        const url = baseUrl + insertOrUpdateAbout;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: `${data.title}`,
            description: `${data.description}`,
            galleryList: data.galleryList,
            usaTitle: `${data.usaTitle}`,
            usaDescription: `${data.usaDescription}`,
            usaLogo: `${data.usaLogo}`,
            logoList: data.logoList,
            getInTouch: `${data.getInTouch}`,
        });

        const peopleServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_peopleService",
            true
        );
        return peopleServicePromise;
    },

    getList: async () => {
        const { baseUrl, getAboutList } = ApiConfig;

        const url = baseUrl + getAboutList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const aboutServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_about",
            true
        );
        return aboutServicePromise;
    },

    enableDisableAbout: async (data) => {
        const { baseUrl, enableOrDisableAbout } = ApiConfig;

        const url = baseUrl + enableOrDisableAbout;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: data.status,
        });

        const enableDisableAboutServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableAbout",
            true
        );
        return enableDisableAboutServicePromise;
    },

};

export default AboutService;
