import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";
import application_contants from "../../ui/constants/application_contant";

const YouTubeService = {
    getGraphicNovelYoutubePlayList: async (playListId) => {
        const { YouTubeBaseUrl, playlistItems } = ApiConfig;

        const headerParameters = '?part=snippet&maxResults=100&playlistId='+playListId+'&key='+application_contants.YOUTUBE_API_KEY

        const url = YouTubeBaseUrl + playlistItems + headerParameters;

        const headers = new Headers({
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
        });
        console.log(url)
        const YouTubeServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_section",
            true
        );
        return YouTubeServicePromise;
    },


};

export default YouTubeService;
