import React, { useState } from 'react';
import Select from "react-select";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RadioButtonUncheckedRounded from "@material-ui/icons/RadioButtonUncheckedRounded";
import RadioButtonCheckedRounded from '@material-ui/icons/RadioButtonCheckedRounded'
import { Button, Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { ApiConfig } from '../../../../../../api/apiConfig/apiConfig';
import VideoGallery from './VideoGallery';
import ImageGallery from './ImageGallery';
import ProjectLayoutData from '../../../../../Utils/Json/projectLayout';
import PreviewModal from '../../../../../customComponent/PreviewModal/PreviewModal';
import PostitonModal from './PostitonModal';
import ColorCode from '../../../../../Utils/Json/colorCode';


const LocationDetails = (props) => {
    const { locationOptions, locationList, handleLocationList } = props;
    const [locationName, setLocationName] = useState("");
    const [locationDescription, setLocationDescription] = useState("");
    const [locationId, setLocationId] = useState(0);
    const [isLocationEdit, setIsLocationEdit] = useState(false);
    const [locationEditIndex, setLocationEditIndex] = useState(0);
    const [locationDetails, setLocationDetails] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [galleryList, setGalleryList] = useState([]);
    const [isVideo, setIsVideo] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [videoGallery, setVideoGallery] = useState([]);
    const [layoutType, setLayoutType] = useState('');
    const [isVideoAdd, setIsVideoAdd] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [sectionTitle, setSectionTitle] = useState('');
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);
    const [playStoreUrl, setPlayStoreUrl] = useState('');
    const [supportedBy, setSupportedBy] = useState('');
    const [fileType, setFileType] = useState('image/jpeg, image/jpg, image/png');
    const [colorCode, setColorCode] = useState('fff');
    const [sectionColorCode, setSectionColorCode] = useState('fff');
    const [imgGallery, setImgGallery] = useState([]);
    const [isImage, setIsImage] = useState(false);
    const [isImgAdd, setIsImgAdd] = useState(false);
    const [isImgModal, setIsImgModal] = useState(false);
    const [arrowColor, setArrowColor] = useState('fff');

    React.useEffect(() => {
        setLocationData(locationOptions)
    }, [locationOptions])

    React.useEffect(() => {
        setLocationDetails(locationList)
    }, [locationList])

    const handleLocationName = item => {
        setLocationName(item);
    };

    const handleLocationDescription = event => {
        setLocationDescription(event.target.value);
    };

    const handleSectionTitle = (event) => {
        setSectionTitle(event.target.value);
    }

    const handlePlayStoreUrl = (event) => {
        setPlayStoreUrl(event.target.value);
    }

    const handleSupportedBy = (event) => {
        setSupportedBy(event.target.value);
    }

    const getGalleryList = (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setGalleryList([...galleryList, ...item])
                }

            };
            reader.readAsDataURL(file);
        });
    }

    const handleGalleryImageDelete = (index) => {
        galleryList.splice(index, 1);
        setGalleryList([...galleryList]);
    }

    const handleLocationCancel = () => {
        setLocationName("");
        setLocationDescription("");
        setGalleryList([]);
        setIsVideoAdd(false);
        setIsImgAdd(false);
        setLayoutType('');
        setSectionTitle('');
        setSupportedBy('');
        setPlayStoreUrl('');
        setColorCode('fff')
    };

    const handleLocationSave = () => {
        let data;
        if (isLocationEdit) {
            const item = locationDetails[locationEditIndex];
            // console.log("item :", item);
            item.locationName = locationName ? locationName.label : locationName;
            item.description = locationDescription;
            item.galleryList = galleryList.length > 0 ? galleryList : [];
            item.videoGalleryList = videoGallery.length > 0 ? videoGallery : [];
            item.imgGallery = imgGallery.length > 0 ? imgGallery : [];
            item.templateType = layoutType ? layoutType.value : '';
            item.isVideo = isVideo;
            item.sectionTitle = sectionTitle;
            item.storeUrl = playStoreUrl;
            item.supportedBy = supportedBy;
            item.bgColorCode = colorCode;
            item.colorCode = sectionColorCode;
            item.arrowColor = arrowColor;
            setLocationDetails([...locationDetails]);
            handleLocationList(locationDetails);
        } else {
            data = [
                {
                    //   id: parseInt(locationId) + parseInt(1),
                    locationName: locationName ? locationName.label : "",
                    description: locationDescription,
                    galleryList: galleryList.length > 0 ? galleryList : [],
                    imgGallery: imgGallery.length > 0 ? imgGallery : [],
                    isVideo: isVideo,
                    videoGalleryList: videoGallery,
                    templateType: layoutType ? layoutType.value : '',
                    sectionTitle: sectionTitle,
                    storeUrl: playStoreUrl,
                    supportedBy: supportedBy,
                    bgColorCode: colorCode,
                    colorCode: sectionColorCode,
                    arrowColor: arrowColor,
                }
            ];
            //   setLocationId(parseInt(locationId) + parseInt(1));
            setLocationDetails([...data, ...locationDetails]);
            let array = [...data, ...locationDetails];
            handleLocationList(array);
        }
        handleLocationCancel();
        setIsLocationEdit(false);
        setIsVideoAdd(false)
    };

    const handleLocationEdit = async (index) => {
        const item = locationDetails[index];
        setLocationDescription(item.description);
        const lName = locationData.find(it => it.label === item.locationName);
        setLocationName(lName);
        const tName = ProjectLayoutData.find(it => it.value === item.templateType);
        setLayoutType(tName);
        setIsLocationEdit(true);
        setLocationEditIndex(parseInt(index));
        setGalleryList([...item.galleryList]);
        setVideoGallery([...item.videoGalleryList]);
        setPlayStoreUrl(item.storeUrl);
        setSupportedBy(item.supportedBy);
        setIsVideoAdd(item.isVideo ? true : false);
        setIsVideo(item.isVideo);
        await setIsImgAdd(tName.value === 8 ? true : false);
        await setIsImage(tName.value === 8 ? true : false)
        setSectionTitle(item.sectionTitle);
        setColorCode(item.bgColorCode);
        setSectionColorCode(item.colorCode);
        setArrowColor(item.arrowColor);
        await setImgGallery(item.imgGallery && item.imgGallery.length > 0 ? item.imgGallery : []);
    };

    const handleLocationDelete = index => {
        locationDetails.splice(index, 1);
        setLocationDetails([...locationDetails]);
        handleLocationList(locationDetails);
    };

    const handleVideoTheme = (value) => {
        setIsVideo(value);
        setVideoGallery([]);
    }

    const handleImgTheme = (value) => {
        setIsImage(value);
        setImgGallery([]);
    }

    const handleImageTheme = (value) => {
        setIsVideo(value);
        setGalleryList([]);
    }

    const handleImagesTheme = (value) => {
        setIsImage(value);
        setImgGallery([]);
    }

    const handleToggle = () => {
        setIsModal(!isModal);
    }
    const handleImageToggle =() =>{
        setIsImgModal(!isImgModal);
    }
    const handleImgToggle = () => {
        setIsImgModal(!isImgModal);
    }

    const handleVideoGalleryList = (list) => {
        setVideoGallery([...list]);
        setIsModal(!isModal);
        setIsVideoAdd(false);
    }

    const handleImgGalleryList =(list) => {
        setImgGallery([...list]);
        setIsImgModal(!isImgModal);
        setIsImgAdd(false);
    }

    const handleLayoutType = (item) => {
        if (item.value === 7) {
            setFileType('application/pdf');
            setGalleryList([]);
        } else {
            setFileType('image/jpeg, image/jpg, image/png');
        }
        setLayoutType(item)
    }

    const handlePreviewModal = () => {
        setIsPreview(!isPreview)
    }

    const handleLocationPosition = (index) => {
        setPositionIndex(index)
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionToggle = () => {
        setIsPositionModal(!isPositionModal)
    }

    const handlePositionSave = (toIndex) => {
        const list = [...locationList];
        //list.splice(0, 0, list.splice(index, 1)[0]);
        var element = list[positionIndex];
        list.splice(positionIndex, 1);
        list.splice(toIndex, 0, element);
        setLocationData([...list]);
        handleLocationList(list);
        setIsPositionModal(!isPositionModal);
    }

    const handleSelectedColor = (label) => {
        setColorCode(label)
    }

    const handleSelectedColorCode = (label) => {
        setSectionColorCode(label)
    }

    const handleArrowSelectedColor = (label) => {
        setArrowColor(label)
    }

    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="d-flex align-items-center full-width justify-content-between bg-zircon-grey">
                    <div>
                        <h6 className="h6-small external-title title-bright-grey p-2">
                            Add Template :
                        </h6>
                    </div>
                    <div>
                        <h6 className="h6-small external-title title-crimson-red p-2 cursor-pointer" onClick={() => handlePreviewModal()}>
                            Preview
                        </h6>
                    </div>
                </div>
            </Col>
            <Col
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="m-auto mt-4"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Template Type*:
                                </Label>
                                <Select
                                    value={layoutType}
                                    onChange={handleLayoutType}
                                    options={ProjectLayoutData}
                                    placeholder={"Select Layout Type"}
                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                    isSearchable={true}
                                    isClearable
                                    maxMenuHeight={200}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Location Name :
                                </Label>
                                <Select
                                    value={locationName}
                                    onChange={handleLocationName}
                                    options={locationData}
                                    placeholder={"Select Location"}
                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                    isSearchable={true}
                                    isClearable
                                    maxMenuHeight={200}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Section Title :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Section Title"
                                    id="Section Title"
                                    name="Section Title"
                                    className="form-control common-form-control"
                                    value={sectionTitle}
                                    onChange={event =>
                                        handleSectionTitle(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row
                            className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Play Store URL :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="PlayStore URL"
                                    id="Play-Store-URL"
                                    name="Play-Store-URL"
                                    className="form-control common-form-control"
                                    value={playStoreUrl}
                                    onChange={event =>
                                        handlePlayStoreUrl(event)
                                    }
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    SupportedBy :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="SupportedBy"
                                    id="SupportedBy"
                                    name="SupportedBy"
                                    className="form-control common-form-control"
                                    value={supportedBy}
                                    onChange={event =>
                                        handleSupportedBy(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                         <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${sectionColorCode}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColorCode(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                       <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Background Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColor(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Arrow Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${arrowColor}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleArrowSelectedColor(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                        {/* <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                       Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${sectionColorCode}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColorCode(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left d-flex align-items-center">
                                    <div>
                                        Background Color Code:
                                    </div>
                                    <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                                    </div>

                                </Label>
                                <div className="d-flex flex-wrap">
                                    {ColorCode.map((it, index) => (
                                        <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColor(`${it.label}`)}>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </FormGroup>
                        */}
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Description :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Description"
                                    id="Description"
                                    name="Description"
                                    className="form-control common-form-control"
                                    value={locationDescription}
                                    onChange={event =>
                                        handleLocationDescription(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                        {layoutType && layoutType.value === 8 ?
                            <FormGroup
                                row
                                className="mx-0 align-items-center"
                            >
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {isImage ?
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center cursor-pointer" onClick={() => handleImgTheme(false)}>
                                                <div>
                                                    <RadioButtonCheckedRounded style={{ color: 'green' }} />
                                                </div>
                                                <div className="pl-2">
                                                    <Label className="common-label text-left cursor-pointer">
                                                        Image Theme
                                                    </Label>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    className="btn custom-theme-btn custom-btn--small br-4px"
                                                    onClick={handleImgToggle}
                                                >
                                                    {`${!isImgAdd ? 'Add Image' : 'Update Video'}`}
                                                </Button>
                                            </div>

                                        </div> :
                                        <div className="d-flex align-items-center cursor-pointer" onClick={() => handleImagesTheme(true)}>
                                            <div>
                                                <RadioButtonUncheckedRounded style={{ color: 'green' }} />
                                            </div>
                                            <div className="pl-2">
                                                <Label className="common-label text-left cursor-pointer">
                                                    Image Theme
                                                </Label>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </FormGroup> :
                            <FormGroup
                                row
                                className="mx-0 align-items-center"
                            >
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {isVideo ?
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center cursor-pointer" onClick={() => handleVideoTheme(false)}>
                                                <div>
                                                    <RadioButtonCheckedRounded style={{ color: 'green' }} />
                                                </div>
                                                <div className="pl-2">
                                                    <Label className="common-label text-left cursor-pointer">
                                                        Video Theme
                                                    </Label>
                                                </div>
                                            </div>
                                            <div>
                                                <Button
                                                    className="btn custom-theme-btn custom-btn--small br-4px"
                                                    onClick={handleToggle}
                                                >
                                                    {`${!isVideoAdd ? 'Add Video' : 'Update Video'}`}
                                                </Button>
                                            </div>

                                        </div> :
                                        <div className="d-flex align-items-center cursor-pointer" onClick={() => handleImageTheme(true)}>
                                            <div>
                                                <RadioButtonUncheckedRounded style={{ color: 'green' }} />
                                            </div>
                                            <div className="pl-2">
                                                <Label className="common-label text-left cursor-pointer">
                                                    Video Theme
                                                </Label>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </FormGroup>}
                        {isImgModal ?
                            <FormGroup row className="mx-0 align-items-center">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <ImageGallery imgGallery={imgGallery} open={isImgModal} isImgAdd={isImgAdd} toggle={handleImageToggle} handleImgGalleryList={handleImgGalleryList} layoutType={layoutType} />
                                </Col>
                            </FormGroup> : null}

                        {isVideo ?
                            <FormGroup row className="mx-0 align-items-center">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {isModal ?
                                        <VideoGallery videoGallery={videoGallery} open={isModal} isVideoAdd={isVideoAdd} toggle={handleToggle} handleVideoGalleryList={handleVideoGalleryList} layoutType={layoutType} /> : null}
                                </Col>
                            </FormGroup>
                            :
                            <FormGroup row className="mx-0 align-items-center">
                                {layoutType && layoutType.value === 8 ? null :
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Label className="common-label text-left">
                                            Upload Gallery Images* :
                                        </Label>
                                        <div>
                                        </div>
                                        <div
                                            className={`position-relative`}
                                        >
                                            <div className="file-form-container">
                                                <input type="file" value={""} placeholder="Upload File" multiple={true} accept={`${fileType}`} className="form-control common-form-control" onChange={getGalleryList} />
                                                <p className="file-text">{galleryList.length > 0 ? `${galleryList.length} uploaded` : 'upload Files'}</p>
                                            </div>

                                            <div className="pdf-thumbnail-container">
                                                <CloudUploadIcon
                                                    style={{
                                                        color: "#fff",
                                                        fontSize: "2rem",
                                                        borderRadius: 4,
                                                        padding: 2,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                }
                            </FormGroup>}
                        {galleryList.length > 0 && fileType !== 'application/pdf' ?
                            <FormGroup row className="mx-0 align-items-center">
                                {galleryList.map((it, index) => (
                                    <Col xs={6} sm={6} md={3} lg={3} xl={3} key={index} className="mb-2">
                                        <div className="position-relative">
                                            <img src={it.imgPath ? `${ApiConfig.URL}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                            <div className="trash-icon-container" onClick={() => handleGalleryImageDelete(index)}>
                                                <DeleteIcon style={{
                                                    color: "#fff",
                                                    fontSize: "1rem",

                                                }} />
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </FormGroup>
                            : null}
                    </Col>
                    <Col
                        xs={10}
                        sm={10}
                        md={8}
                        lg={6}
                        xl={6}
                        className="ml-auto mb-3"
                    >
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                    onClick={handleLocationCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                    onClick={handleLocationSave}
                                    disabled={layoutType ? false : true}
                                >
                                    Save
                                </Button>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Table bordered responsive>
                                    <thead className="custom-table-thead">
                                        <tr>
                                            <th>Section Title</th>
                                            <th>Name</th>
                                            <th>Template</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="custom-table-tbody">
                                        {locationList.map((it, index) => (
                                            <tr key={index}>
                                                <td>{it.sectionTitle}</td>
                                                <td>{it.locationName}</td>
                                                <td>{it.templateType ? ProjectLayoutData[it.templateType - 1].label : ''}</td>
                                                <td>{`${it.description.length > 50 ? `${it.description.substr(0, 50)}...` : it.description} `}</td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div
                                                            onClick={() =>
                                                                handleLocationDelete(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                Delete
                                                            </Button>
                                                        </div>
                                                        <div
                                                            className="ml-12px"
                                                            onClick={() =>
                                                                handleLocationEdit(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Edit
                                                            </Button>
                                                        </div>
                                                        <div
                                                            className="ml-12px"
                                                            onClick={() =>
                                                                handleLocationPosition(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Position
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            {isPreview ?
                <PreviewModal open={isPreview} toggle={handlePreviewModal} data={locationDetails} isTitle={false} /> : null}
            {isPositionModal ?
                <PostitonModal isModal={isPositionModal} handlePositionSave={handlePositionSave} handleToggle={handlePositionToggle} len={locationList} /> : null}
        </Row>
    )
}


export default LocationDetails;