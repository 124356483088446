import React from 'react';
import Helmet from 'react-helmet';
import SEO from 'react-seo-component';


const SEOComponent = (props) => {
    const { title, description, siteUrl, keywords , subtitle} = props;
    return (
        <div>
            <Helmet>
                <meta name="keywords" content={keywords} />
            </Helmet>
            <SEO
                title={title}
                titleTemplate={''}
                titleSeparator={`- ${subtitle}`}
                description={description || ''}
                // image={image}
                pathname={`${window.location}`}
            // siteLanguage={siteLanguage}
            // siteLocale={siteLocale}
            // twitterUsername={twitterUsername}
            />
        </div>
    )
}

export default SEOComponent;