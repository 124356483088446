const FinancialType = [
    {
        label: "Annual Report",
        value: 1
    },
    {
        label: "Financial Report",
        value: 2
    },
    {
        label: "FCRA Report",
        value: 3
    }
];

export default FinancialType;