import React from 'react';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import FileUploader from '../../../customComponent/FileUploader/FileUploader';
import LogoListDetails from './LogoListDetails';


const ReportsForm = (props) => {
    const {
        handleLogoCancel,
        handleLogoSave,
        logoLists,
        handleLogoDelete,
        handleLogoEdit,
        getFile,
        logoFileName,
        handleLogoPosition
    } = props;
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label text-left">
                            Start Year :
                    </Label>
                        <Input
                            type="text"
                            placeholder="Start Year"
                            id="Start Year"
                            name="Start Year"
                            className="form-control common-form-control"
                            value={startYear}
                            onChange={event => handleStartYear(event)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label text-left">
                            End Year :
                    </Label>
                        <Input
                            type="text"
                            placeholder="End Year"
                            id="End Year"
                            name="End Year"
                            className="form-control common-form-control"
                            value={endYear}
                            onChange={event => handleEndYear(event)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label text-left">
                            Report Type :
                  </Label>
                        <Select
                            value={reportType}
                            onChange={handleReport}
                            options={ReportsType}
                            placeholder={"Select Location"}
                            classNamePrefix="common-select-drop-down common-select-drop-down-large"
                            isSearchable={true}
                            isClearable
                            maxMenuHeight={200}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label text-left d-flex align-items-center">
                            <div>
                                Color Code:
                            </div>
                            <div className="color-dots-container" style={{ backgroundColor: `#${colorCode}` }}>
                            </div>

                        </Label>
                        <div className="d-flex flex-wrap">
                            {ColorCode.map((it, index) => (
                                <div key={index} className="color-dots-container" style={{ backgroundColor: `#${it.label}` }} onClick={() => handleSelectedColor(`${it.label}`)}>

                                </div>
                            ))}
                        </div>
                    </Col>
                </FormGroup>
                */}
                <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Label className="common-label text-left">
                            Upload Logo File :
                        </Label>
                        <FileUploader
                            isImg={true}
                            isSingleGrid={true}
                            getThumbnail={(item) =>
                                getFile(item)
                            }
                            value={logoFileName}
                            isEditable={false}
                            labelName="Upload Logo File"
                        />
                    </Col>
                </FormGroup>
            </Col>
            <Col
                xs={10}
                sm={10}
                md={8}
                lg={6}
                xl={6}
                className="ml-auto mb-3"
            >
                <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Button
                            className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                            onClick={() => handleLogoCancel()}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Button
                            className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                            onClick={() => handleLogoSave()}
                            disabled={logoFileName ? false : true}
                        >
                            Save
                        </Button>
                    </Col>
                </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormGroup
                    row
                    className="mx-0 align-items-center"
                >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <LogoListDetails data={logoLists} handleLogoDelete={(index) => handleLogoDelete(index)} handleLogoEdit={(index) => handleLogoEdit(index)} handleLogoPosition={(index) => handleLogoPosition(index)} />
                    </Col>
                </FormGroup>
            </Col>
        </Row>

    )
}

export default ReportsForm;