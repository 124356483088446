import React, { useState } from "react";
import ImageCaursolGrid from "../ImageCaursolGrid/ImageCaursolGrid";
import ImageGrid from "../ImageGrid/ImageGrid";
import PDFGridViewer from "../PDFGridViewer/PDFGridViewer";
import PlayStoreAndSupportedDetails from "../PlayStoreAndSupportedDetails/PlayStoreAndSupportedDetails";
import SupportedImage from "../SupportedImage/SupportedImage";
import TitleandParagraph from "../TitleandParagraph/TitleandParagraph";
import VideoGrid from "../VideoGrid/VideoGrid";
import VideoSingleGrid from "../VideoSingleGrid/VideoSingleGrid";


const Template = (props) => {
    const [data, setData] = useState([]);
    const { toggle, isMapofme, websiteUrl , title} = props;
    
    React.useEffect(() => {
        if (props.data) {
            setData([props.data])
        }
    }, [props.data])

    return (
        <div>
            {data.length > 0 ?
                <div>
                    {data.map((it, index) => (
                        <div key={index}>
                            {it.templateType === 1 ?
                                <div>
                                    <ImageGrid data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                            {it.templateType === 2 ?
                                <div>
                                    <VideoGrid data={it} sectionTitle={false} isBtn={false} isMapofme={isMapofme}  websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                            {it.templateType === 3 ?
                                <div>
                                    <VideoSingleGrid data={it} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                            {it.templateType === 4 ?
                                <div>
                                    <VideoGrid data={it} sectionTitle={true} isBtn={false} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                            {it.templateType === 5 ?
                                <div>
                                    <VideoGrid data={it} sectionTitle={true} isBtn={true} isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                            {it.templateType === 6 ?
                                <div>
                                    <PlayStoreAndSupportedDetails data={it} title={title}/>
                                </div>
                                : null}
                            {it.templateType === 7 ?
                                <div>
                                    <PDFGridViewer data={it}  isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                             {it.templateType === 8 ?
                                <div>
                                    <ImageCaursolGrid data={it}  isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                             {it.templateType === 9 ?
                                <div>
                                    <SupportedImage data={it}  isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                            : null}
                             {it.templateType === 10 ?
                                <div>
                                    <TitleandParagraph data={it}  isMapofme={isMapofme} websiteUrl={websiteUrl} title={title}/>
                                </div>
                                : null}
                        </div>
                    ))}
                </div> :
                <p className="external-section--title fw-blod align-right cursor-pointer" onClick={toggle}>
                    Data not found
                    </p>}
        </div>
    )
}


export default Template;