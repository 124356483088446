import React from 'react';
import { useWindowSize } from '@react-hook/window-size';
import ContactUsInfo from '../../customComponent/ContactUsInfo/ContactUsInfo';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import application_contants from '../../constants/application_contant';

const ContactUs = () => {
    const [width] = useWindowSize();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <ExternalLayout>
            <RenderPage
                id="contact-page"
                className={`render-page ${width >= application_contants.MOBILE_BREAKPOINT ? 'bg-white' : ''}`}
                containerType="container-fluid px-0"
            >
                <ContactUsInfo isInfo={true}/>
            </RenderPage>
        </ExternalLayout>
    )
};


export default ContactUs;