import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import SEOComponent from '../../customComponent/SEO/SEOComponent';

const PrivacyPolicy = () => {
    return (
        <ExternalLayout>
            <SEOComponent title="Going to School" subtitle="Privacy Policy"  description=""
             />
            <RenderPage
                id="policies-page"
                className="render-page bg-zircon-grey"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-policies"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-col-no--padding">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto mobile-col-no--padding">
                                    <h6 className="h6-small external-title pt-5 pb-4"> <Link to={'/policies'} className="link--underline--none">{`Our Policies >>`}</Link>
                                        <font className="pl-8px">Privacy Policy</font>
                                    </h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto pb-5 mobile-col-no--padding">
                                    <Card className="card-container">
                                        <CardBody className="card-body--container">
                                            <Row className="mx-0">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <div className="policies-container">
                                                        <img src={application_contants.compliantImg} alt="" />
                                                    </div>
                                                    <h6 className="h6-small external-title pb-3 card-title--position">Privacy Policy</h6>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                    <p className="external-title title-dune-grey pb-2">1. WHAT DO WE DO WITH YOUR INFORMATION?</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            When you sign up on our platform to watch videos, read books or take part in quizes and surveys, we collect the personal information you give us such as your name, mobile, grade, school &amp; district.
                                                            When you browse our platform, we also automatically receive your devices's ID (Device ID) and OS information in order to provide us with information that helps us learn about your mobile device and operating system
                                                     </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            <b>Mobile SMS Marketing</b>
                                                            <font> (if applicable): With your permission, we may send you SMS about our platform, new videos and other updates.</font>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">2. CONSENT</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            <b>How do you get my consent?</b>
                                                            <font className="pl-8px">
                                                                When you provide us with personal information to complete a form, submit a quiz or survey, we imply that you consent to our collecting it and using it for that specific reason only.
                                                        </font>
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                                                    </p>
                                                        <p className="p-small external-title title-dune-grey lh-30px  pb-12px">How do I withdraw my consent?</p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at
                                                        <b> lisa@goingtoschool.com </b> or mailing us at: Going to School, Bungalow No. 116, Aram Nagar part 2 back road, Machlimaar nagar road, Opp Gulshan Colony,Versova, Andheri West, Mumbai 400061
                                                    </p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">3. DISCLOSURE</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            We may disclose your personal information if we are required to do so by law or if you violate our Terms of Service.
                                                    </p>
                                                    </div>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">4. THIRD-PARTY SERVICES</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                                                   </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
                                                       </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            Once you leave our platform or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our platform's Terms of Service.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            <b>Links:</b>
                                                        </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            When you click on links on our platform, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
                                                     </p>
                                                    </div>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">5. SECURITY</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            To protect your personal information, we take reasonable precautions and follow the best industry practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                                                    </p>
                                                    </div>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">6. COOKIES</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            We use cookies to maintain the session of your user. It is not used to personally identify you on other websites.
                                                   </p>
                                                    </div>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">7. AGE OF CONSENT</p>
                                                    <div className="pl-16px">

                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            By using this platform, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this platform.
                                                   </p>
                                                    </div>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">8. CHANGES TO THIS PRIVACY POLICY</p>
                                                    <div className="pl-16px">
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the platform. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                                                    </p>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            If our platform is acquired or merged with another entity, your information may be transferred to the new owners so that we may continue to provide services to you.
                                                    </p>
                                                    </div>

                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4">
                                                    <p className="external-title title-dune-grey pb-2">QUESTIONS AND CONTACT INFORMATION</p>
                                                    <div>
                                                        <p className="p-small external-section--title title-bright-grey lh-30px  pb-12px">
                                                            If you would like to access, correct, amend or delete any personal information we have about you, register a complaint, or simply want information, contact our Privacy Compliance Officer at <b class="pink">lisa@goingtoschool.com</b> or by mail at Going to School, Bungalow No. 116, Aram Nagar part 2 back road, Machlimaar nagar road, Opp Gulshan Colony,Versova, Andheri West, Mumbai 400061
                                                    </p>
                                                    </div>

                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
}

export default PrivacyPolicy;