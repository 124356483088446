import React from 'react'
import { Row, Col } from 'reactstrap';
import Strip from "../../Strip/Strip";
import Img from "../../../assets/GoalNew/section3/sec3-img.png";
const Section3 = () => {
  return (
    <>
     <Strip
        className="strip strip-no-padding goalthree"
        containerType="container-fluid  px-0 ">
        <Strip
          className="strip strip-no-padding"
          containerType="container ">
          <Row className='pt-lg-5 pb-5'>
        
            <Col xs={12} md={6} className='mt-5 pt-md-5'>
              <div className=' play-p pt-lg-5'> 
              <p>  
              If we can teach football at school for girls in India, then she'll come back to school to play football. The other three days a week we'll be able to equip her with the skills she needs to solve the biggest challenge of our time, Climate Change.
               </p>
              </div>
             
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <div className='play-img'>
                <img src={Img} />
              </div>
            </Col>
            <Col xs={12} className="mt-5">
              
            </Col>
          </Row>
          </Strip>
          </Strip>
    
    </>
  )
}

export default Section3